import { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../../components/Forms/FormInput';
import { isValidSendGridKey, type ClientInfoType } from '../../../../types';
import { type Client, ClientEmailSettings, EmailRegex, SendGridRegex } from '../../../../types';
import adminService from '../../../../services/adminService/adminService';
import emailService from '../../../../services/emailService/emailService';
import { AuthContext } from '../../../../services/authService/authProvider';
import { useAlertComponent } from '../../../../components/Alert/AlertProvider';
import settingsService from '../../../../services/settingsService/settingsService';

export default function DefaultEmail({ client, loading, setLoading }: ClientInfoType) {
  const auth = useContext(AuthContext);
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [testLoading, setTestLoading] = useState<boolean>(false);
  const [emailFrom, setEmailFrom] = useState<string | undefined>(client?.emailFrom);

  const handleEmailTest = async () => {
    setTestLoading(true);

    try {
      const emailTo = auth?.getUser()?.email as string;

      const res = await emailService.test(client?.clientId!, emailTo);
      if (res?.status === 'Accepted') {
        setAlertText(`Sent test email to ${emailTo}`);
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
      }
    } catch (error) {
      setAlertText('Unable to test Sendgrid Key');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    setTestLoading(false);
  };

  const handleSubmit = async () => {
    if (setLoading) setLoading(true);

    try {
      const submit = {
        clientId : client?.clientId,
        sendgridApiEnabled: false
      };

      const response = await settingsService.setEmail(submit as ClientEmailSettings,client?.clientId!);
        setAlertText('Succesfully update email');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
    } catch (error: any) {
      setAlertText('Unable to update email');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    if (setLoading) setLoading(false);
  };

  return (
              <Form noValidate>
              <Form.Group controlId="emailFrom" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Email From</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  defaultValue={ "no-reply@centrexsoftware.com"}
                  readOnly={true}
                  disabled={true}
                />      
              </Form.Group>
              <Form.Group controlId="nameFrom" style={{ marginBottom: '40px' }}>
                <Form.Label className="label">Name From</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  defaultValue={client?.clientName}
                  readOnly={true}
                  disabled={true}
                />
              </Form.Group>
             
              <Button onClick={()=>handleSubmit()}  className="btn-backround" style={{ float: 'right', marginLeft: '15px' }}>
                <span>Save &nbsp;</span>
                {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
              <Button variant="secondary" style={{ float: 'right' }} onClick={handleEmailTest}>
                <span>Test &nbsp;</span>
                {testLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
            </Form>
  );
}



