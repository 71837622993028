import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../services/authService/authProvider';
import { FormInput } from '../../components/Forms/FormInput';
import authService from '../../services/authService/authService';
import { ContentCard } from '../../components/Card';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import styled from 'styled-components';

const PhoneWrapper = styled(PhoneInput)`
    .PhoneInputInput {
        border:none;
        outline: none;
    }
`;

type ConfirmPhoneProps = {
    phoneNumber: string;
    
  };

export default function ConfirmPhone({ phoneNumber }: ConfirmPhoneProps) {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [code, setCode] = useState('');
  const [error, setError] = useState<string>('');
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const handleResend = async (event: any) => {
    event.preventDefault();
    setServerError(undefined);

    setLoading(true);

    try {
        setLoading(true);
        await authService.verifyPhone(phoneNumber);
        setLoading(false);
    } catch (e:any) {
        if(typeof(e) === 'string'){
            setError(e); 
        }
        setLoading(false);
    }
  };

  const handleConfirm = async (event: any) => {
    event.preventDefault();
    setServerError(undefined);

    setLoading(true);

    try {
        await authService.confirmPhone(phoneNumber, code);
        auth.setIs2FAEnabled(true);
        navigate('/', { replace: true });
    } catch (e:any) {
        if(typeof(e) === 'string'){
            setError(e); 
        }
        else{
            setError("Unable to verify Code"); 
        }
        
        setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
            <div className="login-form" style={{ maxWidth: '384px' }}>
                <h1 className="title">Phone number verification</h1>
                    <Form noValidate>
                        <p className="mb-0">A verification code will be sent to your phone number</p>
                        <p className="mb-0">{phoneNumber}</p>
                        <p className="reset-txt" onClick={handleResend}>
                            Re send code
                        </p>
                    <Form.Group controlId="code" className="mb-4">
                        <Form.Label className="form-label">
                        <p className="mb-0">Verification Code</p>
                        </Form.Label>
                        <Form.Control
                        required
                        as={FormInput}
                        type="text"
                        maxLength={6}
                        autoComplete="off"
                        placeholder="Enter verification code"
                        onChange={e => setCode(e.target.value)}
                        isInvalid={!!error}
                        className="form-input"
                        value={code}
                        />
                        <Form.Control.Feedback type="invalid">
                        {error}
                        
                        </Form.Control.Feedback>
                    </Form.Group>
                    <span>{serverError ?? serverError}</span>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button
                        className="cancel-btn"
                        style={{ width: '175px' }}
                        onClick={() => {
                            navigate('/auth/login', { replace: true });
                        }}
                        >
                        Cancel
                        </Button>
                        <Button 
                            type="submit" 
                            onClick={handleConfirm}
                            disabled={loading || !phoneNumber || !code}
                            className="btn-form" 
                            style={{ height: 'auto', width: '175px' }}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        <span>&nbsp;&nbsp;Submit</span>
                        </Button>
                    </div>
                    </Form>
            </div>
    </Row>
  );
}
