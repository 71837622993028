import jwt_decode from 'jwt-decode';
import { AuthResponse, TwoFactorRequiredResponse, getAppUserFromAuth } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import { axiosPublic } from '../axios/axiosPublic';
import { AxiosError } from 'axios';
import { PhoneNumber } from 'react-phone-number-input';

class AuthService {
  
  async login(userName: string, password: string): Promise<AuthResponse | TwoFactorRequiredResponse |  undefined> {
    try {
      const response = await axiosPublic.post(
        '/admin/api/authenticate',
        {
          email: userName,
          password: password,
        }
      );

      if (response?.data?.succeeded === false) {
        return Promise.reject(response?.data?.responseStatus?.errorMessage);
      }

      if (response?.status === 200 && response?.data?.accessToken) {
        return response.data;
      }
      
    } catch (err: any) {
      if("response" in err){
        if(err.response && err?.response?.status === 401 && err?.response?.data?.requiresTwoFactor === true){
          return  err.response?.data;
        }
      }
      return Promise.reject(err);
    }
  }

  async login2fa(userName: string, code: string): Promise<AuthResponse | undefined> {
    try {
      const response = await axiosPublic.post(
        '/admin/api/authenticate/2fa',
        {
          email: userName,
          code: code,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.succeeded === false) {
          return Promise.reject(response?.data?.responseStatus?.errorMessage);
        }
        return response.data;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async resend2faCode(phone: string) {
    try {
      const response = await axiosPublic.post(
        '/admin/api/authenticate/verify-phone',
        {
          phoneNumber: phone
        }
      );

      if (response?.status === 200) {
        if (response?.data?.succeeded === false) {
          return Promise.reject(response?.data?.responseStatus?.errorMessage);
        }
        return response.data;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }


  async verifyPhone(phoneNumber: string): Promise<{ status: number }> {
    try {
      const response = await axiosPrivate.post('/admin/api/verify-phone', { PhoneNumber: phoneNumber });

      if (response?.status === 200) {
        return Promise.resolve({ status: response?.status });
      } else {
        return Promise.reject(response?.data?.responseStatus?.errorMessage);
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.title ? error?.response?.data?.title : error?.response?.data?.errors[0];
      return Promise.reject(errMsg);
    }
  }

  async confirmPhone(phoneNumber: string, code: string) {
    try {
      const response = await axiosPrivate.post('/admin/api/confirm-phone', { PhoneNumber: phoneNumber, code: code },{cache:false});

      if (response?.status === 200) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } catch (error: any) {
      const errMsg = typeof(error?.response?.data) === "string" ?  error?.response?.data : "unable to verify code";
      return Promise.reject(errMsg);
    }
  }

  async setSendResetPasswordEmail(email: string): Promise<{ status: number }> {
    try {
      const response = await axiosPublic.post('/admin/api/password/request-passcode', { email: email });

      if (response?.status === 200) {
        return Promise.resolve({ status: response?.status });
      } else {
        return Promise.reject(response?.data?.responseStatus?.errorMessage);
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.title ? error?.response?.data?.title : error?.response?.data?.errors[0];
      return Promise.reject(errMsg);
    }
  }

  async setResetNewPassword(email: string, code: string, password: string): Promise<{ status: number }> {
    try {
      const response = await axiosPublic.post('/admin/api/password/reset', {
        email: email,
        token: code,
        newPassword: password,
      });

      if (response?.status === 200) {
        return Promise.resolve({ status: response?.status });
      } else {
        return Promise.reject(response?.data?.responseStatus?.errorMessage);
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.title ? error?.response?.data?.title : error?.response?.data?.errors[0];
      return Promise.reject(errMsg);
    }
  }

  async setChangePassword(oldPassword: string, newPassword: string): Promise<{ status: number }> {
    try {
      const response = await axiosPrivate.post(
        '/admin/api/password/change',
        {
          oldPassword,
          newPassword,
        },
        { cache: false }
      );

      if (response?.status === 200) {
        return Promise.resolve({ status: response?.status });
      } else {
        return Promise.reject(response?.data?.responseStatus?.errorMessage);
      }
    } catch (error: any) {
      const errMsg = error?.response?.data?.title ? error?.response?.data?.title : error?.response?.data?.errors[0];
      return Promise.reject(errMsg);
    }
  }
}

export default new AuthService();
