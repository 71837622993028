import { StripeInvoice, StripePrice, StripeProduct } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

const environment = process.env.NODE_ENV;
class stripeService {
  async getPrices(): Promise<Array<StripePrice> | undefined> {
    try {
      const response = await axiosPrivate.get(`/stripe/v1/prices`);

      if (response?.status === 200) {
        return response?.data?.data?.map((u: any) => ({
          id: u.id,
          type: u.type,
          unit_amount: u.unit_amount,
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getProducts(): Promise<Array<StripeProduct> | undefined> {
    try {
      const response = await axiosPrivate.get(
        `/stripe/v1/products?expand[]=data.default_price&expand[]=data.default_price.tiers&active=true`
      );

      if (response?.status === 200) {
        return response?.data?.data?.map((u: any) => ({
          id: u.id,
          name: u.name,
          description: u.description,
          price: u.default_price,
          app_product: u.metadata['app_product'],
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getInvoices(stripeCustomerId: string): Promise<Array<StripeInvoice> | undefined> {
    try {
      const response = await axiosPrivate.get(`/stripe/v1/invoices?customer=${stripeCustomerId}`);

      if (response?.status === 200) {
        return response?.data?.data?.map((u: any) => ({
          id: u.id,
          amount_due: u.amount_due,
          amount_paid: u.amount_paid,
          due_date: u.due_date,
          invoice_pdf: u.invoice_pdf,
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getUpcomingInvoice(stripeCustomerId: string): Promise<Array<StripeInvoice> | undefined> {
    try {
      const response = await axiosPrivate.get(`/stripe/v1/invoices/upcoming?customer=${stripeCustomerId}`);

      if (response?.status === 200) {
        return response?.data?.data?.map((u: any) => ({
          id: u.id,
          amount_due: u.amount_due,
          amount_paid: u.amount_paid,
          due_date: u.due_date,
          invoice_pdf: u.invoice_pdf,
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
  async searchCustomer(clientId: string): Promise<string | undefined> {
    try {
      const response = await axiosPrivate.get(
        `/stripe/v1/customers/search?query=metadata['app_client']:'${clientId}' AND metadata['app_env']:'${environment}'`
      );

      if (response.status === 200) {
        if (response?.data?.data) {
          return response?.data?.data?.[0]?.id;
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new stripeService();
