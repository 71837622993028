import { axiosPrivate } from '../axios/axiosPrivate';

class StorageService {
  async uploadBlob(filePath: string, content: File): Promise<boolean> {
    try {
      var date = new Date().toUTCString();
      const requestOptions = {
        headers: {
          'Content-Type': content.type,
          'x-ms-date': date,
          'x-ms-blob-type': 'BlockBlob',
        },
      };
      const response = await axiosPrivate.put(`/storage/${filePath}`, content, requestOptions);

      if (response?.status === 201) {
        return true;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return false;
  }
}

export default new StorageService();
