import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import AdvancesService from '../../services/crmService/AdvancesService';
import { Advance, AdvanceDetails, formatUTCDate, moneyAmount } from '../../types';
import { RemittanceContext } from '../Users/Detail/Remittance/RemittanceContext';

interface IContactAdvanceProps {
  advance?: Advance;
}

const Wrapper = styled(Card)`
    display: flex;
    flex-direction: row;
    margin:10px;
    padding:10px;
    align-items: center;
    cursor: pointer;
    div:last-of-type {
      margin-left: auto;
    }
    &:hover {
        background-color: #f8f9fa;
    }
`;

const ContactAdvance = ({ advance }: IContactAdvanceProps) => {  
    const remittanceContext = useContext(RemittanceContext); 
    const dealId = remittanceContext.selectedAdvance?.dealId;

    const handleClick = () => {
      if(advance){
        remittanceContext.setSelectedAdvance(advance);
      }
    };

    return (
      <>
        <Wrapper onClick={handleClick} style={{borderColor:dealId === advance?.dealId ? '#3d8acb' :'', borderWidth:dealId === advance?.dealId ? 'medium': ''}} >
          <div style={{ marginLeft: '16px' }}>
          <div style={{ fontSize: '14px', color: '#2D3142', fontWeight: '600', marginBottom: '3px' }}>
             {moneyAmount(advance?.advanceAmount)}
            </div>
            <div style={{ fontSize: '10px', color: '#2D3142', fontWeight: '400', marginBottom: '1px' }}>
              Advance ID: {advance?.dealId}
            </div>
            <div style={{ fontSize: '12px', color: '#2D3142', fontWeight: '400', marginBottom: '1px' }}>
             Holdback: {advance?.remitPercent}%
            </div>
            {advance?.fundingDate &&
              <div style={{ fontSize: '12px', color: '#2D3142', fontWeight: '400', marginBottom: '3px' }}>
                Funded Date: { formatUTCDate(advance?.fundingDate) }
              </div>
            }
            <div style={{ fontSize: '12px', color: '#2D3142', fontWeight: '400', marginBottom: '3px' }}>
              Balance: {moneyAmount(advance?.balance)}
            </div>
           
           
          </div>
        </Wrapper>
      </>
    );
  };
  
  export { ContactAdvance };
  