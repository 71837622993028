/* eslint-disable react-hooks/exhaustive-deps */

import Steps from '@uiw/react-steps';
import { AdvanceTransaction, formatUTCDate, ReconciliationAdvanceTransaction } from '../../../../types';
import { Button, ListGroup } from 'react-bootstrap';
import { useContext } from 'react';
import { RemittanceContext } from './RemittanceContext';


interface IRemittancePaymentsProps {
    advanceTransactions: Array<ReconciliationAdvanceTransaction>;
}

const RemittancePayments = ({ advanceTransactions }: IRemittancePaymentsProps) => {
    const remittanceContext = useContext(RemittanceContext);
    const renderMoneyAmount = (value: number) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
  
      if (value < 0) {
        return <span className="txtPrice green">{formatter.format(value.valueOf()).replace('-', '')}</span>;
      } else {
        return <span className="txtPrice">{formatter.format(value.valueOf())}</span>;
      }
    };
  
    return (
      <>
        <ListGroup as="ol" >
            {advanceTransactions.sort((a,b)=> a.transactionDate.localeCompare(b.transactionDate) > 0 ? -1 : 1 )?.filter(f=>f.transactionType === 1).map((t: ReconciliationAdvanceTransaction) => (
              <ListGroup.Item
                as="li"
                key={t.advanceTransactionId}
                className="d-flex justify-content-between align-items-center"
              >
                <>
                      {
                        t.include &&  
                        <>
                           <div className="ms-2 me-auto">
                            <div><b style={ {marginRight:'10px'}} >{formatUTCDate(t.transactionDate)}</b>{t.title} {t.description}</div>
                          </div>
                          <div>{renderMoneyAmount(-t.amount)}</div>
                          <Button disabled={remittanceContext.advancePayments!.filter(f=>f.include).length<=1} variant='link' onClick={()=> remittanceContext.excludePayment(t.advanceTransactionId) } >  
                        exclude
                        </Button> 
                        </>
                       
                      }
                      {
                        !t.include &&  
                        <>
                        <div className="ms-2 me-auto">
                        <div className={'text-decoration-line-through'} ><b style={ {marginRight:'10px'}} >{formatUTCDate(t.transactionDate)}</b>{t.title} {t.description}</div>
                      </div>
                      <div className={'text-decoration-line-through'}>{renderMoneyAmount(t.amount)}</div>
                        <Button variant='link' onClick={()=> remittanceContext.includePayment(t.advanceTransactionId)} >  
                          include
                        </Button>
                        </>
                      } 
                </>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </>
    );
  }  

export { RemittancePayments };
