export default class Endpoints {
  static readonly LoginUrl = '/identity/Authentication';
  static readonly RefreshToken = '/identity/RefreshToken';

  static readonly HomeDashboardUrl = '/transactions/api/home/GetHomeDashboard';
  static readonly MoneyDashboardUrl = '/transactions/api/money/GetMoneyDashboard';
  static readonly AdvancesDashboardUrl = '/crm/api/v2/contacts/advances';
  static readonly AdvanceDetailsUrl = '/transactions/api/Advance/Details';
  static readonly DocumentsV2Url = '/crm/api/v2/contacts/documents';
  static readonly DocumentDetailsUrl = '/crm/api/v2/Documents/details';
  static readonly DocumentTypesUrl = '/crm/api/v2/Documents/types';
  static readonly HomeCRMDataUrl = '/crm/api/v2/contacts/summary';
  static readonly DocumentTypesFilterUrl = '/crm/api/v2/Documents/setfilter';
  static readonly AssignmentTypesUrl = '/crm/api/v2/Advances/assignments';
  static readonly AssignmentTypesFilterUrl = '/crm/api/v2/Advances/setAssignmentsFilter';

  static readonly AdvanceBalanceUrl = '/transactions/api/Advance/Balance';

  static readonly TasksDashboardUrl = '/crm/api/v2/contacts/tasks';
  static readonly TaskDetailsUrl = '/transactions/api/Task/Details';
  static readonly TaskUpdateStatusdUrl = '/transactions/api/Task/UpdateStatus';

  static readonly ImageUrl = '/Image';

  static readonly SetupWizardUrl = '/transactions/api/SetupWizard';
  static readonly UpdateProfileUrl = '/transactions/api/Profile/Update';
  static readonly GetProfileUrl = '/transactions/api/Profile/Details';

  static readonly ChangePasswordUrl = '/api/v1/Account/ChangePassword';
  static readonly ForgotPasswordUrl = '/api/v1/Passcode/Forgot';
  static readonly ResetPasswordUrl = '/api/v1/Passcode/Reset';

  static readonly PushNotificationsUrl = '/transactions/api/PushNotificationSettings';
  static readonly GetPushNotificationsUrl = Endpoints.PushNotificationsUrl + '/All';
  static readonly UpdatePushNotificationsUrl = Endpoints.PushNotificationsUrl + '/Setting';
  static readonly RegisterNewDeviceTokenUrl = Endpoints.PushNotificationsUrl + '/NewDevice';
  static readonly DeleteDeviceTokenUrl = Endpoints.PushNotificationsUrl + '/DeleteDevice/{deviceToken}';

  static readonly TermsAndConditionsUrl = '/api/v1/TermsConditions';
  static readonly PrivacyPolicyUrl = '/api/v1/PrivacyPolicy';

  static readonly MailSupportUrl = '/transactions/api/MailSupport';

  static readonly TransactionsUrl = '/transactions/api/Transaction/GetAll';

  static readonly BankAccountsUrl = '/transactions/api/BankAccount/All';
  static readonly PrimaryBankAccountUrl = '/transactions/api/BankAccount/DefaultAccount';
  static readonly DeletePrimaryBankAccountUrl = '/transactions/api/BankAccount/Account';

  static readonly NotificationsUrl = '/transactions/api/Notification/GetAll';
  static readonly NotificationsGetListNewUrl = '/transactions/api/Notification/ListNew';
  static readonly NotificationsMarkReadUrl = '/transactions/api/Notification/MarkRead';
  static readonly NotificationsDeleteUrl = '/transactions/api/Notification/Delete';

  static readonly SendNotification = '/admin/api/notifications';

  static readonly NotificationsCountSummaryUrl = '/transactions/api/Notification/GetSummaryNotification';

  static readonly PlaidLinkGetTokenUrl = '/transactions/api/PlaidLink/GetToken';
  static readonly PlaidrocessAccessTokendUrl = '/transactions/api/PlaidLink/ProcessAccessToken';
  static readonly PlaidCreatePublicTokendUrl = '/transactions/api/PlaidLink/CreatePublicToken';
  static readonly PlaidConfirmActionOnUrl = '/transactions/api/PlaidLink/ConfirmActionOn';

  static readonly NoteTypeUrl = '/crm/api/v2/Notes/types';
  static readonly NotesUrl = '/crm/api/v2/Notes/settings';

  static readonly CreateWebUserUrl = '/admin/api/users/create';

  static readonly CreateClientUrl = '/admin/api/clients';
  static readonly UpdateClientUrl = '/admin/api/clients';
  static readonly UpdateClientCurrentUrl = '/admin/api/clients/current';

  static readonly DisableUserUrl = '/admin/api/users/deactivate';
  static readonly EnableUserUrl = '/admin/api/users/activate';

  static readonly EmailTestUrl = '/admin/api/email/test';
  static readonly GetContactAdvancesUrl ='/crm/api/v2/Contacts/advances';
  static readonly GetAdvanceDetailsUrl ='/crm/api/v2/Advances/details';
}
