import { useContext, useEffect, useState } from 'react';
import { CheckoutForm } from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../services/authService/authProvider';
import clientsService from '../../services/clientsService/clientsService';
import { LoadingSpinnerComponent } from '../../components/Loading';

function AddPaymentMethod() {
  const auth = useContext(AuthContext);

  const [clientSecretStripe, setClientSecret] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    if (auth.clientId) {
      clientsService.createSetupIntent(auth.clientId).then(response => {
        setClientSecret(response);
        setIsLoading(false);
      });
    }
  }, [auth.clientId]);

  const stripeKey = process.env.REACT_APP_STRIPEPUBLISHABLEKEY;
  const stripePromise = loadStripe(stripeKey!);
  const options = {
    clientSecret: clientSecretStripe,
  };

  return (
    <>
      {isLoading && <LoadingSpinnerComponent />}

      {!isLoading && clientSecretStripe && (
        <>
          <div style={{ padding: '0 200px' }}>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          </div>
        </>
      )}
    </>
  );
}

export { AddPaymentMethod };
