import React, { createRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormikProps, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import { FormInput } from '../../components/Forms/FormInput';
import { UrlRegex, type Client, type ClientFormType } from '../../types';
import debtpayproService from '../../services/debtpayproService/debtpayproService';
import adminService from '../../services/adminService/adminService';
import { useAlertComponent } from '../../components/Alert/AlertProvider';
import { error } from 'console';
import { AxiosError } from 'axios';
import { AuthContext } from '../../services/authService/authProvider';

interface CredentialsTestProps {
  client?: Client | undefined;
  setTestStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const CredentialsTest = React.forwardRef(({ client, setTestStatus }: CredentialsTestProps, ref) => {
  const params = useParams();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const auth = useContext(AuthContext);
  const formRef = createRef<FormikProps<FormikValues>>();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ClientFormType>({
    crmUrl: '',
    crmClientSecret: '',
    crmKeyId: '',
    crmUserId: undefined,
    clientId: '',
    isTestClient: false,
  });

  const schema = yup.object().shape({
    crmKeyId: yup
      .string()
      .required('This is a required field.')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be exactly 4 digits')
      .max(4, 'Must be exactly 4 digits'),
    crmClientSecret: yup.string().required('This is a required field.'),
    crmUrl: yup.string().matches(UrlRegex, 'Please type a valid url').optional(),
  });

  const initialValues = {
    crmKeyId: client?.crmKeyId || '',
    crmClientSecret:  undefined,
  };

  useEffect(() => {
    if (client?.crmKeyId && client?.crmClientSecret) {
      setData(prevData => ({
        ...prevData,
        crmKeyId: client?.crmKeyId,
        crmClientSecret: client?.crmClientSecret,
        crmUrl: client?.crmUrl,
      }));
    }
  }, [client]);

  const validateForm = () => {
    if (formRef?.current) {
      formRef?.current?.submitForm();

      return formRef?.current?.isValid;
    }

    return false;
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return validateForm();
    },
    getValues() {
      if (formRef?.current) {
        return data;
      }

      return null;
    },
  }));

  const handlerSubmit = async () => {
    formRef?.current?.submitForm();
    if (!formRef?.current?.isValid) return;

    const formData = formRef?.current?.values;
    if (formData && (formData?.crmKeyId === '' || formData?.crmClientSecret === '')) return;

    setLoading(true);
    try{
      const testRes = await debtpayproService.testCredentials(formData?.crmKeyId, formData?.crmClientSecret);
      if(!testRes){
          throw new Error('Unable to get credentials test result');
      }
      const currentUser = await debtpayproService.getCurrentUser(testRes);
      if(!currentUser){
        throw new Error('Unable to get CRM key user');
      }
      let clientData : Client | undefined;
      if(auth.isAdmin){
        clientData = await adminService.getClientDetails(currentUser!.clientId, currentUser!.clientId);
      }
      else{
        clientData = await adminService.getCurrentClient();
      }
     
      if (clientData && clientData?.clientId) {
        setLoading(false);
        setIntervalDelay(1000);
        if (clientData?.clientId !== params.id) {
          setTestStatus(false);
          setAlertText(`This Account Id is for client ${clientData?.clientId}`);
          setVariant('dark'); 
        }
        else{
          setTestStatus(true);
          setAlertText('Succesfully tested Centrex CRM Credentials');
          setVariant('success');
          
        }
        setAlert(true);
      }
      setData(preData => ({
        ...preData,
        crmUserId: currentUser?.id,
        clientId: clientData ? clientData!.clientId : currentUser!.clientId,
        crmKeyId: formData?.crmKeyId,
        crmClientSecret: formData?.crmClientSecret,
      }));
      
    }
    catch(error:any){
      setLoading(false);
      setTestStatus(false);
      if(error instanceof AxiosError){
        setAlertText(error.response?.data?.status.message);
      }
      else if(error instanceof Error){
        setAlertText(error.message);
      }

      setVariant('dark');
      setAlert(true);
      setIntervalDelay(1000);
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Formik
            innerRef={formRef}
            validationSchema={schema}
            onSubmit={() => {}}
            initialValues={initialValues}
            validateOnMount={true}
            validateOnChange={true}
          >
            {({ handleChange, values, touched, errors }) => (
              <>
                <Form noValidate>
                  <Form.Group controlId="crmKeyId" style={{ marginBottom: '24px' }}>
                    <Form.Label className="label">CRM Key Id</Form.Label>
                    <Form.Control
                      as={FormInput}
                      type="text"
                      value={values.crmKeyId}
                      onChange={handleChange}
                      isInvalid={touched.crmKeyId && !!errors.crmKeyId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['crmKeyId'] === 'string' ? errors['crmKeyId'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="crmClientSecret" style={{ marginBottom: '40px' }}>
                    <Form.Label className="label">Client secret</Form.Label>
                    <Form.Control
                      as={FormInput}
                      type={values.crmClientSecret ? "text" : "password"}
                      value={values.crmClientSecret ?? "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
                      onChange={handleChange}
                      isInvalid={touched.crmClientSecret && !!errors.crmClientSecret}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['crmClientSecret'] === 'string' ? errors['crmClientSecret'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <Button className="btn-backround" style={{ float: 'right' }} onClick={handlerSubmit}>
                  <span>Test &nbsp;</span>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                </Button>
              </>
            )}
          </Formik>
        </Col>
        <Col md={6}></Col>
      </Row>
    </>
  );
});

CredentialsTest.displayName = 'CredentialsTest';
export default CredentialsTest;
