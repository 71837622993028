import { useParams } from 'react-router-dom';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import '../../custom.css';

import debtpayproService from '../../../services/debtpayproService/debtpayproService';
import adminService from '../../../services/adminService/adminService';

import { FormInput } from '../../../components/Forms/FormInput';
import { type ClientInfoType, type Client, type ClientFormType, UrlRegex } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import ModalComponent from '../../../components/Modal';
import { ComponentRef, forwardRef, useImperativeHandle, useState } from 'react';
import { MdRefresh, MdContentCopy } from "react-icons/md";
import settingsService from '../../../services/settingsService/settingsService';
import { LoadingSpinnerComponent } from '../../../components/Loading';

type SetTestAccountProps = {
  client: Client;
};

type Handle = {
  handlerFunction: (isTestAccount: boolean) => Promise<boolean>;
};

export type SetTestAccountRef = ComponentRef<typeof SetTestAccount>;

  const SetTestAccount = forwardRef<Handle,SetTestAccountProps>((props, ref) =>  {
  const [isLoading,setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (isTestAccount: boolean) : Promise<boolean> => {
    if(props?.client?.clientId){
      setIsLoading(true);
      try{
        await settingsService.setTestAccount(props?.client?.clientId, isTestAccount);
        setIsLoading(false);
        return Promise.resolve(true);
      }
      catch(exception){
        setIsLoading(false);
      }
      
    }
    return Promise.resolve(false);
  }

  useImperativeHandle(ref, () => ({
    handlerFunction(isTestAccount: boolean) {
      return handleSubmit(isTestAccount)
    }
  }));

  return (
        <>
          <p>
            If you switch this account to Test, ongoing stripe subscriptions will be canceled.
          </p>
          {isLoading && <LoadingSpinnerComponent />}
        </>
  );
});

export default SetTestAccount;
