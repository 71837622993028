import { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ContentCard } from '../../components/Card';
import { MonthlyUsage } from './monthlyUsage';
import { PaymentMethodTable } from '../PaymentMethod/paymentMethodTable';
import { PricingTable } from './pricingTable';
import type { btnOption } from '../../components/MenuButtons';
import MenuButtons from '../../components/MenuButtons';
import stripeService from '../../services/stripeService/stripeService';
import clientsService from '../../services/clientsService/clientsService';
import { AuthContext } from '../../services/authService/authProvider';
import adminService from '../../services/adminService/adminService';

const menus: btnOption[] = [
  {
    title: 'Billing Details',
  },
  {
    title: 'Manage Payment',
  },
  {
    title: 'Pricing Details',
  },
];

function ClientUsage() {
  const [selectView, setSelectView] = useState<string>('Billing Details');
  const [stripeCustomerId, setStripeCustomerId] = useState<string | undefined>(undefined);
  const auth = useContext(AuthContext);
  
  useEffect(()=>{
    (async()=> {
      if(auth && auth.clientId){
        const client = await adminService.getClientDetails(auth.clientId,auth.clientId);
        if(client && client.stripeCustomerId){
          const invoices = await stripeService.getInvoices(client?.stripeCustomerId);
        }
      } 
    })()
  },[])
  return (
    <>
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
          <h3 style={{ color: 'white' }}>Billing</h3>
        </div>
        <ContentCard>
          <Container>
            <div style={{ width: '100%', marginBottom: '40px' }}>
              <MenuButtons titles={menus} selectView={selectView} setSelectView={setSelectView} />
            </div>
            <div>
              {selectView === 'Billing Details' && <MonthlyUsage />}
              {(selectView === 'Manage Payment' || selectView === 'Add Payment') && (
                <PaymentMethodTable selectView={selectView} setSelectView={setSelectView} />
              )}
              {selectView === 'Pricing Details' && <PricingTable />}
            </div>
          </Container>
        </ContentCard>
      </Container>
    </>
  );
}

export { ClientUsage };
