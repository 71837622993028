import { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../components/Forms/FormInput';
import { type ClientInfoType } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import { PushNotificationType } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

export default function PushNotification({ client, loading, setLoading }: ClientInfoType) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [pushData, setPushData] = useState<PushNotificationType | undefined>(undefined);

  const schema = yup.object().shape({
    appId: yup.string().nullable().optional(),
    appName: yup.string().nullable().optional(),
    googlePrivateKey: yup.string().nullable().optional(),
    googleClientEmail: yup.string().nullable().optional(),
    googleProjectId: yup.string().nullable().optional(),
    keyId: yup.string().nullable().optional(),
    token: yup.string().nullable().optional(),
  });

  const initialValues: PushNotificationType = {
    appId: pushData?.appId || '',
    appName: pushData?.appName || '',
    googlePrivateKey: pushData?.googlePrivateKey || '',
    googleClientEmail: pushData?.googleClientEmail || '',
    googleProjectId: pushData?.googleProjectId || '',
    keyId: pushData?.keyId || '',
    token: pushData?.token || '',
  };

  useEffect(() => {
    if (client) {
      adminService.getClientPushNotification(client?.id).then((rs: PushNotificationType | undefined) => {
        setPushData(rs);
      });
    }
  }, [client]);

  const handlerSubmit = async (data: PushNotificationType) => {
    if (setLoading) setLoading(true);

    try {
      const response = await adminService.updateClientPushNotification({ ...data, clientId: client?.id });

      if (response && response?.status === 200) {
        setAlertText('Succesfully update push notification');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
      } else {
        setAlertText('Unable to update push notification');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }
    } catch (error) {
      setAlertText('Unable to update push notification');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    if (setLoading) setLoading(false);
  };

  return (
    <div className="client-settings">
      <div style={{ marginBottom: '100px' }}>
        <Formik
          validationSchema={schema}
          onSubmit={handlerSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <h1 className="title">Apple APNS</h1>
              <div style={{ marginBottom: '40px' }}>
                <Form.Group controlId="appId" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">App Id</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={values.appId}
                    onChange={handleChange}
                    isInvalid={touched.appId && !!errors.appId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['appId'] === 'string' ? errors['appId'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="appName" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">App Name</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={values.appName}
                    onChange={handleChange}
                    isInvalid={touched.appName && !!errors.appName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['appName'] === 'string' ? errors['appName'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="keyId" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">Key Id</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={values.keyId}
                    onChange={handleChange}
                    isInvalid={touched.keyId && !!errors.keyId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['keyId'] === 'string' ? errors['keyId'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="token">
                  <Form.Label className="label">Token</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="password"
                    value={values.token}
                    onChange={handleChange}
                    isInvalid={touched.token && !!errors.token}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['token'] === 'string' ? errors['token'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div style={{ marginBottom: '24px' }}>
                <h1 className="title">Google FCMv1</h1>
                <Form.Group controlId="googleClientEmail">
                  <Form.Label className="label">Client Email</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={values.googleClientEmail}
                    onChange={handleChange}
                    isInvalid={touched.googleClientEmail && !!errors.googleClientEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['googleClientEmail'] === 'string' ? errors['googleClientEmail'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="googleProjectId">
                  <Form.Label className="label">Project Id</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={values.googleProjectId}
                    onChange={handleChange}
                    isInvalid={touched.googleProjectId && !!errors.googleProjectId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['googleProjectId'] === 'string' ? errors['googleProjectId'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="googlePrivateKey">
                  <Form.Label className="label">Private Key</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="password"
                    value={values.googlePrivateKey}
                    onChange={handleChange}
                    isInvalid={touched.googlePrivateKey && !!errors.googlePrivateKey}
                  />
                  <Form.Control.Feedback type="invalid">
                    {typeof errors['googlePrivateKey'] === 'string' ? errors['googlePrivateKey'] : ''}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Button className="btn-backround" type="submit" style={{ float: 'right' }}>
                <span>Save &nbsp;</span>
                {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
