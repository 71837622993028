import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormInput } from '../../../components/Forms/FormInput';
import authService from '../../../services/authService/authService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import { AuthContext } from '../../../services/authService/authProvider';


export default function TwoFactorAuth() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  
  const email = window.localStorage.getItem('emailAuth');
  const phone = window.localStorage.getItem('phone2fa');
  
  const [code, setCode] = useState('');
  const [error, setError] = useState<string>('');
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [signinIn, setSigninIn] = useState<boolean>(false);


  const handleSubmit = (event: any) => {
    event.preventDefault();
    setServerError(undefined);

    if (code && code.length ===0) {
      setError('Code is required');
      return;
    }

    setSigninIn(true);

    try {
      auth.signIn2fa(email!, code, () => {
          navigate('/', { replace: true });
        })
        .then(
          () => {},
          error => {
            if (typeof error === 'string') {
              setServerError(error);
            } else {
              setServerError('Unable to login');
              console.log(error);
            }
            setSigninIn(false);
          }
        );
    } catch (e) {
      setSigninIn(false);
    }
  };

  return (
    <Row className="justify-content-center">
      <div className="login-form" style={{ maxWidth: '384px' }}>
        <h1 className="title">Two factor login</h1>
            <Form noValidate>
            {
                phone && email ? 
                <p className="mb-0">We sent a verification code to your phone number {phone}. Enter the code below to to sign in</p> :
                <p className="mb-0">Please login using username and password </p>  
            }
              <Form.Group controlId="code" className="mb-4">
                <Form.Label className="form-label">
                  <p className="mb-0">Verification Code</p>
                </Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="text"
                  maxLength={6}
                  autoComplete="off"
                  placeholder="Enter verification code"
                  onChange={e => setCode(e.target.value)}
                  isInvalid={!!error}
                  className="form-input"
                  value={code}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                 
                </Form.Control.Feedback>
              </Form.Group>
              <span>{serverError ?? serverError}</span>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button
                  className="cancel-btn"
                  style={{ width: '175px' }}
                  onClick={() => {
                    navigate('/auth/login', { replace: true });
                  }}
                >
                  Cancel
                </Button>
                <Button 
                    type="submit" 
                    onClick={handleSubmit}
                    disabled={signinIn || (!phone && !email)}
                    className="btn-form" 
                    style={{ height: 'auto', width: '175px' }}>
                  {signinIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  <span>&nbsp;&nbsp;Submit</span>
                </Button>
              </div>
            </Form>
      </div>
    </Row>
  );
}
