import { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../services/authService/authProvider';
import { useNavigate } from 'react-router-dom';
import { memoizedRefreshToken } from '../../services/axios/refreshToken';

const timeout = 1000 * 15 * 60;
const promptBeforeIdle = 1000 * 60;

function IdleTimerPrompt() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    auth.signOut(() => {
      navigate('/auth/login', { replace: true });
    });
  };

  const onPrompt = () => {
    setOpen(true);
    setRemaining(promptBeforeIdle);
  };

  const onActive = () => {
    setOpen(false);
    //setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
    memoizedRefreshToken();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = getRemainingTime();
      setRemaining(Math.ceil(remainingTime / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      <Modal show={open} centered>
        <Modal.Body>Logging you out in {remaining} seconds</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleStillHere}>I'm Still Here</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default IdleTimerPrompt;
