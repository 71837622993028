import { useState, useEffect } from 'react';
import styled from 'styled-components';
import plaidService from '../../services/plaidService/plaidService';
import Image from 'react-bootstrap/Image';
import { AiOutlineBank } from 'react-icons/ai';
interface InstitutionLogoProps {
  id: string;
  size: number;
}

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 48px;
  height: auto;
`;

const StyledInstitution = styled.div`
  width: 100%;
  max-width: 48px;
  height: auto;
`;

function InstitutionLogo({ id, size }: InstitutionLogoProps) {
  const [logo, setLogo] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (id) {
      plaidService.getInstitution(id).then((result) => {
        setLogo(result.logo);
        setColor(result.primary_color);
      });
    }
  }, [id]);

  return (
    <>
      <div>
        {logo && <StyledImage src={'data:image/png;base64, ' + logo}></StyledImage>}
        {!logo && (
          <StyledInstitution>
            <AiOutlineBank size={size} color={color} />
          </StyledInstitution>
        )}
      </div>
    </>
  );
}

export { InstitutionLogo };
