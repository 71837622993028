import { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormInput } from '../../../components/Forms/FormInput';
import authService from '../../../services/authService/authService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

export default function ChangePassword({ setModal }: { setModal: React.Dispatch<React.SetStateAction<boolean>> }) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const [loading, setLoading] = useState<boolean>(false);

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required('Please enter the password.')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/,
        `Passwords must have at least 6 characters in length and least one uppercase ('A'-'Z').`
      ),
    newPassword: yup
      .string()
      .required('Please enter the password.')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/,
        `Passwords must have at least 6 characters in length and least one uppercase ('A'-'Z').`
      ),
    confirmPassword: yup
      .string()
      .required('Please repeat the password.')
      .oneOf([yup.ref('newPassword')], 'Passwords do not match. Please try again.'),
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handleSubmit = async (data: { oldPassword: string; newPassword: string }) => {
    try {
      setLoading(true);
      const response = await authService.setChangePassword(data.oldPassword, data.newPassword);

      if (response?.status === 200) {
        setAlertText('Password was succesfully change.');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
        setModal(false);
      } else {
        setAlertText('Unable to change new password');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }

      setLoading(false);
    } catch (error) {
      setAlertText(error as string);
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
      <div className="login-form">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} id="changePassword">
              <Form.Group controlId="oldPassword" className="mt-4 mb-3">
                <Form.Label className="form-label">Old Password</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="password"
                  placeholder="Enter Old Password"
                  onChange={handleChange}
                  isInvalid={touched.oldPassword && !!errors.oldPassword}
                  className="form-input"
                  value={values.oldPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['oldPassword'] === 'string' ? errors['oldPassword'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="newPassword" className="mt-4 mb-3">
                <Form.Label className="form-label">New Password</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="password"
                  placeholder="Enter New Password"
                  onChange={handleChange}
                  isInvalid={touched.newPassword && !!errors.newPassword}
                  className="form-input"
                  value={values.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['newPassword'] === 'string' ? errors['newPassword'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="confirmPassword" className="mt-4 mb-3">
                <Form.Label className="form-label">New Confirm Password</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="password"
                  placeholder="Enter New Confirm Password"
                  onChange={handleChange}
                  isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                  className="form-input"
                  value={values.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['confirmPassword'] === 'string' ? errors['confirmPassword'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingBottom: '20px',
                }}
              >
                <Button className="cancel-btn" style={{ width: '175px' }} onClick={() => setModal(false)}>
                  Cancel
                </Button>
                <Button type="submit" className="btn-form" style={{ height: 'auto', width: '175px' }}>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  <span>&nbsp;&nbsp;Submit</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Row>
  );
}
