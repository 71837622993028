import { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import '../custom.css';

interface ContentProps {
  title?: string;
  bodyPadding?: string;
}

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;
const PaddedDiv = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    padding: 0em;
  }
`;
const StyledCard = styled(Card)`
  border-radius: 1em;
  @media screen and (max-width: 575px) {
    border: none;
    padding: 0;
    .card-body {
      padding: 0;
    }
  }
`;

const RoundCard = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledCard {...newprops} />;
};

const UsersCard = ({ title, bodyPadding, children }: PropsWithChildren<ContentProps>) => {
  return (
    <>
      <Wrapper>
        <RoundCard>
          <PaddedDiv>
            {title && (
              <Card.Title>
                <h3 style={{ fontSize: '20px', color: '#2D3142' }}>{title}</h3>
              </Card.Title>
            )}
          </PaddedDiv>
          <Card.Body style={{ padding: bodyPadding }}>{children}</Card.Body>
        </RoundCard>
      </Wrapper>
    </>
  );
};

export { UsersCard };
