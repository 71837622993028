import { useNavigate } from 'react-router-dom';
import { Button, Row } from 'react-bootstrap';

export default function SendEmailSuccess() {
  const navigate = useNavigate();
  const email = window.localStorage.getItem('forgotEmail');

  return (
    <Row className="justify-content-center">
      <div className="login-form" style={{ maxWidth: '384px' }}>
        <h1 className="title text-center">An email has been sent to {email}</h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '50px  ' }}>
          <Button
            className="cancel-btn"
            style={{ width: '175px' }}
            onClick={() => {
              navigate('/auth/login', { replace: true });
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-form"
            style={{ height: 'auto', width: '175px' }}
            onClick={() => {
              navigate('/auth/reset-password', { replace: true });
            }}
          >
            Enter Passcode
          </Button>
        </div>
      </div>
    </Row>
  );
}
