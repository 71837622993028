import { ReactNode, forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  headerTitle?: ReactNode;
  title?: ReactNode;
  content?: ReactNode;
  className?: string;
  bodyClass?: string;
  size?: 'sm' | 'lg' | 'xl';
  centered?: boolean;
  showClose?: boolean;
  cancelTxt?: ReactNode;
  okTxt?: ReactNode;
  cancelVariant?: 'primary' | 'success' | 'warning' | 'default' | 'secondary' | 'danger' | 'info' | 'dark';
  okVariant?: 'primary' | 'success' | 'warning' | 'default' | 'secondary' | 'danger' | 'info' | 'dark';
  backdrop?: 'static';
  isFooter: boolean;
  enableClick?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ModalComponent = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      modal,
      setModal,
      headerTitle = '',
      title = '',
      content = '',
      className = '',
      bodyClass = '',
      size,
      centered = false,
      showClose = true,
      cancelTxt = 'Close',
      okTxt = 'Save',
      cancelVariant = 'secondary',
      okVariant = 'primary',
      backdrop,
      isFooter,
      onClose,
      onClick,
      enableClick = true,
      ...props
    },
    ref
  ) => {
    const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClose) {
        onClose(e);
        return;
      }

      setModal(false);
    };

    const handleApple = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
        return;
      }

      setModal(false);
    };
    return (
      <Modal
        {...props}
        {...(size ? { size: size } : {})}
        size={size}
        ref={ref}
        centered={centered}
        className={`${className}`}
        show={modal}
        onHide={() => setModal(false)}
        backdrop={backdrop}
      >
        <Modal.Header closeButton={showClose}>
          <Modal.Title id="contained-modal-title-vcenter">{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${bodyClass}`}>
          <div>{title}</div>
          <div>{content}</div>
        </Modal.Body>
        {isFooter && (
          <Modal.Footer>
            {onClose && (
              <Button variant={cancelVariant} onClick={handleClose}>
                {cancelTxt}
              </Button>
            )}

            {onClick && (
              <Button variant={okVariant} onClick={handleApple} disabled={!enableClick}>
                {okTxt}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
);

ModalComponent.displayName = 'ModalComponent';
export default ModalComponent;
