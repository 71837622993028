import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Container, Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import { ContentCard } from '../../components/Card';
import { PageHeader } from '../../components/Layout/pageHeader';
import { FormInput } from '../../components/Forms/FormInput';
import { ClientsTable } from './clientsTable';
import { WebUsersTable } from './webUsersTable';
import { AuthContext } from '../../services/authService/authProvider';
import adminService from '../../services/adminService/adminService';
import { Client, WebUser } from '../../types';

function AdminPage() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [clientList, setClientList] = useState<Client[] | undefined>();
  const [userList, setUserList] = useState<WebUser[] | undefined>();
  const [selectView, setSelectView] = useState<string>('Clients');
  const [search, setSearch] = useState<string>('');
  const [clientCount, setClientCount] = useState<number>(0);
  const [userCount, setUserCount] = useState<number>(0);

  useEffect(() => {
    const user = auth.getUser();

    if (user) {
      adminService.getClients().then(result => {
        setClientList(result);

        if (result) {
          setClientCount(result.length);
        }
      });

      adminService.getClientUsers().then(result => {
        setUserList(result);

        if (result) {
          setUserCount(result.length);
        }
      });
    }
  }, [auth]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Container fluid>
        <PageHeader>Client Management</PageHeader>
        <ContentCard>
          <Container>
            <div className="admin-header">
              <ButtonGroup className="btnGroup">
                <Button className={selectView === 'Clients' ? `btnCustom btnActive` : `btnCustom`} onClick={() => setSelectView('Clients')}>
                  <span>Clients</span>
                  {clientCount !== 0 && <span className="badge">{clientCount}</span>}
                </Button>
                <Button className={selectView === 'Users' ? `btnCustom btnActive` : `btnCustom`} onClick={() => setSelectView('Users')}>
                  <span>Users</span>
                  {userCount !== 0 && <span className="badge">{userCount}</span>}
                </Button>
              </ButtonGroup>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ width: '300px' }}>
                  <InputGroup>
                    <Form.Control
                      as={FormInput}
                      type="search"
                      placeholder={selectView === 'Clients' ? 'Search client' : 'Search user'}
                      value={search}
                      onChange={handleSearch}
                      autoComplete="off"
                    />
                    <InputGroup.Text
                      style={{ backgroundColor: 'transparent', border: '1px solid #ced4da', borderWidth: '0 0 1px', borderRadius: '0' }}
                    >
                      <FaSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                {selectView === 'Clients' && (
                  <Button className="btn-backround" style={{ marginLeft: '24px' }} onClick={() => navigate('/admin/clients/create')}>
                    Add Client
                  </Button>
                )}

                {selectView === 'Users' && (
                  <Button className="btn-backround" style={{ marginLeft: '24px' }} onClick={() => navigate('/admin/create-webuser')}>
                    Add user
                  </Button>
                )}
              </div>
            </div>
            <div>
              {selectView === 'Clients' && <ClientsTable search={search} clientList={clientList} />}

              {selectView === 'Users' && <WebUsersTable search={search} userData={userList} />}
            </div>
          </Container>
        </ContentCard>
      </Container>
    </>
  );
}

export { AdminPage };
