import { Button, ButtonGroup } from 'react-bootstrap';

export type btnOption = {
  title: string;
  count?: number;
  disabled?: boolean;
};

interface MenuButtonsProps {
  titles: btnOption[];
  selectView: string;
  setSelectView: React.Dispatch<React.SetStateAction<string>>;
}

export default function MenuButtons({ titles, selectView, setSelectView }: MenuButtonsProps) {
  return (
    <ButtonGroup className="btnGroup">
      {titles.map((rs: btnOption, key: number) => (
        <Button
          key={key}
          className={selectView === rs.title ? `btnCustom btnActive` : `btnCustom`}
          onClick={() => setSelectView(rs.title)}
          disabled={rs.disabled}
        >
          <span>{rs.title}</span>
          {rs.count && rs.count !== 0 && <span className="badge">{rs.count}</span>}
        </Button>
      ))}
    </ButtonGroup>
  );
}
