import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const StyledFormInput = styled(Form.Control)`
  border-radius: 0;
  padding: 5px 0;
  border-width: 0 0 2px;
  box-shadow: none !important;
  &:focus {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
  &.is-invalid {
    color: red;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
`;

const FormInput = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledFormInput {...newprops} />;
};

export { FormInput };
