import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import stripeService from '../../services/stripeService/stripeService';
import { StripeProduct } from '../../types';
import { CompactTable } from '@table-library/react-table-library/compact';
import { LoadingSpinnerComponent } from '../../components/Loading';

function PricingTable() {
  const [products, setProducts] = useState<Array<StripeProduct> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const COLUMNS = [
    { label: 'Quantity', renderCell: (item: any) => item.up_to },
    { label: 'Price per unit', renderCell: (item: any) => renderMoneyAmount(item.unit_amount) },
    { label: 'Flat rate', renderCell: (item: any) => renderMoneyAmount(item.flat_amount) },
  ];

  const renderMoneyAmount = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    if (value < 0 || !value) {
      return <span>--</span>;
    } else {
      return <span>{formatter.format(value.valueOf() / 100)}</span>;
    }
  };

  useEffect(() => {
    setLoading(true);

    stripeService.getProducts().then(p => {
      setProducts(p);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinnerComponent />
      ) : (
        <>
          {products &&
            products.map((p: StripeProduct, key: number) => {
              return p.price?.billing_scheme === 'tiered' ? (
                <Fragment key={key}>
                  <Row>
                    <Col>
                      <h5>{p.name}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{p.description}</p>
                    </Col>

                    <CompactTable columns={COLUMNS} data={{ nodes: p.price.tiers }} />
                  </Row>
                </Fragment>
              ) : (
                <Fragment key={key}>
                  <h5>{p.name}</h5>
                  <p>{p.description}</p>
                  <p>Unit amount: {renderMoneyAmount(p.price?.unit_amount)}</p>
                </Fragment>
              );
            })}
        </>
      )}
    </>
  );
}

export { PricingTable };
