import { useFormikContext } from "formik";
import { useAlertComponent } from "../../../../components/Alert/AlertProvider";
import { useContext, useState } from "react";
import emailService from "../../../../services/emailService/emailService";
import { AxiosError } from "axios";
import { AuthContext } from "../../../../services/authService/authProvider";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { ClientEmailSettings } from "../../../../types";


const NotEmptyString = (value: string | undefined) =>  {
    return  value ==='' ? undefined : value;
} 

type SubmitTestProps = {
    clientId:string,
    enabled: boolean,
    sendgridApiKey?: string,
    emailFrom?: string,
    fromName?: string
  }
  export const SubmitTest = ({ clientId,  sendgridApiKey, emailFrom, fromName, enabled } : SubmitTestProps) => {
    const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
    const { errors, validateForm, values , setValues, setTouched, isValid } = useFormikContext<ClientEmailSettings>();
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const [testSuccess, setTestSuccess] = useState<boolean | undefined>(undefined);
    const [testError, setTestError] = useState<string | undefined>(undefined);
    let auth = useContext(AuthContext);
  
    const handleEmailTest = async () => {
      setTestLoading(true);
      if(enabled){ 
        await validateForm();
        
        if(!isValid){
          setTestLoading(false);
          return;
        }
     } 
      try {
        const res = await emailService.test(clientId, auth.getUser()?.email!, NotEmptyString(sendgridApiKey), NotEmptyString(emailFrom),NotEmptyString(fromName));
        if (res?.status === 'OK') {
          setTestLoading(false);
          setTestSuccess(true);      
          window.setTimeout(()=>{
            setTestSuccess(undefined)
          },3000)
        }
        else{
          setTestLoading(false);
          setTestSuccess(false);      
          window.setTimeout(()=>{
            setTestSuccess(undefined)
          },3000)
          setTestError(res?.status);
          //setIntervalDelay(1000);
        }
      } 
        catch(error:any){
          setTestLoading(false)
          if(error instanceof AxiosError){
            setAlertText(error.response?.data?.status.message);
          }
          else if(error instanceof Error){
            setAlertText(error.message);
          }
    
          setVariant('dark');
          setAlert(true);
          setIntervalDelay(1000);
        }
    };
  
    return (
      <>
        <Button variant="secondary" style={{ float: 'right' }} onClick={handleEmailTest}>
          Test 
          {testLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </Button>
        <Row>
          <Col>
          { testSuccess && (
            <Alert variant='success'>Succesfuly verified Api key, an email has been sent to {auth.getUser()?.email!}</Alert>
          )}

        { testSuccess === false && (
            <Alert variant='warning'>Unable to verify Api key: {testError}</Alert>
          )}      
          </Col>
        </Row>
        
    </>
    );
  }
  