import React, { createRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormikProps, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import { error } from 'console';
import { AxiosError } from 'axios';
import { Client, ClientFormType, UrlRegex } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import { AuthContext } from '../../../services/authService/authProvider';
import debtpayproService from '../../../services/debtpayproService/debtpayproService';
import adminService from '../../../services/adminService/adminService';
import { FormInput } from '../../../components/Forms/FormInput';


interface CredentialsTestProps {
  client?: Client | undefined;
  setTestStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const CredentialsTest = React.forwardRef(({ client, setTestStatus }: CredentialsTestProps, ref) => {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const auth = useContext(AuthContext);
  const formRef = createRef<FormikProps<FormikValues>>();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ClientFormType>({
    crmUrl: '',
    crmClientSecret: '',
    crmKeyId: '',
    crmUserId: undefined,
    clientId: '',
    isTestClient: false,
  });

  const schema = yup.object().shape({
    crmKeyId: yup
      .string()
      .required('This is a required field.')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be exactly 4 digits')
      .max(4, 'Must be exactly 4 digits'),
    crmClientSecret: yup.string().required('This is a required field.'),
    crmUrl: yup.string().matches(UrlRegex, 'Please type a valid url').optional(),
  });

  const initialValues = {
    crmKeyId: client?.crmKeyId || '',
    crmClientSecret:  undefined,
  };

  useEffect(() => {
    if (client?.crmKeyId && client?.crmClientSecret) {
      setData(prevData => ({
        ...prevData,
        crmKeyId: client?.crmKeyId,
        crmClientSecret: client?.crmClientSecret,
        crmUrl: client?.crmUrl,
      }));
    }
  }, [client]);

  const validateForm = () => {
    if (formRef?.current) {
      formRef?.current?.submitForm();

      return formRef?.current?.isValid;
    }

    return false;
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return validateForm();
    },
    getValues() {
      if (formRef?.current) {
        return data;
      }

      return null;
    },
  }));

  const getCurrentUser = async (formData: FormikValues): Promise<string | undefined>  =>  {
    try{
      const testRes = await debtpayproService.testCredentials(formData?.crmKeyId, formData?.crmClientSecret);
      if(!testRes){
          throw new Error('Unable to get credentials test result');
      }
      const currentUser = await debtpayproService.getCurrentUser(testRes);
      if(!currentUser){
        throw new Error('Unable to get CRM key user');
      }
      return currentUser.clientId;
    }
    catch(error:any){
      setLoading(false);
      setTestStatus(false);
      if(error instanceof AxiosError){
        setAlertText(error.response?.data?.status.message);
      }
      else if(error instanceof Error){
        setAlertText(error.message);
      }

      setVariant('dark');
      setAlert(true);
      setIntervalDelay(1000);
    }
    return undefined;
  }


  const handlerSubmit = async () => {
    formRef?.current?.submitForm();
    if (!formRef?.current?.isValid) return;

    const formData = formRef?.current?.values;
    if (formData && (formData?.crmKeyId === '' || formData?.crmClientSecret === '')) return;

    setLoading(true);
   
      let clientId = await getCurrentUser(formData);
      let clientData : Client | undefined;
      if(auth.isAdmin && clientId){
        try {
          clientData = await adminService.getClientDetails(clientId, clientId);
          if (clientData && clientData?.clientId) {
            setLoading(false);
            setIntervalDelay(1000);
            setTestStatus(false);
            setAlertText(`This Account Id is for client ${clientData?.clientId}`);
            setVariant('dark'); 
            setAlert(true);
          }
        }
        catch { 
        setTestStatus(true);
        setAlertText('Succesfully tested Centrex CRM Credentials');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
        setLoading(false);
      setData(preData => ({
        ...preData,
        crmUserId: clientId,
        clientId: clientData ? clientData!.clientId : clientId,
        crmKeyId: formData?.crmKeyId,
        crmClientSecret: formData?.crmClientSecret,
      }));
        }
        
      }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Formik
            innerRef={formRef}
            validationSchema={schema}
            onSubmit={() => {}}
            initialValues={initialValues}
            validateOnMount={true}
            validateOnChange={true}
          >
            {({ handleChange, values, touched, errors }) => (
              <>
                <Form noValidate autoComplete='off'>
                  <Form.Group controlId="crmKeyId" style={{ marginBottom: '24px' }}>
                    <Form.Label className="label">CRM Key Id</Form.Label>
                    <Form.Control
                      as={FormInput}
                      type="text"
                      value={values.crmKeyId}
                      onChange={handleChange}
                       autoComplete="new-password"
                      isInvalid={touched.crmKeyId && !!errors.crmKeyId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['crmKeyId'] === 'string' ? errors['crmKeyId'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="crmClientSecret" style={{ marginBottom: '40px' }}>
                    <Form.Label className="label">Client secret</Form.Label>
                    <Form.Control
                      as={FormInput}
                      type="password"
                      value={values.crmClientSecret}
                      autoComplete="new-password"
                      onChange={handleChange}
                      isInvalid={touched.crmClientSecret && !!errors.crmClientSecret}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['crmClientSecret'] === 'string' ? errors['crmClientSecret'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <Button className="btn-backround" style={{ float: 'right' }} onClick={handlerSubmit}>
                  <span>Test &nbsp;</span>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                </Button>
              </>
            )}
          </Formik>
        </Col>
        <Col md={6}></Col>
      </Row>
    </>
  );
});

CredentialsTest.displayName = 'CredentialsTest';
export default CredentialsTest;
