import React, { ReactNode } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AccountContext } from '../../services/accountService/accountsProvider';
import { AccountSummary } from '../../types';
import close from '../../assets/image-icons/close.png';
import coins from '../../assets/image-icons/coins.png';
import coins2 from '../../assets/image-icons/coins2.png';
import money from '../../assets/image-icons/money.png';
import seo from '../../assets/image-icons/seo.png';
import topUp from '../../assets/image-icons/top-up.png';
import wallet from '../../assets/image-icons/wallet.png';
import warning from '../../assets/image-icons/warning.png';

const StyledListItem = styled(ListGroup.Item)`
  color: #2d3142;
  margin-top: 0em;
  margin-bottom: 0em;
  &:nth-of-type(n + 2) {
    border-left: 1px solid rgb(45, 49, 66, 0.1);
  }
`;
const StyledListGroup = styled(ListGroup)`
  &:nth-of-type(n + 2) {
    border-top: 1px solid rgb(45, 49, 66, 0.1);
  }
`;

const StyledGroupWrapper = styled.div`
  border-radius: 24px;
  background-color: #f4f5f6;
  margin-bottom: 18px;
`;

const ItemWrapper = styled(Container)`
  padding: 10px;
`;

const formatMoney = (value: string) => {
  if (value?.startsWith('.')) {
    return '$0' + value;
  } else {
    return '$' + value?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
};

const Circle = styled.div`
  width: 48px;
  height: 48px;
  background: rgba(26, 134, 203, 0.05);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin: auto;
  display: block;
`;
const NumberText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  color: #2d3142;
  margin-top: 5px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #4c5980;
`;
const ImageIcon = styled.img`
  margin-top: 13px;
`;

interface  AccountSummaryReportProps {
  accountSummary?: AccountSummary;
}

const AccountSummaryReport = ({ accountSummary } : AccountSummaryReportProps) => {


  const ItemData = (Icon: ReactNode, title: string, value: string) => {
    return (
      <ItemWrapper>
        <Row>
          <Col className="text-center">
            <Circle className="text-center">{Icon}</Circle>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <NumberText>{value}</NumberText>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Text>{title}</Text>
          </Col>
        </Row>
      </ItemWrapper>
    );
  };

  return (
    <>
      {accountSummary && (
        <StyledGroupWrapper>
          <StyledListGroup horizontal>
            <StyledListItem as={Col} md={3}>
              {ItemData(
                <ImageIcon src={topUp}></ImageIcon>,
                'Total Deposits',
                formatMoney(accountSummary.totalDeposits)
              )}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={seo}></ImageIcon>, 'Average Balance', formatMoney(accountSummary.avgBalance))}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(
                <ImageIcon src={coins}></ImageIcon>,
                'Starting Balance',
                formatMoney(accountSummary.startingBalance)
              )}{' '}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={coins2}></ImageIcon>, 'End Balance', formatMoney(accountSummary.endBalance))}
            </StyledListItem>
          </StyledListGroup>
          <StyledListGroup horizontal>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={wallet}></ImageIcon>, '# of Deposits', accountSummary.noDeposits)}{' '}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={money}></ImageIcon>, 'Withdrawals', accountSummary.withdrawals)}{' '}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={warning}></ImageIcon>, 'Days Negative', accountSummary.daysNegative)}{' '}
            </StyledListItem>
            <StyledListItem as={Col} md={3}>
              {ItemData(<ImageIcon src={close}></ImageIcon>, 'NSF ', accountSummary.nsf)}
            </StyledListItem>
          </StyledListGroup>
        </StyledGroupWrapper>
      )}
    </>
  );
}

export { AccountSummaryReport };
