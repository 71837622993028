import mem from 'mem';
import { AppUser, getAppUserFromAuth } from '../../types';
import { axiosPublic } from './axiosPublic';

const refreshTokenFn = async () => {
  const localUser = window.localStorage.getItem('AppUser') || null;

  if (localUser != null) {
    const user = JSON.parse(localUser!) as AppUser;

    try {
      const response: any = await axiosPublic.get('/admin/api/refreshToken?token=' + user.refreshToken);
      const session = response.data;

      if (!session?.accessToken) {
        window.localStorage.removeItem('AppUser');
      }

      const newUser = getAppUserFromAuth(response.data);
      window.localStorage.setItem('AppUser', JSON.stringify(newUser));

      return session;
    } catch (error) {
      window.localStorage.removeItem('AppUser');
      window.location.href = '/auth/login';
    }
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
