import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../components/Forms/FormInput';
import type { ClientInfoType, ClientFormType, Client } from '../../../types';
import { EmailRegex, NoWhiteSpaceRegex } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import settingsService from '../../../services/settingsService/settingsService';
import CheckBox from '../../../components/Forms/CheckBox';
import ModalComponent from '../../../components/Modal';
import { useContext, useEffect, useRef, useState } from 'react';

import { AuthContext } from '../../../services/authService/authProvider';
import SetTestAccount, { SetTestAccountRef } from './TestAccount';

export default function ClientInfo({ client, loading, setLoading }: ClientInfoType) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [testAccountModal, setTestAccountModal] = useState<boolean>(false);
  const [isTestAccount, setIsTestAccount] = useState<boolean>(client?.isTestAccount ? true : false);

  const testAccountRef = useRef<SetTestAccountRef>(null);
  let auth = useContext(AuthContext);

  useEffect(()=>{

  }
  ,[isTestAccount])


  const schema = yup.object().shape({
    clientName: yup.string().required('Client Name is required'),
    subdomain: yup
      .string()
      .matches(NoWhiteSpaceRegex, 'Please type a valid subdomain witouth whitespaces')
      .required('Subdomain is required'),
    billingEmail: yup
      .string()
      .matches(EmailRegex, 'Please type a valid email address')
      .required('Client billing email is required'),
    phone: yup.string().nullable().optional(),
    address: yup.string().nullable().optional(),
    website: yup.string().nullable().optional(),
    appStoreLink: yup.string().nullable().optional(),
    playStoreLink: yup.string().nullable().optional(),
  });

  const initialValues = {
    clientName: client?.clientName || '',
    subdomain: client?.subdomain || '',
    billingEmail: client?.billingEmail || '',
    phone: client?.phone || '',
    address: client?.address || '',
    website: client?.website || '',
    appStoreLink: client?.appStoreLink || '',
    playStoreLink: client?.playStoreLink || '',
  };

  const handleTestAccountChange= async () =>{ 
      setTestAccountModal(true);
  }

  const handleTestAccountSubmit= async () =>{ 
    const success = await testAccountRef.current?.handlerFunction(!client?.isTestAccount);
    if(success){
      setAlertText('Succesfully updated test account');
      setVariant('success');
      setAlert(true);
      setIntervalDelay(1000);
      setTestAccountModal(false);
      setIsTestAccount(!client?.isTestAccount);
    }
    else{
      setAlertText('Unable to update client info');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }
    
  }
  const handlerSubmit = async (data: ClientFormType) => {
    if (setLoading) setLoading(true);

    try {
      const submit = {
        ...data,
        id: client?.id,
        clientId: client?.id,
        crmClientSecret: client?.crmClientSecret,
        crmUrl: client?.crmUrl,
        crmKeyId: client?.crmKeyId,
        crmUserId: client?.crmUserId,
        emailFrom: client?.emailFrom,
        icon: client?.icon ? client?.icon : '',
        isTestClient: client?.isTestClient,
        logo: client?.logo,
        nameFrom: client?.nameFrom,
        primaryColor: client?.primaryColor ? client?.primaryColor : '',
        sendgridApiKey: client?.sendgridApiKey ? client?.sendgridApiKey : '',
        whiteLogo: client?.whiteLogo ? client?.whiteLogo : '',
      };

       await settingsService.setInfo(submit as Client);

        setAlertText('Succesfully update client info');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
    } catch (error) {
      setAlertText('Unable to update client info');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    if (setLoading) setLoading(false);
  };

  return (
    <div className="client-settings">
      <ModalComponent
        modal={testAccountModal}
        size="lg"
        setModal={setTestAccountModal}
        backdrop="static"
        centered={true}
        headerTitle={'Switch account to '}
        content={
          <SetTestAccount client={client!} ref={testAccountRef}  />
        }
        okVariant={'primary'}
        okTxt={
          <>
            { <span>Save</span>}
            &nbsp;
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </>
        }
        onClose={() => setTestAccountModal(false)}
        onClick={() => handleTestAccountSubmit() }
        isFooter={true}
      />
      <h1 className="title">Client Info</h1>
      <div style={{ marginBottom: '100px' }}>
        <Formik
          validationSchema={schema}
          onSubmit={handlerSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="isTestAccount" style={{ marginBottom: '24px' }}>
                <Form.Check
                    type={'switch'}
                    label={'Test Account'}
                    onChange={handleTestAccountChange}
                    checked={isTestAccount}
                    disabled = {!auth.isAdmin}
                  />
              </Form.Group>
              <Form.Group controlId="clientName" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Client Name</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.clientName}
                  onChange={handleChange}
                  isInvalid={touched.clientName && !!errors.clientName}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['clientName'] === 'string' ? errors['clientName'] : ''}
                </Form.Control.Feedback>
              </Form.Group> 
              <Form.Group controlId="subdomain" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Short Name / Subdomain</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.subdomain}
                  onChange={handleChange}
                  isInvalid={touched.subdomain && !!errors.subdomain}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['subdomain'] === 'string' ? errors['subdomain'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="address" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Address</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                  isInvalid={touched.address && !!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['address'] === 'string' ? errors['address'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phone" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Phone Number</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={touched.phone && !!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['phone'] === 'string' ? errors['phone'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="billingEmail" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Billing Email</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.billingEmail}
                  onChange={handleChange}
                  isInvalid={touched.billingEmail && !!errors.billingEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['billingEmail'] === 'string' ? errors['billingEmail'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="website" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Website</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.website}
                  onChange={handleChange}
                  isInvalid={touched.website && !!errors.website}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['website'] === 'string' ? errors['website'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="appStoreLink" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Apple App Store Link</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.appStoreLink}
                  onChange={handleChange}
                  isInvalid={touched.appStoreLink && !!errors.appStoreLink}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['appStoreLink'] === 'string' ? errors['appStoreLink'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="playStoreLink" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Android Playstore Link</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.playStoreLink}
                  onChange={handleChange}
                  isInvalid={touched.playStoreLink && !!errors.playStoreLink}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['playStoreLink'] === 'string' ? errors['playStoreLink'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="btn-backround" type="submit" style={{ float: 'right' }}>
                <span>Save &nbsp;</span>
                {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
