import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { FormInput } from '../../../components/Forms/FormInput';
import authService from '../../../services/authService/authService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const email = window.localStorage.getItem('forgotEmail');

  const [loading, setLoading] = useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required.')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address. Please try again.'),
    code: yup.string().required('Verification code is required!'),
    password: yup
      .string()
      .required('Please enter the password.')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/,
        `Passwords must have at least 6 characters in length and least one uppercase ('A'-'Z').`
      ),
    confirmPassword: yup
      .string()
      .required('Please repeat the password.')
      .oneOf([yup.ref('password')], 'Passwords do not match. Please try again.'),
  });

  const initialValues = {
    email: email || '',
    code: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (data: { email: string; code: string; password: string }) => {
    try {
      setLoading(true);
      const response = await authService.setResetNewPassword(data.email, data.code, data.password);

      if (response?.status === 200) {
        setAlertText('Password was succesfully reset.');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);

        setTimeout(() => {
          navigate('/auth/login', { replace: true });
        }, 3000);
      } else {
        setAlertText('Unable to reset new password');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }

      setLoading(false);
    } catch (error) {
      setAlertText(error as string);
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
      <div className="login-form" style={{ maxWidth: '384px' }}>
        <h1 className="title">Password Reset</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="email" className="mb-4">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="email"
                  placeholder="Enter your email address"
                  isInvalid={touched.email && !!errors.email}
                  className="form-input"
                  readOnly
                  value={values.email}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['email'] === 'string' ? errors['email'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="code" className="mb-4">
                <Form.Label className="form-label">
                  <p className="mb-0">Verification Code</p>
                  <span style={{ fontSize: '12px', color: '#ced4da' }}>
                    (Verification code received from Reset Email)
                  </span>
                </Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="text"
                  placeholder="Enter verification code"
                  isInvalid={touched.code && !!errors.code}
                  className="form-input"
                  value={values.code}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['code'] === 'string' ? errors['code'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password" className="mt-4 mb-3">
                <Form.Label className="form-label">New Password</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="password"
                  placeholder="Enter New Password"
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                  className="form-input"
                  value={values.password}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['password'] === 'string' ? errors['password'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="confirmPassword" className="mt-4 mb-3">
                <Form.Label className="form-label">New Confirm Password</Form.Label>
                <Form.Control
                  required
                  as={FormInput}
                  type="password"
                  placeholder="Enter New Confirm Password"
                  onChange={handleChange}
                  isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                  className="form-input"
                  value={values.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['confirmPassword'] === 'string' ? errors['confirmPassword'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button
                  className="cancel-btn"
                  style={{ width: '175px' }}
                  onClick={() => {
                    navigate('/auth/login', { replace: true });
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" className="btn-form" style={{ height: 'auto', width: '175px' }}>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  <span>&nbsp;&nbsp;Submit</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Row>
  );
}
