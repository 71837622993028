import { CardStored } from '../../types';
import { FaRegTimesCircle } from 'react-icons/fa';
import iconElo from '../../assets/image-icons/elo.svg';
import iconUnionpay from '../../assets/image-icons/unionpay.svg';
import iconJcb from '../../assets/image-icons/jcb.svg';
import iconDiners from '../../assets/image-icons/diners.svg';
import iconDiscover from '../../assets/image-icons/discover.svg';
import iconAmex from '../../assets/image-icons/amex.svg';
import iconMastercard from '../../assets/image-icons/mastercard.svg';
import iconVisa from '../../assets/image-icons/visa.svg';

interface PaymentCardItemProps {
  cardInfo: CardStored;
  defaultPaymentMethod: string;
}

const ICON = [
  {
    name: 'elo',
    src: iconElo,
  },
  {
    name: 'unionpay',
    src: iconUnionpay,
  },
  {
    name: 'jcb',
    src: iconJcb,
  },
  {
    name: 'diners',
    src: iconDiners,
  },
  {
    name: 'discover',
    src: iconDiscover,
  },
  {
    name: 'amex',
    src: iconAmex,
  },
  {
    name: 'mastercard',
    src: iconMastercard,
  },
  {
    name: 'visa',
    src: iconVisa,
  },
];

export default function PaymentCardItem({ cardInfo, defaultPaymentMethod }: PaymentCardItemProps) {
  const setIcon = ICON.find(rs => rs.name === cardInfo?.brand);

  return (
    <div className="payment-card-item">
      <div className="header">
        <div>
          <img className="icon" src={setIcon?.src} alt="" />
          &nbsp;
          <span style={{ color: 'red', fontSize: '13px', fontWeight: 'bold' }}>{defaultPaymentMethod === cardInfo.id ? 'Default' : ''}</span>
        </div>
        <FaRegTimesCircle style={{ width: '18px', height: '18px', color: '#D75053' }} />
      </div>
      <h3 className="bank-name">Jerome Bell</h3>
      <p className="card-num mb-0">**** **** **** {cardInfo?.last4}</p>
    </div>
  );
}
