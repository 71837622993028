import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';

export type TestEmailResponse = {
  status: string;
  response: string;
};
class EmailService {
  async test(
    clientId: string,
    emailTo: string,
    apiKey: string | null | undefined = undefined,
    emailFrom: string | undefined = undefined,
    emailFromName: string | undefined = undefined
  ): Promise<TestEmailResponse | undefined> {
    try {
      const response = await axiosPrivate.post(Endpoints.EmailTestUrl, { clientId, emailTo, emailFrom, emailFromName, apiKey});

      if (response?.status === 200) {
        return {
          status: response?.data?.status,
          response: response?.data?.response
        };
      }
    } catch (err: any) {
      if (err?.response && err?.response?.status === 400) {
        if (err?.response?.data?.errors && typeof err?.response?.data?.errors === 'object') {
          const errors = err?.response?.data?.errors;
          return Promise.reject(errors);
        }
      }
    }

    return undefined;
  }
}

export default new EmailService();
