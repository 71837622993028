import { useRef, useState } from 'react';
import { Row, Col, Form, Overlay, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ImageUploadHandle, ThumbImage } from '../../../components/Media/ImageThumb';
import { SketchPicker } from '@hello-pangea/color-picker';
import { ClientAssets, type ClientInfoType } from '../../../types';
import { Client, ClientFormType, HexColorRegex, ImageFileRegex } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import settingsService from '../../../services/settingsService/settingsService';

export default function Assets({ client, loading, setLoading }: ClientInfoType) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const logoCompRef = useRef<ImageUploadHandle>(null);
  const whiteLogoCompRef = useRef<ImageUploadHandle>(null);
  const iconCompRef = useRef<ImageUploadHandle>(null);
  const target = useRef(null);

  const [displayPicker, setDisplayPicker] = useState<boolean>(false);
  const [change, setChange] = useState<boolean>(false);

  const schema = yup.object().shape({
    logo: yup.string().matches(ImageFileRegex, 'Please type a valid Logo').required('Logo is Required'),
    icon: yup.string().matches(ImageFileRegex, 'Please type a valid Icon').required('Icon is Required'),
    primaryColor: yup
      .string()
      .matches(HexColorRegex, 'Please type a valid Hex Color ex: #FF5733')
      .required('Primary color is required'),
    whiteLogo: yup.string().matches(ImageFileRegex, 'Please type a valid White Logo').required('White Logo is Required')
  });

  const initialValues = {
    logo: client?.logo || '',
    icon: client?.icon || '',
    primaryColor: client?.primaryColor || '#888888',
    secondaryColor: client?.secondaryColor || '#888888',
    whiteLogo: client?.whiteLogo || '',
    whiteIcon: client?.whiteIcon || ''
  };

  const handleSubmit = async (data: ClientAssets) => {
    if (setLoading) setLoading(true);

    try {
        await settingsService.setAssets(data as ClientAssets, client?.clientId!);
        setAlertText('Succesfully update assets');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
    } catch (error) {
      setAlertText('Unable to update assets');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    if (setLoading) setLoading(false);
  };

  return (
    <div
      className="client-settings"
      onClick={() => {
        if (!change) {
          setDisplayPicker(false);
        }

        setChange(false);
      }}
      style={{ marginBottom: '100px' }}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize
      >
        {({ handleChange, handleSubmit, values, touched, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <h1 className="title">Logos</h1>
            <Row style={{ marginBottom: '40px' }}>
            <Col md={6}>
                <div className="row-item" style={{ justifyContent: 'right' }}>
                  <Form.Group controlId="logo">
                    <Form.Control
                      type="hidden"
                      value={values.logo ? values.logo : ''}
                      onChange={handleChange}
                      isInvalid={touched.logo && !!errors.logo}
                    />
                    <ThumbImage
                      className="default-logo-item"
                      path="client-images"
                      defaultURL={values.logo}
                      ref={logoCompRef}
                      onUpdate={v => {
                        setFieldValue('logo', v, true);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['logo'] === 'string' ? errors['logo'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="logo-info">
                    <p className="txt-1" style={{ marginBottom: '11px' }}>
                      Upload Client Logo
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Dimension
                    </p>
                    <p className="txt-3" style={{ marginBottom: '11px' }}>
                      200 x 40 px or 5:1 ratio
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Supported Format
                    </p>
                    <p className="txt-3" style={{ marginBottom: '0' }}>
                      PNG and JPG/JPEG
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="row-item" style={{ justifyContent: 'left' }}>
                  <Form.Group controlId="icon">
                    <Form.Control
                      type="hidden"
                      value={values.icon ? values.icon : ''}
                      onChange={handleChange}
                      isInvalid={touched.icon && !!errors.icon}
                    />
                    <ThumbImage
                      className="default-logo-item"
                      path="client-images"
                      defaultURL={values.icon}
                      ref={iconCompRef}
                      onUpdate={v => {
                        setFieldValue('icon', v, true);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['icon'] === 'string' ? errors['icon'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="logo-info">
                    <p className="txt-1" style={{ marginBottom: '11px' }}>
                      Upload Client Icon
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Dimension
                    </p>
                    <p className="txt-3" style={{ marginBottom: '11px' }}>
                      1024 x 1024 px
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Supported Format
                    </p>
                    <p className="txt-3" style={{ marginBottom: '0' }}>
                      PNG and JPG/JPEG
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px', backgroundColor: `${values.primaryColor}` }}>
              <Col md={6}>
                <div className="row-item" style={{ justifyContent: 'right' }}>
                  <Form.Group controlId="whiteLogo">
                    <Form.Control
                      type="hidden"
                      value={values.whiteLogo ? values.whiteLogo : ''}
                      onChange={handleChange}
                      isInvalid={touched.whiteLogo && !!errors.whiteLogo}
                    />
                    <ThumbImage
                      className="default-logo-item"
                      path="client-images"
                      defaultURL={values.whiteLogo}
                      ref={whiteLogoCompRef}
                      onUpdate={v => {
                        setFieldValue('whiteLogo', v, true);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors['whiteLogo'] === 'string' ? errors['whiteLogo'] : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="whiteLogo-info">
                    <p className="txt-1" style={{ marginBottom: '11px' }}>
                      Upload Client whiteLogo
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Dimension
                    </p>
                    <p className="txt-3" style={{ marginBottom: '11px' }}>
                      200 x 40 px or 5:1 ratio
                    </p>
                    <p className="txt-2" style={{ marginBottom: '3px' }}>
                      Supported Format
                    </p>
                    <p className="txt-3" style={{ marginBottom: '0' }}>
                      PNG and JPG/JPEG
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <h1 className="title">Color</h1>
            <Row style={{ marginBottom: '40px' }}>
              <Col>
                <div
                  ref={target}
                  style={{ width: '300px', height: '50px', backgroundColor: values.primaryColor, cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation();
                    setDisplayPicker(!displayPicker);
                  }}
                ></div>
                <Overlay target={target.current} show={displayPicker} placement="right" rootClose onHide={() => setDisplayPicker(false)}>
                  {({ arrowProps: _arrowProps, show: _show, ...props }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      <SketchPicker
                        onChange={value => {
                          setChange(true);
                          setFieldValue('primaryColor', value.hex);
                        }}
                        onSwatchHover={() => setChange(true)}
                        onChangeComplete={() => setChange(false)}
                        color={values.primaryColor}
                      />
                    </div>
                  )}
                </Overlay>
                <Form.Control.Feedback type="invalid">
                      {typeof errors['primaryColor'] === 'string' ? errors['primaryColor'] : ''}
                    </Form.Control.Feedback>
              </Col>
            </Row>
            <Button className="btn-backround" onClick={()=>handleSubmit()} style={{ float: 'right' }}>
              <span>Save&nbsp;</span>
              {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
