import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../services/authService/authProvider';
import { FormInput } from '../../components/Forms/FormInput';
import authService from '../../services/authService/authService';
import { ContentCard } from '../../components/Card';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import styled from 'styled-components';
import ConfirmPhone from './ConfirmPhone';

const PhoneWrapper = styled(PhoneInput)`
    .PhoneInputInput {
        border:none;
        outline: none;
    }
`;

export default function VerifyPhone() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const userName = auth.getUser()?.email;

  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>('');
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const validatePhone = async(number: string) => { 
        if(number && isPossiblePhoneNumber(number)){
            setPhone(number);
        }
        else{
            setPhone(undefined);
        }
  }
  const handleSubmit = async (event: any) =>  {
    event.preventDefault();
    setServerError(undefined);

    if (phone && (phone.length === 0 || !isPossiblePhoneNumber(phone))) {
      setError('Valid Phone number is required');
      return;
    }

    setLoading(true);

    try {
        if(phone){
            await authService.verifyPhone(phone);
            setNextStep(true);
        }
        
    } catch (e) {
        setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
            {!nextStep && 
            <div className="login-form" style={{ maxWidth: '384px' }}>
                <h1 className="title">Phone number verification</h1>
                    <Form noValidate>
                        <p className="mb-0">A verification code will be sent to your phone number</p>
                    <Form.Group controlId="code" className="mb-4">
                        <Form.Label className="form-label">
                        <p className="mb-0">Mobile Phone Number</p>
                        </Form.Label>
                        <Form.Control
                            required
                            as={PhoneWrapper}
                            type="text"
                            maxLength={18}
                            defaultCountry="US"
                            international
                            countryCallingCodeEditable={false}
                            autoComplete="off"
                            placeholder="Enter verification code"
                            onChange={e=> e ? validatePhone(e?.toString()) : ''}
                            isInvalid={!!error}
                            className="form-input"
                        />
                        <Form.Control.Feedback type="invalid">
                        {error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <span>{serverError ?? serverError}</span>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button
                        className="cancel-btn"
                        style={{ width: '175px' }}
                        onClick={() => {
                            navigate('/auth/login', { replace: true });
                        }}
                        >
                        Cancel
                        </Button>
                        <Button 
                            type="submit" 
                            onClick={handleSubmit}
                            disabled={loading || !phone}
                            className="btn-form" 
                            style={{ height: 'auto', width: '175px' }}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        <span>&nbsp;&nbsp;Send Code</span>
                        </Button>
                    </div>
                    </Form>
            </div>
            }
            {
                nextStep && phone && userName && <ConfirmPhone phoneNumber={phone}  />
            }
    </Row>
  );
}
