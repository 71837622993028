import { useEffect, useState } from 'react';
import { Container, Row as BRow, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { format } from 'date-fns';

import { Data, TableNode } from '@table-library/react-table-library/table';
import { CompactTable } from '@table-library/react-table-library/compact';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import styled from 'styled-components';
import { LoadingSpinnerComponent } from '../../Loading';

import adminService from '../../../services/adminService/adminService';
import type { RecentActivityType, TableItem } from '../../../types';

import { FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';

interface RecentActivityProps {
  userId: string;
  clientId: string;
}

const StyledGroupText = styled(InputGroup.Text)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
`;

const StyledGroupButton = styled(Button)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: #4c5980;
  padding: 5px;
  &.btn:disabled {
    background-color: transparent;
    color: #4c5980;
  }
`;

export default function RecentActivity({ userId, clientId }: RecentActivityProps) {
  const theme = useTheme(getTheme());

  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Data<TableNode>>({ nodes: [] });
  const [perPage, setPerPage] = useState<number>(10);

  useEffect(() => {
    (async () => {
      if (userId) {
        const data = await adminService.getUserRecentActivity(userId, clientId);
        //const tableItems = data?.map((r, i) => ({ ...r} as TableItem<RecentActivityType>));
        setTableLoaded(true);
        const tableData = { nodes: data} as unknown as Data<TableNode>;
        setTableData(tableData);
      }
    })();
  }, [userId, clientId]);

  const capitalize = (value: string) => {
    let retStr = '';
    const tmpStr = value.split('-');

    for (let i = 0; i < tmpStr.length; i++) {
      retStr += `${tmpStr[i].charAt(0).toUpperCase() + tmpStr[i].slice(1)} `;
    }

    return retStr;
  };

  const pagination = usePagination(tableData, {
    state: {
      page: 0,
      size: perPage,
    },
  });

  const COLUMNS = [
    {
      label: 'Title',
      renderCell: (item: TableNode) => capitalize(item.eventType),
      resize: true,
    },
    {
      label: 'Description',
      renderCell: (item: TableNode) => item.description,
      resize: true,
    },
    { label: 'Date', renderCell: (item: TableNode) => format(new Date(item?.date), 'MM/dd/yy, hh:mm a'), resize: true },
  ];

  return (
    <>
      {!tableLoaded && <LoadingSpinnerComponent />}

      {tableLoaded && tableData.nodes.length === 0 && (
        <Container>
          <BRow className="justify-content-md-center">
            <Col md="auto">
              <span>Data not available</span>
            </Col>
          </BRow>
        </Container>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <>
          {/* <Table data={tableData} pagination={pagination} theme={theme}>
            {tableList => (
              <>
                <Header>
                  <HeaderRow>
                    <StyledHeaderCell>#</StyledHeaderCell>
                    <StyledHeaderCell>Title</StyledHeaderCell>
                    <StyledHeaderCell>Description</StyledHeaderCell>
                    <StyledHeaderCell>Date</StyledHeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item: TableNode, key: number) => (
                    <StyledRow item={item} key={key}>
                      <Cell>{item.number}</Cell>
                      <Cell>{capitalize(item.eventType)}</Cell>
                      <Cell>{item.description}</Cell>
                      <Cell>{format(new Date(item?.date), 'MM/dd/yy, hh:mm a')}</Cell>
                    </StyledRow>
                  ))}
                </Body>
              </>
            )}
          </Table> */}
          <CompactTable columns={COLUMNS} data={tableData} theme={theme} pagination={pagination} />
        </>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <BRow style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            {tableData.nodes.length} items in total
          </Col>
          <Col md={9} xs={7}>
            <InputGroup className="justify-content-end">
              <div style={{ width: '270px' }}>
                <Form.Select
                  defaultValue={perPage}
                  style={{ outline: 'none', boxShadow: 'none', border: 'none' }}
                  onChange={e => setPerPage(Number(e.target.value))}
                >
                  <option value="10">Showing 10 items per page</option>
                  <option value="20">Showing 20 items per page</option>
                  <option value="50">Showing 50 items per page</option>
                  <option value="100">Showing 100 items per page</option>
                </Form.Select>
              </div>
              <StyledGroupButton disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                <FaStepBackward />
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page === 0}
                onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
              >
                <FaPlay style={{ transform: 'rotate(180deg)' }} />
              </StyledGroupButton>
              <StyledGroupText>
                {pagination.state.getPageBoundaries(tableData.nodes).start}
                {'-'}
                {pagination.state.getPageBoundaries(tableData.nodes).end}
              </StyledGroupText>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
              >
                <FaPlay></FaPlay>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(tableData.nodes) - 1)}
              >
                <FaStepForward />
              </StyledGroupButton>
            </InputGroup>
          </Col>
        </BRow>
      )}
    </>
  );
}
