import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './authProvider';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();
  const appUser = auth.getUser();

  if (!appUser) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  if (!auth.is2FAEnabled) {
    return <Navigate to="/auth/verify-phone" state={{ from: location }} replace />;   
  }
  return children;
}

export { RequireAuth };
