import React, { useRef } from "react";
import { useState } from "react";
import { Overlay,Popover} from "react-bootstrap";
import { DateRange, DefinedRangeProps, Preview } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import './index.css';
import { DateRangePicker } from 'react-date-range';
import { endOfDay, isSameDay, startOfDay, sub } from "date-fns";
import { Range, StaticRange } from "react-date-range";

interface IStaticRangeHandler  { 
  range?: (props?: DefinedRangeProps)  => Preview ,
  isSelected:(range: Range) => boolean
}
const defineds = {
  today: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  lastWeek:sub(new Date(), { weeks: 1 }),
  lastMonth:sub(new Date(), { months: 1 }),
  lastYear: sub(new Date(), { years: 1 }),
  last30: sub(new Date(), { days: 29 }),
  last45: sub(new Date(), { days: 44 }),
  last60: sub(new Date(), { days: 59 }),
  last90: sub(new Date(), { days: 89 })
};

const staticRangeHandler: IStaticRangeHandler = {
  range: undefined,
  isSelected(range: Range) {
    if(!this.range)
      return false;
    const definedRange = this.range;
    return (
      isSameDay(range.startDate!, definedRange()!.startDate!) &&
      isSameDay(range.endDate!, definedRange()!.endDate!)
    );
  }
};

interface StaticRangeDefaultInit {
  range: (props?: DefinedRangeProps) => Preview;
  label?: string | undefined;

}

function createStaticRanges(ranges: StaticRangeDefaultInit[]) : StaticRange[] {
  const newRanges = ranges.map(range => ({ ...staticRangeHandler, ...range}));
  return newRanges;
}

export const defaultRanges = createStaticRanges([
  {
    label: 'Last week',
    range: () => ({
      startDate: defineds.lastWeek,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last month',
    range: () => ({
      startDate: defineds.lastMonth,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last year',
    range: () => ({
      startDate: defineds.lastYear,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '30 days',
    range: () => ({
      startDate: defineds.last30,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '45 days',
    range: () => ({
      startDate: defineds.last45,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '60 days',
    range: () => ({
      startDate: defineds.last60,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '90 days',
    range: () => ({
      startDate: defineds.last90,
      endDate: defineds.endOfToday,
    }),
  }
]);

const CollapsibleDateRangePicker = (props: any) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const target = useRef(null);
  return (
    <>
      <div onClick={() => setCollapsed(!collapsed)}   ref={target}>
        <DateRange className={"inline"} {...props} />
      </div>
      <Overlay
          show={!collapsed}
          target={target.current}
          placement="bottom"
          container={this}
          containerPadding={30}
          rootClose
          onHide={() => setCollapsed(true)}
        >
          <Popover id="popover-contained" title="Popover bottom" >
            <DateRange  className={"open"} {...props} />
          </Popover>
        </Overlay>
    </>
  );
};


const CollapsibleDateRangePicker2 = (props: any) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const target = useRef(null);

  return (
    <>
      <div onClick={() => setCollapsed(!collapsed)}   ref={target}>
        <DateRangePicker className={"inline"} {...props} />
      </div>
      <Overlay
          show={!collapsed}
          target={target.current}
          placement="bottom"
          container={this}
          containerPadding={30}
          rootClose
          onHide={() => setCollapsed(true)}
        >
          <Popover id="popover-contained" title="Popover bottom" >
            <DateRangePicker className={"open"} {...props} />
          </Popover>
        </Overlay>
    </>
  );
};



export { CollapsibleDateRangePicker };
export { CollapsibleDateRangePicker2 };