import { Outlet } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

import '../custom.css';
import imgBackground from '../../assets/login_bg.png';
import imgLogo from '../../assets/centrex-logo-v4.jpg';
import imgContent1 from '../../assets/login_image_1.png';

function Login() {
  return (
    <div className="wrapper">
      <Row className="m-0 h-100 login-container">
        <Col md={6} className="p-0">
          <div className="login-left-content">
            <img src={imgLogo} alt="logo" className="login-logo"></img>
            <div className="login-content">
              <Container>
                <Outlet />
              </Container>
            </div>
          </div>
        </Col>
        <Col md={6} className="p-0">
          <div className="login-right-content">
            <img className="background-image" src={imgBackground} alt="background"></img>
            <div>
              <p className="title">
                Fully Branded for your<br></br>
                Customers
              </p>
              <p className="subtitle">
                It is in the best interest of your fund to protect your customer and it is in the best interest of your
                customer to to protect your fund.
              </p>
            </div>
            <div>
              <Carousel>
                <Carousel.Item>
                  <img className="img-content" src={imgContent1} alt="Content 1" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="img-content" src={imgContent1} alt="Content 2" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="img-content" src={imgContent1} alt="Content 3" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export { Login };
