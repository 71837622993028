import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AuthContext } from '../../services/authService/authProvider';
import { btnOption } from '../../components/MenuButtons';
import { addDays, sub } from 'date-fns';
import { PageHeader } from '../../components/Layout/pageHeader';
import { OverviewCard } from '../../components/Card/OverviewCard';
import { CollapsibleDateRangePicker2, defaultRanges } from '../../components/DateRangePicker';
import { UsersOverview } from '../../components/Users/usersOverview';
import { UsersCard } from '../../components/Card/UsersCard';
import { UsersTable } from '../Users/usersTable';
import { ContentCard } from '../../components/Card';
import { UsersStats } from '../../components/Users/usersStats';
import { UsersSales } from '../../components/Users/usersSales';
import { PushNotifications } from '../../components/Forms/PushNotifications';
import { Range } from "react-date-range";

export type SelectFilterType = {
  id: number;
  label: string;
  value: string;
  checked: boolean;
};

const StyledRow = styled(Row)`
  max-width: 1400px;
`;

function Dashboard() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [startDate, setStartDate] = useState<Date>(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [selectionRanges, setSelectionRanges] = useState<Range[]>([{
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
    key: "rangeSel",
  }]);

  const [periodOverview, setPeriodOverview] = useState<string>('Day');

      const handleDateChange = (ranges: any) => {
        
       if(ranges['rangeSel'] && ranges['rangeSel'].startDate && ranges['rangeSel'].endDate){
        
         const nextRanges = [...selectionRanges];
         const rangeToUpdate = nextRanges.find(
           (t) => t.key === 'rangeSel'
         );
         if(rangeToUpdate){
           rangeToUpdate.startDate = ranges['rangeSel'].startDate;
           rangeToUpdate.endDate = ranges['rangeSel'].endDate;
           setStartDate(new Date(rangeToUpdate.startDate!));
           setEndDate(new Date(rangeToUpdate.endDate!));
           setSelectionRanges(nextRanges);  
        }
       } 

     }

  const [menu, setMenu] = useState<btnOption[]>([
    {
      title: 'Day',
      disabled: false,
    },
    {
      title: 'Week',
      disabled: false,
    },
    {
      title: 'Month',
      disabled: false,
    },
  ]);
  const [selectFilter, setSelectFilter] = useState<SelectFilterType[]>([
    {
      id: 0,
      label: 'Active Users',
      value: 'totalUsers',
      checked: true,
    },
    {
      id: 1,
      label: 'New Users',
      value: 'newUsers',
      checked: true,
    },
    {
      id: 2,
      label: 'Plaid Connections',
      value: 'totalPlaidConnections',
      checked: true,
    },
  ]);
  useEffect(() => {
    if (auth && !auth?.isAdmin) {
      const user = auth?.getUser();
      auth.setSelectedClientId(user?.clientId as string);
    }
  }, [auth, startDate, endDate]);

  return (
    <>
      <div className="d-none d-sm-block">
        <PageHeader>Welcome Back, {auth.getUser()?.name}</PageHeader>
      </div>
      <Container fluid>
        {auth.clientId && auth.clientId !== '0' && (
          <StyledRow>
            <Col sm={12} xs={12} lg={8} xl={8}>
              <OverviewCard
                title="Overview"
                period={periodOverview}
                setPeriod={setPeriodOverview}
                selectFilter={selectFilter}
                setSelectFilter={setSelectFilter}
                menu={menu}
              >
              <Row className="justify-content-md-center">
                <Col sm={12} xs={12} lg={6} xl={6} >
                  <CollapsibleDateRangePicker2
                    key={`1234`}
                    onChange={(date:Range[]) => handleDateChange(date)}
                    moveRangeOnFirstSelection={false}
                    ranges={selectionRanges}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    maxDate = {new Date()}
                    calendarFocus="backwards"
                    staticRanges = {defaultRanges}
                  />
                </Col>
              </Row>
                <UsersOverview
                  startDate={startDate}
                  endDate={endDate}
                  selectFilter={selectFilter}
                  period={periodOverview}
                  setMenu={setMenu}
                />
              </OverviewCard>
              <UsersCard title="Users">
                <UsersTable rows={10} hiddenColumns={['STATUS', 'CONNECTIONS']} />
              </UsersCard>
            </Col>
            <Col sm={12} xs={12} lg={4} xl={4}>
              <ContentCard>
                <div style={{ cursor: 'pointer' }} onClick={() => navigate('/billing')}>
                  <UsersStats />
                </div>
              </ContentCard>
              <ContentCard title="New Users">
                <UsersSales />
              </ContentCard>
              <ContentCard>
                <PushNotifications />
              </ContentCard>
            </Col>
          </StyledRow>
        )}

        {auth.clientId && auth.clientId === '0' && (
          <StyledRow>
            <Col sm={12} xs={12} lg={7} xl={8}>
              <ContentCard title="Overview" setSearch={() => {}}>
                <h5>Please select a client to view a dashboard</h5>
              </ContentCard>
            </Col>
          </StyledRow>
        )}
      </Container>
    </>
  );
}

export { Dashboard };
