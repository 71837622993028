import { useContext, useEffect, useState } from "react";
import { RemittanceContext } from "./RemittanceContext";
import { CollapsibleDateRangePicker } from "../../../DateRangePicker";
import { Range } from "react-date-range";


interface IRemittanceDateRangeProps {
    start: Date;
    end: Date;
    funded:Date;
    lastPayment: Date;
}

const RemittanceDateRange = ({ start, end, funded, lastPayment }: IRemittanceDateRangeProps) => {
  const remittanceContext = useContext(RemittanceContext);

  const [selectionRanges, setSelectionRanges] = useState<Range[]>([{
    startDate: start,
    endDate: end,
    key: "rangeSel",
  },
 {
    startDate: funded,
    endDate: funded,
    disabled: true,
    key: "fundingDate",
  },
  {
    startDate: lastPayment,
    endDate: lastPayment,
    disabled: true,
    key: "lastPaymentDate",
  }]);

  const handleDateRangeChange = (dateRange: any) => {
    if(dateRange['rangeSel'] && dateRange['rangeSel'].startDate && dateRange['rangeSel'].endDate){
     
      const nextRanges = [...selectionRanges];
      const rangeToUpdate = nextRanges.find(
        (t) => t.key === 'rangeSel'
      );
      if(rangeToUpdate){
        rangeToUpdate.startDate = dateRange['rangeSel'].startDate;
        rangeToUpdate.endDate= dateRange['rangeSel'].endDate;
        setSelectionRanges(nextRanges);
        if(dateRange['rangeSel'].startDate !== dateRange['rangeSel'].endDate){
          remittanceContext.setDateRange(dateRange['rangeSel'].startDate! , dateRange['rangeSel'].endDate!);
      }
    }
    }
  }
 
  return (
    <>
       <CollapsibleDateRangePicker
                  key={`${remittanceContext.dateRange?.start.toString()}-${remittanceContext.dateRange?.end.toString()}`}
                  onChange={(date:Range[]) => handleDateRangeChange(date)}
                  ranges={selectionRanges}
                  moveRangeOnFirstSelection={false}
                />
    </>
  );
};

export default RemittanceDateRange;
