import { Col, Container, Row, Spinner } from 'react-bootstrap';

const LoadingSpinnerComponent = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Spinner animation="border" role="status" variant="primary" style={{ width: '2.5rem', height: '2.5rem' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};

export { LoadingSpinnerComponent };
