import { Badge, Row as BRow, Button, Col, Container, InputGroup, ListGroup, Spinner } from 'react-bootstrap';

import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Steps from '@uiw/react-steps';
import { ReconciliationTransaction, Transaction, getYearMonthDateKey } from '../../../../types';
import { MdCheck, MdHideImage, MdRemoveCircle, MdShower } from 'react-icons/md';
import { RemittanceContext } from './RemittanceContext';

const StyledGroupIcon = styled(InputGroup.Text)`
  border: 1px solid #dedede;
  border-width: 0 0 1px;
  border-radius: 0;
  background-color: transparent;
`;

interface IAccountTransactionsProps {
  transactions?: ReconciliationTransaction[];
}

const RemittanceAccountTransactions= ({ transactions }: IAccountTransactionsProps) => {
  const remittanceContext = useContext(RemittanceContext);
  const renderMoneyAmount = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    if (value < 0) {
      return <span className="txtPrice green">{formatter.format(value.valueOf()).replace('-', '')}</span>;
    } else {
      return <span className="txtPrice">{formatter.format(value.valueOf())}</span>;
    }
  };
  
  return (
    <>
      {transactions && (
        <>

          <ListGroup as="ol" >
            { transactions.sort((a,b)=> a.date.localeCompare(b.date) > 0 ? -1 : 1 ).map((rs: ReconciliationTransaction, index: number) => (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <>
                      {
                        rs.include &&  
                        <>
                          <div className="ms-2 me-auto">
                            <div><b style={ {marginRight:'10px'}} >{rs.date}</b> {remittanceContext.getAccountName(rs.account)} - {rs.description}</div>
                          </div>
                          <div>{renderMoneyAmount(rs.amount)}</div>
                          <Button disabled={remittanceContext.transactions!.filter(f=>f.include).length<=1} variant='link' onClick={()=> remittanceContext.excludeTransaction(rs.id) } >  
                            exclude
                          </Button> 
                        </>
                       
                      }
                      {
                        !rs.include &&  
                         <>
                          <div className="ms-2 me-auto">
                          <div className={'text-decoration-line-through' } ><b style={ {marginRight:'10px'}} >{rs.date}</b> {rs.description}</div>
                          </div>
                          <div className={'text-decoration-line-through' }>{renderMoneyAmount(-rs.amount)}</div>
                            <Button variant='link' onClick={()=> remittanceContext.includeTransaction(rs.id)} >  
                              include
                            </Button>
                          </>
                      }
                </>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )}
    </>
  );
}

export { RemittanceAccountTransactions };
