import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';
import styled from 'styled-components';

const CheckoutForm = () => {
  const elements = useElements();
  const stripe = useStripe();
  const [signinIn, setSigninIn] = useState(false);

  const StyledButton = styled(Button)`
    float: right;
    margin-top: 35px;
    margin-bottom: 25px;
  `;

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: process.env.REACT_APP_SITEURL + 'manage-payment-method',
        },
      })
      .then(() => {
        setSigninIn(false);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <StyledButton size="sm" variant="primary" type="submit" onClick={handleSubmit} disabled={signinIn}>
        {signinIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        Add Card
      </StyledButton>
    </form>
  );
};

export { CheckoutForm };
