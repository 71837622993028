import React, { useCallback, useImperativeHandle } from 'react';
import { useState, createRef } from 'react';
import { DropzoneRef, useDropzone } from 'react-dropzone';
import storageService from '../../services/storageService/storageService';
import ImgUpload from '../../assets/img_upload.png';
import { LoadingSpinnerComponent } from '../Loading';
const storageUrl = process.env.REACT_APP_STORAGEURL;

interface ImageThumbProps {
  path: string;
  defaultURL?: string;
  onUpdate: (imageUrl: string | null) => void;
  className?: string;
}

export type ImageThumb = {
  src?: string;
};

export type ImageUploadHandle = {
  open: () => void;
};

const ThumbImage = React.forwardRef<ImageUploadHandle, ImageThumbProps>((props, ref) => {
  const random = crypto.randomUUID();
  const [File, setFile] = useState<ImageThumb>({
    src: props.defaultURL !== '' ? `${storageUrl}/${props.path}/${props.defaultURL}?random=${random}` : ImgUpload,
  });
  const dropzoneRef = createRef<DropzoneRef>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      setIsLoading(true);
      setFile(prevState => ({ ...prevState, src: ImgUpload }));
      const randomGUID = crypto.randomUUID();
      const file = acceptedFiles[0];
      const extension = file.name.split('.').pop();

      storageService.uploadBlob(`${props.path}/${randomGUID}.${extension}`, file).then(result => {
        if (result) {
          setFile(prevState => ({ ...prevState, src: `${storageUrl}/${props.path}/${randomGUID}.${extension}` }));
          props.onUpdate(`${randomGUID}.${extension}`);
          setIsLoading(false);
        }
      });
    },
    [props]
  );

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: onDrop,
  });

  useImperativeHandle(ref, () => ({
    open() {
      open();
    },
  }));

  const onImageError = (event: any) => {
    event.target.src = ImgUpload;
    props.onUpdate(null);
  };
  const thumb = (image: ImageThumb) => (
    <div>
      {isLoading && <LoadingSpinnerComponent></LoadingSpinnerComponent>}
      {!isLoading && (
        <img
          className={props.className}
          src={image.src ?? ImgUpload}
          alt="logo"
          onClick={openDialog}
          onError={onImageError}
        />
      )}
    </div>
  );

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div style={{ cursor: 'pointer' }}>{thumb(File)}</div>
      </div>
    </>
  );
});

export { ThumbImage };
