import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row as BRow, Form, InputGroup, Button } from 'react-bootstrap';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell, Data } from '@table-library/react-table-library/table';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import styled from 'styled-components';
import type { TableItem, WebUser } from '../../types';
import { LoadingSpinnerComponent } from '../../components/Loading';

import { FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';
import { ProfileImage } from '../../components/ProfileImage';

interface WebUserProps {
  search: string;
  userData: WebUser[] | undefined;
}

const StyledHeaderCell = styled(HeaderCell)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledGroupText = styled(InputGroup.Text)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
`;
const StyledGroupButton = styled(Button)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: #4c5980;
  padding: 5px;
  &.btn:disabled {
    background-color: transparent;
    color: #4c5980;
  }
`;

const StyledEmail = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

function WebUsersTable({ search, userData }: WebUserProps) {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<Array<TableItem<WebUser>>>();
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [tableData, setTableData] = useState<Data<any>>({
    nodes: [],
  });

  const theme = useTheme({
    Table: `
          --data-table-library_grid-template-columns: minmax(50px, 0.2fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 0.5fr);
        `,
    BaseCell: `
          &:nth-of-type(1) {
            left: 0px;
          }
  
          &:nth-of-type(2) {
            left: 0px;
          }
        `,
    Row: `
        cursor: pointer;
        color: rgb(117, 117, 117);
        &:hover {
          color: black;
        }
        &:hover .td {
          background-color:#f8f9fa;
        }
        `,
  });
  useEffect(() => {
    if (userData) {
      const tableItems = userData?.map((r, i) => ({ ...r, number: i + 1 } as TableItem<WebUser>));
      setUserList(tableItems);
      setTableLoaded(true);
      const tableData = { nodes: tableItems } as Data<any>;
      setTableData(tableData);
    }
  }, [userData]);

  useEffect(() => {
    if (userList) {
      const filteredData = { nodes: userList.filter(f => f.id.toLowerCase().includes(search.toLowerCase())) } as Data<any>;
      setTableData(filteredData);
    }
  }, [search, userList]);

  const pagination = usePagination(tableData, {
    state: {
      page: 0,
      size: perPage,
    },
  });

  const sort = useSort(
    tableData,
    {
      onChange: () => {},
    },
    {
      sortFns: {
        USERNAME: array => array.sort((a, b) => a.userName - b.UserName),
        DATE: array => array.sort((a, b) => a.createDate.localeCompare(b.type)),
      },
    }
  );

  return (
    <>
      {!tableLoaded && <LoadingSpinnerComponent />}

      {tableLoaded && tableData && (
        <>
          <Table data={tableData} pagination={pagination} sort={sort} theme={theme} layout={{ custom: true }}>
            {(tableList: any[]) => (
              <>
                <Header>
                  <HeaderRow>
                    <StyledHeaderCell>#</StyledHeaderCell>
                    <StyledHeaderCell>User</StyledHeaderCell>
                    <StyledHeaderCell>Phone</StyledHeaderCell>
                    <StyledHeaderCell>ClientID</StyledHeaderCell>
                    <StyledHeaderCell>Role</StyledHeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map(item => (
                    <Row item={item} key={item.id} onClick={() => navigate(`/myprofile?id=${item.id}`)}>
                      <Cell>{item.number}</Cell>
                      <Cell>
                        {/* {item.email} */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ProfileImage
                            url={item.profileImage}
                            sizePx={30}
                            {...(item?.profileImage ? { urlPrefix: true } : {})}
                          />
                          <div style={{ marginLeft: '8px' }}>
                            {item.firstName} {item.lastName}
                            <br />
                            <StyledEmail> {item.email}</StyledEmail>
                          </div>
                        </div>
                      </Cell>
                      <Cell>{item.mobilePhone}</Cell>
                      <Cell>{item.clientId}</Cell>
                      <Cell>{item.roles[0]}</Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <BRow style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            {tableData.nodes.length} items in total
          </Col>
          <Col md={9} xs={7}>
            <InputGroup className="justify-content-end">
              <div style={{ width: '270px' }}>
                <Form.Select
                  defaultValue={perPage}
                  style={{ outline: 'none', boxShadow: 'none', border: 'none' }}
                  onChange={e => setPerPage(Number(e.target.value))}
                >
                  <option value="10">Showing 10 items per page</option>
                  <option value="20">Showing 20 items per page</option>
                  <option value="50">Showing 50 items per page</option>
                  <option value="100">Showing 100 items per page</option>
                </Form.Select>
              </div>
              <StyledGroupButton disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                <FaStepBackward></FaStepBackward>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page === 0}
                onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
              >
                <FaPlay style={{ transform: 'rotate(180deg)' }}></FaPlay>
              </StyledGroupButton>
              <StyledGroupText>
                {pagination.state.getPageBoundaries(tableData.nodes).start}
                {'-'}
                {pagination.state.getPageBoundaries(tableData.nodes).end}
              </StyledGroupText>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
              >
                <FaPlay></FaPlay>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(tableData.nodes) - 1)}
              >
                <FaStepForward></FaStepForward>
              </StyledGroupButton>
            </InputGroup>
          </Col>
        </BRow>
      )}
    </>
  );
}

export { WebUsersTable };
