import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client, ClientFormType } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import adminService from '../../../services/adminService/adminService';
import { Button, Container, Spinner } from 'react-bootstrap';
import { PageHeader } from '../../../components/Layout/pageHeader';
import { ContentCard } from '../../../components/Card';
import CredentialsTest from './CredentialsTest';
import ClientInfo from './ClientInfo';



export type FormHandleType = {
  validate: () => Promise<boolean>;
  getValues: () => ClientFormType | null;
};

export default function CreateClient() {
  const navigate = useNavigate();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const credentialsRef = useRef<FormHandleType>(null);
  const clientInfoRef = useRef<FormHandleType>(null);

  const [testStatus, setTestStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreate = async () => {
    const credentialsData = credentialsRef?.current?.getValues();
    if (credentialsData && (credentialsData?.crmKeyId === '' || credentialsData?.crmClientSecret === '')) return;

    const clientData = clientInfoRef?.current?.getValues();
    if (
      clientData &&
      (clientData?.clientName === '' || clientData?.subdomain === '' || clientData?.billingEmail === '')
    )
      return;


    setLoading(true);
    const submit = {
      ...clientData,
      ...credentialsData,
      id: credentialsData!.clientId,
    };

    try {
      const response =  await adminService.createClient(submit as Client);

      if (response && response?.status === 200) {
        setLoading(false);
        navigate('/admin');
      } else {
        setAlertText('Unable to create client');
        setVariant('dark');
        setAlert(true);
        setIntervalDelay(1000);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response) {
        setAlertText('Unable to create client');
        setVariant('dark');
        setAlert(true);
        setIntervalDelay(1000);
      }

      setLoading(false);
    }
  };

  const onSubmit = () => {
    const isCredentialsValid = credentialsRef?.current?.validate();
    const isClientInfoValid = clientInfoRef?.current?.validate();

    if (!isCredentialsValid || !isClientInfoValid) return;

    if (!testStatus) {
      setAlertText('Please test Centrex CRM Credentials');
      setVariant('warning');
      setAlert(true);
      setIntervalDelay(1000);
      return;
    }

    handleCreate();
  };

  return (
    <>
      <Container fluid>
        <PageHeader>
          <a className="admin-setting-header" href="/admin">
            Client Management
          </a>
          &nbsp;/&nbsp;
          <span>Create Client</span>
        </PageHeader>
        <ContentCard>
          <div className="client-details">
            <h1 className="title">Settings</h1>
            <div style={{ marginBottom: '24px' }}>
              <CredentialsTest ref={credentialsRef} setTestStatus={setTestStatus} />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <h1 className="title">Client Info</h1>
              <ClientInfo ref={clientInfoRef}  />
            </div>
            <Button className="btn-backround" style={{ float: 'right', margin: '54px 0 40px 0' }} onClick={onSubmit}>
              <span>Create &nbsp;</span>
              {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
          </div>
        </ContentCard>
      </Container>
    </>
  );
}
