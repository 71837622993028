import { useEffect, useState } from 'react';
import { Col, Container, Row as BRow, Alert, Button, Modal, Form, Spinner } from 'react-bootstrap';
import { FormInput } from '../../../components/Forms/FormInput';
import styled from 'styled-components';
import {
  HeaderCell,
  Row,
  Data,
  Table,
  Header,
  HeaderRow,
  Body,
  Cell,
  TableNode,
} from '@table-library/react-table-library/table';
import NotesService from '../../../services/notes/Notes';
import { useTheme } from '@table-library/react-table-library/theme';
import { type Notes, type ClientInfoType, type TableItem, NotesType, isValidEmail } from '../../../types';
import { LoadingSpinnerComponent } from '../../../components/Loading';

import iconDel from '../../../assets/image-icons/delete.png';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

const StyledHeaderCell = styled(HeaderCell)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledRow = styled(Row)`
  cursor: pointer;
  &:focus {
    background-color: white;
    color: black;
  }
  &:hover {
    background-color: #4c5980;
    color: black;
  }
`;

export default function NotesPage({ client }: ClientInfoType) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [modal, setModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Data<TableNode>>({
    nodes: [],
  });
  const [noteTypes, setNoteTypes] = useState<NotesType[]>([]);
  const [selectNoteType, setSelectNoteType] = useState<string>('');
  const [selectNoteItem, setSelectNoteItem] = useState<Notes | null>(null);
  const [validate, setValidate] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [validates, setValidates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme({
    Row: `
    cursor: pointer;
    color: rgb(117, 117, 117);
    border: 1px solid #fff !important;
    &:hover {
      color: black;
    }
    &:hover .td {
      background-color:#f8f9fa;
    }
    `,
  });

  useEffect(() => {
    if (client) {
      (async () => {
        try {
          const notes = await NotesService.getNotesData(client?.clientId);
          const noteType = await NotesService.getNotesType(client?.clientId as string);

          if (notes) {
            const tableItems = notes?.map((r: Notes, i: number) => ({ ...r, number: i + 1 } as TableItem<Notes>));
            const tableData = { nodes: tableItems } as Data<TableNode>;

            setTableData(tableData);
            setErrorAlert(false);
            setNoteTypes(noteType as NotesType[]);
          }
        } catch (error: any) {
          setErrorAlert(true);
        }

        setTableLoaded(true);
      })();
    }
  }, [client, loading]);

  const handleChangeNoteType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectNoteType(e.target.value);
  };

  const handleClickTableRow = (item: TableNode) => {
    switch (item.eventType) {
      case 'balance-requested':
        setModalTitle('Balance Request');
        break;
      case 'capital-requested':
        setModalTitle('Capital Request');
        break;
      case 'plaid-account-linked':
        setModalTitle('Plaid Data');
        break;
    }

    setSelectNoteType(item.noteId ?? noteTypes?.[0]?.id);
    setSelectNoteItem(item as Notes);
    setModal(true);
    setValidates(
      item?.emailsCC?.map(() => {
        return '';
      })
    );
  };

  const handleAddEmail = () => {
    if(email === '' || !isValidEmail(email)){
      setValidate('Please type a valid email address.');
      return;
    }
    const findEmail = selectNoteItem?.emailsCC?.find((f: string) => email === f);

    if (!findEmail) {
      setSelectNoteItem({ ...(selectNoteItem as Notes), emailsCC: [...(selectNoteItem?.emailsCC as string[]), email] });
      setValidates([...validates, '']);
      setEmail('');
    }
    else {
      setValidate('Email is already added.');
    }
    
    };

  const handleSubmit = async () => {
    const find = selectNoteItem?.emailsCC?.find((f: string) => f === '');
    if (find === '') return;

    const submit = {
      clientId: client?.clientId as string,
      noteTypeId: selectNoteType,
      eventType: selectNoteItem?.eventType as string,
      emailsCC: selectNoteItem?.emailsCC as string[],
      userIds: [] as string[],
      isEnabled: true,
    };

    setLoading(true);

    try {
      await NotesService.setNotesData(submit);
    } catch (error) {
      setAlertText('Something went wrong.');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
      setLoading(false);

      return;
    }

    setLoading(false);
    setModal(false);
  };

  return (
    <>
      <Modal show={modal} dialogClassName="modal-width" onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BRow>
            <Col md={5}>
              <Form.Label>Note Type</Form.Label>
              <Form.Select
                className="customSelect"
                style={{ width: '100%', height: '33px', marginBottom: '24px' }}
                value={selectNoteType}
                onChange={handleChangeNoteType}
              >
                {noteTypes?.map((item: NotesType, key: number) => (
                  <option key={key} value={item.id}>
                    {item.message}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={7} style={{ paddingLeft: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <Form.Group controlId="email" style={{ width: '80%' }}>
                  <Form.Label className="label">CC Emails</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="email"
                    placeholder="Type in Email"
                    className={`${validate !== '' ? 'is-invalid' : ''}`}
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!isValidEmail(event.target.value)) {
                        setValidate('Please type a valid email address.');
                      } else {
                        setValidate('');
                      }

                      setEmail(event.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{validate}</Form.Control.Feedback>
                </Form.Group>
                <Button variant="outline-primary" onClick={handleAddEmail}>
                  Add
                </Button>
              </div>
              {selectNoteItem?.emailsCC?.map((rs: string, key: number) => (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                  }}
                >
                  <div style={{ width: '80%' }}>
                    <Form.Control
                      as={FormInput}
                      type="email"
                      readOnly={true}
                      placeholder="Type in Email"
                      className={`${validates[key] !== '' ? 'is-invalid' : ''}`}
                      value={rs}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (!isValidEmail(event.target.value)) {
                          setValidates(prev => {
                            const tmp = [...prev];
                            tmp[key] = 'Please type a valid email address.';
                            return tmp;
                          });
                        } else {
                          setValidates(prev => {
                            const tmp = [...prev];
                            tmp[key] = '';
                            return tmp;
                          });
                        }

                        setSelectNoteItem(prev => {
                          const tmp = { ...prev } as Notes;
                          if (tmp?.emailsCC) tmp.emailsCC[key] = event.target.value;
                          return tmp;
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{validates[key]}</Form.Control.Feedback>
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const filterItem = selectNoteItem?.emailsCC?.filter((_: string, idx: number) => idx !== key);
                      const filterValidate = validates.filter((_: string, idx: number) => idx !== key);
                      setValidates(filterValidate);
                      setSelectNoteItem({ ...selectNoteItem, emailsCC: filterItem });
                    }}
                  >
                    <img className="icon-action" src={iconDel} alt="" />
                  </div>
                </div>
              ))}
            </Col>
          </BRow>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <span>Save Changes &nbsp;</span>
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="client-settings">
        <h1 className="title" style={{ marginBottom: '55px' }}>
          Notes
        </h1>
        <div>
          {!tableLoaded && <LoadingSpinnerComponent />}

          {tableLoaded && errorAlert ? (
            <Container>
              <BRow className="justify-content-md-center">
                <Col md="6">
                  <Alert variant="danger" show={errorAlert}>
                    <Alert.Heading>Unable to connect to CRM</Alert.Heading>
                    <p>
                      Please update your CRM credentials in the &nbsp;
                      <a href="/settings?view=crm" style={{ color: '#842029' }}>
                        settings page.
                      </a>
                    </p>
                  </Alert>
                </Col>
              </BRow>
            </Container>
          ) : (
            <>
              {tableLoaded && tableData.nodes.length === 0 && (
                <Container>
                  <BRow className="justify-content-md-center">
                    <Col md="auto">
                      <span>Data not available</span>
                    </Col>
                  </BRow>
                </Container>
              )}
            </>
          )}

          {tableLoaded && tableData.nodes.length !== 0 && !errorAlert && (
            <>
              <Table data={tableData} theme={theme}>
                {(tableList: TableNode[]) => (
                  <>
                    <Header>
                      <HeaderRow>
                        <StyledHeaderCell>Event Type</StyledHeaderCell>
                        <StyledHeaderCell>Note ID</StyledHeaderCell>
                        <StyledHeaderCell>Note</StyledHeaderCell>
                        <StyledHeaderCell>Emails CC</StyledHeaderCell>
                        <StyledHeaderCell>ModifiedOn</StyledHeaderCell>
                      </HeaderRow>
                    </Header>

                    <Body>
                      {tableList.map((item: TableNode, key: number) => {
                        let type = '';

                        switch (item.eventType) {
                          case 'balance-requested':
                            type = 'Balance Request';
                            break;
                          case 'capital-requested':
                            type = 'Capital Request';
                            break;
                          case 'plaid-account-linked':
                            type = 'Plaid Data';
                            break;
                        }

                        return (
                          <StyledRow item={item} key={key}>
                            <Cell>{type}</Cell>
                            <Cell>{item.noteId && item.noteId !== '' ? item.noteId : '-'}</Cell>
                            <Cell>{item.note && item.note !== '' ? item?.note : '-'}</Cell>
                            <Cell>{item.emailsCC && item.emailsCC?.length !== 0 ? item.emailsCC.toString() : '-'}</Cell>
                            <Cell>
                              <Button size="sm" style={{ float: 'right' }} onClick={() => handleClickTableRow(item)}>
                                Edit
                              </Button>
                            </Cell>
                          </StyledRow>
                        );
                      })}
                    </Body>
                  </>
                )}
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
}
