import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ContentCard } from '../../../components/Card';

import ChangePassword from './ChangePassword';
import ModalComponent from '../../../components/Modal';

import { AuthContext } from '../../../services/authService/authProvider';
import type { WebUser } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import { LoadingSpinnerComponent } from '../../../components/Loading';

import { FaPencilAlt, FaRegUser, FaRegEnvelope, FaRegTrashAlt } from 'react-icons/fa';
import IconChangePwd from '../../../assets/image-icons/icon_change_pwd.svg';
import IconLogOut from '../../../assets/image-icons/logout.svg';

export default function MyProfile() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [modal, setModal] = useState<boolean>(false);
  const [user, setUser] = useState<WebUser | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (search !== '') {
        const response = await adminService.getUserDetails(search.split('id=')[1]);
        setUser(response);
        setLoading(false);
      } else {
        const response = await adminService.getUserDetails(auth?.getUser()?.id as string);
        setUser(response);
        setLoading(false);
      }
    })();
  }, [auth, search]);

  const handleLogout = () => {
    auth.signOut(() => {
      navigate('/login', { replace: true });
    });
  };

  return (
    <>
      <ModalComponent
        modal={modal}
        setModal={setModal}
        backdrop="static"
        centered={true}
        headerTitle="Change Password"
        content={<ChangePassword setModal={setModal} />}
        isFooter={false}
      />
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
          <h3 style={{ color: 'white' }}>My Profile</h3>
        </div>
        <ContentCard>
          <Container style={{ height: '464px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
              <a href={`/myprofile/${user?.id}`} className="profile-header">
                <p className="txt">Edit Profile</p>
                <FaPencilAlt style={{ fontSize: '12px', color: 'black' }} />
              </a>
            </div>
            {loading ? (
              <>
                <div style={{ height: '150px' }}></div>
                <LoadingSpinnerComponent />
              </>
            ) : (
              <>
                <div className="profile-user-info">
                  <div className="profile-avatar">
                    {user?.profileImage && user?.profileImage !== '' ? (
                      <img
                        src={`${process.env.REACT_APP_STORAGEURL}/web-profile-images/${user?.profileImage}`}
                        alt=""
                        width={97}
                        height={97}
                        style={{ borderRadius: '100%' }}
                      />
                    ) : (
                      <FaRegUser style={{ fontSize: '55px' }} />
                    )}
                  </div>
                  <div>
                    <p className="full-name">
                      {user?.firstName} {user?.lastName}
                    </p>
                    <div className="row-item" style={{ marginBottom: '10px' }}>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f7f7f7',
                        }}
                      >
                        <FaRegEnvelope style={{ fontSize: '10px' }} />
                      </div>
                      <p className="other-info" style={{ marginLeft: '10px' }}>
                        {user?.id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="profile-other-info">
                  <div className="row-item">
                    <p className="title">Role:</p>
                    <p className="content-txt">{user?.roles?.[0]}</p>
                  </div>
                </div>
                <div className="row-item-between" style={{ marginBottom: '44px' }}>
                  <div className="row-item" style={{ cursor: 'pointer' }} onClick={() => setModal(true)}>
                    <img src={IconChangePwd} alt="" />
                    <p className="profile-change-password-txt">Change Password</p>
                  </div>

                  {user?.roles?.[0] && auth?.isAdmin && (
                    <div className="row-item" style={{ cursor: 'pointer' }}>
                      <div
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f7f7f7',
                        }}
                      >
                        <FaRegTrashAlt style={{ fontSize: '12px', color: 'd75053' }} />
                      </div>
                      <p className="profile-delete-logout-account-txt">Delete Account</p>
                    </div>
                  )}

                  <div className="row-item" style={{ cursor: 'pointer' }} onClick={handleLogout}>
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f7f7f7',
                      }}
                    >
                      <img src={IconLogOut} alt="" />
                    </div>
                    <p className="profile-delete-logout-account-txt">Logout</p>
                  </div>
                </div>
              </>
            )}
          </Container>
        </ContentCard>
      </Container>
    </>
  );
}
