import { useParams } from 'react-router-dom';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';

import debtpayproService from '../../../services/debtpayproService/debtpayproService';

import { FormInput } from '../../../components/Forms/FormInput';
import { type ClientInfoType, type Client, type ClientFormType, UrlRegex } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import ModalComponent from '../../../components/Modal';
import { useRef, useState } from 'react';
import CrmBasicAuth, { CrmBasicAuthRef } from './CrmBasicAuth';
import CredentialsTest from '../CredentialsTest';
import styled from 'styled-components';
import settingsService from '../../../services/settingsService/settingsService';
import { FormHandleType } from '../create/CreateClient';


const SectionClickWrapper = styled.div`
  cursor: pointer;
  padding: 0.75em;
  &:hover {
    background-color: #f8f9fa;
  }
  * {
  cursor: pointer;
  }
`;


export default function CrmKeys({ client, loading, setLoading }: ClientInfoType) {
  const params = useParams();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const [testStatus, setTestStatus] = useState<boolean>(false);
  const [basicModal, setBasicModal] = useState<boolean>(false);
  const [crmKeyModal, setCrmKeyModal] = useState<boolean>(false);
  const basicRef = useRef<CrmBasicAuthRef>(null);
  const credentialsRef = useRef<FormHandleType>(null);
  const schema = yup.object().shape({
    crmKeyId: yup
      .string()
      .required('This is a required field.')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be exactly 4 digits')
      .max(4, 'Must be exactly 4 digits'),
    crmClientSecret: yup.string().required('This is a required field.'),
    crmUrl: yup.string().matches(UrlRegex, 'Please type a valid url').optional(),
  });

  const handleSaveCrmKeys = async () => {
      const credentialsData = credentialsRef?.current?.getValues();
      if (credentialsData && (credentialsData?.crmKeyId === '' || credentialsData?.crmClientSecret === '')) return;

      try{
        const result = await settingsService.setCrmKey(credentialsData?.crmKeyId!, credentialsData?.crmClientSecret!, client?.id!);
        setCrmKeyModal(false);
        setTestStatus(false);
      }
      catch(error){

      }
      
  };

  return (
    <div className="client-settings">
      <ModalComponent
        modal={basicModal}
        size="lg"
        setModal={setBasicModal}
        backdrop="static"
        centered={true}
        headerTitle={'Update webhook secret'}
        content={
          <CrmBasicAuth client={client!} ref={basicRef}  />
        }
        okVariant={'primary'}
        okTxt={
          <>
            { <span>Save</span>}
            &nbsp;
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </>
        }
        onClose={() => setBasicModal(false)}
        onClick={() => basicRef.current?.handlerFunction() }
        isFooter={true}
      />

<ModalComponent
        modal={crmKeyModal}
        size="lg"
        setModal={setCrmKeyModal}
        backdrop="static"
        centered={true}
        headerTitle={'Update webhook secret'}
        content={
          <CredentialsTest client={client} key={client?.crmKeyId} setTestStatus={setTestStatus} ref={credentialsRef}/>
        }
        okVariant={'primary'}
        okTxt={
          <>
            { <span>Save</span>}
            &nbsp;
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </>
        }
        enableClick={testStatus}
        onClose={() => setCrmKeyModal(false)}
        onClick={() => handleSaveCrmKeys() }
        isFooter={true}
      />
            <Form id="crm-test" noValidate >
              <Form.Group controlId="crmUrl" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">CRM Url</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                />
              </Form.Group>
              <h4>API Key</h4>
              <SectionClickWrapper onClick={()=>setCrmKeyModal(true)}>
                <Form.Group controlId="crmKeyId" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">CRM Key Id</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={client?.crmKeyId}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="crmClientSecret" style={{ marginBottom: '40px' }}>
                  <Form.Label className="label">Client secret</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="password"
                    onClick={()=>setCrmKeyModal(true)}
                    value={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
                    readOnly
                  />
                </Form.Group>
              </SectionClickWrapper>

              <h4>Webhook Basic Auth</h4>
              <SectionClickWrapper onClick={()=>setBasicModal(true)}>
                <Form.Group controlId="crmUrl" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">UserName</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="text"
                    value={client?.id}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="crmUrl" style={{ marginBottom: '24px' }}>
                  <Form.Label className="label">Password</Form.Label>
                  <Form.Control
                    as={FormInput}
                    type="password"
                    value={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
                    readOnly
                  />
                </Form.Group>
              </SectionClickWrapper>
            </Form>
    </div>
  );
}
