/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from 'react-plaid-link';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../services/authService/authProvider';
import transactionsService from '../../services/transactionsService/transactionsService';
import { ProcessLinkRequest } from '../../types';

type LinkProps = {
  onComplete?: () => void;
};

const PlaidLinkWithOAuth = ({ onComplete }: LinkProps) => {
  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');
  const auth = useContext(AuthContext);
  const params = useParams();

  const createLinkToken = async () => {
    const response = await transactionsService.createPlaidLink(params.id!, auth.clientId!);
    if (response) {
      setToken(response.link_token);
      localStorage.setItem('link_token', response.link_token);
    }
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (_, metadata) => {
      const processLinkRequest: ProcessLinkRequest = { ...metadata, clientId: auth.clientId, userId: params.id };
      transactionsService.processPlaidAccessToken(processLinkRequest).then(() => {
        if (onComplete) {
          localStorage.removeItem('link_token');
          onComplete();
        }
      });
    },
    [auth]
  );
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {}, []);
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {}, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };
  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  const onConnectAccountClick = () => {
    createLinkToken();
  };

  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem('link_token'));
    }
  }, [isOAuthRedirect]);

  useEffect(() => {
    if (ready) open();
  }, [token, ready]);

  return isOAuthRedirect ? (
    <></>
  ) : (
    <Row className="justify-content-end">
      <Col md={3}>
        <Button variant="link" onClick={() => onConnectAccountClick()}>
          Add bank account
        </Button>
      </Col>
    </Row>
  );
};

export default PlaidLinkWithOAuth;
