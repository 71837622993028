import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { ContactAdvances } from "../../../Advances/ContactAdvances";
import { UserBankConnections } from "../../../BankAccounts/UserBankConnections";
import MenuButtons, { btnOption } from "../../../MenuButtons";
import { RemittanceAuditReport } from "./AuditReport";
import { RemittanceAccountTransactions } from "./RemittanceAccountTransactions";
import { RemittanceContext } from "./RemittanceContext";
import { RemittancePayments } from "./RemittancePayments";
import { LoadingSpinnerComponent } from "../../../Loading";
import RemittanceDateRange from "./RemittanceDateRange";

const menus: btnOption[] = [
  {
    title: "Deposit Transactions",
  },
  {
    title: "Advance Transactions",
  },
];

const Remittance = () => {
  const remittanceContext = useContext(RemittanceContext);

  const [selectView, setSelectView] = useState<string>("Deposit Transactions");

  return (
    <>
      <Row style={{ marginBottom: "24px" }}>
        {remittanceContext.isLoading && <LoadingSpinnerComponent />}
        {!remittanceContext.isLoading && (
          <>
            <UserBankConnections
              view="Advance%20Reconciliation"
              filter={["savings", "checking"]}
              bankAccountContext={remittanceContext}
            />
            <Row>
              <ContactAdvances
                advances={remittanceContext.advances}
                isLoading={false}
              />
            </Row>
          </>
        )}
      </Row>
      {remittanceContext.selectedAccounts &&
        remittanceContext.selectedAdvance?.dealId && (
          <>
            <Row>
              <h4>Advance Reconciliation</h4>
            </Row>
            {remittanceContext.isCalculatingReport && (
              <LoadingSpinnerComponent />
            )}
            {!remittanceContext.isCalculatingReport &&
              remittanceContext.remittanceReport && (
                <>
                  <Row style={{ marginBottom: "24px" }}>
                    <Col>
                      <RemittanceAuditReport />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div style={{ width: "100%", marginBottom: "40px" }}>
                        <MenuButtons
                          titles={menus}
                          selectView={selectView}
                          setSelectView={setSelectView}
                        />
                      </div>
                    </Col>
                    <Col>
                      {remittanceContext.fundingDate &&
                        remittanceContext.lastPaymentDate &&
                        remittanceContext.dateRange?.start && (
                          <RemittanceDateRange
                            start={remittanceContext.dateRange?.start}
                            end={remittanceContext.dateRange?.end}
                            funded={remittanceContext.fundingDate}
                            lastPayment={remittanceContext.lastPaymentDate}
                          />
                        )}
                    </Col>
                  </Row>

                  <Row>
                    <div
                      style={{
                        display:
                          selectView === "Advance Transactions" ? "" : "none",
                      }}
                    >
                      {remittanceContext?.advancePayments &&
                        remittanceContext?.advancePayments.length === 0 && (
                          <Alert variant="warning">
                            Advance does not have transactions for the selected date range
                          </Alert>
                        )}
                      {remittanceContext?.advancePayments && (
                        <RemittancePayments
                          advanceTransactions={
                            remittanceContext.advancePayments
                          }
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display:
                          selectView === "Deposit Transactions" ? "" : "none",
                      }}
                    >
                       {remittanceContext?.transactions &&
                        remittanceContext?.transactions.length === 0 && (
                          <Alert variant="warning">
                            Bank Account does not have deposits for the selected date range
                          </Alert>
                        )}
                      {remittanceContext?.transactions && (
                       <RemittanceAccountTransactions
                       transactions={remittanceContext.transactions}
                     />
                      )}
                      
                    </div>
                  </Row>
                </>
              )}
          </>
        )}
    </>
  );
};

export default Remittance;
