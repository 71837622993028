import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ContentCard } from '../../../components/Card';
import { PageHeader } from '../../../components/Layout/pageHeader';
import MenuButtons from '../../../components/MenuButtons';
import { type Client, clientMenus } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import Advances from './Advances';
import ClientInfo from './ClientInfo';
import ClientAssets from './Assets';
import CrmKeys from './CrmKeys';
import PushNotification from './PushNotification';
import Plaid from './Plaid';
import Email from './email/Email';
import Notes from './Notes';
import DocTypes from './DocTypes';
import Assignments from './Assignments';

export default function EditClient() {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [selectView, setSelectView] = useState<string>('Client Info');

  useEffect(() => {
    if (params.id) {
      adminService.getClientDetails(params.id, params.id).then((rs: Client | undefined) => {
        setClient(rs);
      });
    }
  }, [params.id, loading]);

  return (
    <Container fluid>
      <PageHeader>
        <a className="admin-setting-header" href="/admin">
          Client Management
        </a>
        &nbsp;/&nbsp;
        <span>Client Details</span>
      </PageHeader>
      <ContentCard>
        <Container>
          <div style={{ marginBottom: '37px' }}>
            <MenuButtons titles={clientMenus} selectView={selectView} setSelectView={setSelectView} />
          </div>
          <div>
            <>
              {selectView === 'Client Info' && <ClientInfo client={client}  />}

              {selectView === 'CRM' && <CrmKeys client={client} loading={loading} setLoading={setLoading} />}

              {selectView === 'Assets' && <ClientAssets client={client} />}

              {selectView === 'Email' && <Email />}

              {selectView === 'Push Notification' && (
                <PushNotification client={client} loading={loading} setLoading={setLoading} />
              )}

              {selectView === 'Plaid Settings' && <Plaid client={client} />}

              {selectView === 'Advances' && <Advances client={client} />}

              {selectView === 'Notes' && <Notes client={client} />}

              {selectView === 'Documents' && <DocTypes client={client} />}

              {selectView === 'Assignments' && <Assignments client={client} />}
            </>
          </div>
        </Container>
      </ContentCard>
    </Container>
  );
}
