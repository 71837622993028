import { Col, Container, Row } from 'react-bootstrap';
import { LeftNav } from '../../components/LeftNav';
import { Navbar } from '../../components/Navbar';
import background from '../../assets/header_background.png';
import { createGlobalStyle } from 'styled-components';
import IdleTimerPrompt from '../IdleTimer';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../services/authService/authProvider';

const GlobalStyle = createGlobalStyle`
  body {
    background-color:#f2f2f2;
    font-family: sans-serif;
    height:100vh;
    width:100%;
  }

  body::-webkit-scrollbar {
    width: scrollbarwidthpx;
  }

  @media screen and (max-width: 575px) {
    #root {
      background-color:white;
    } 
    .row > * {
      padding-left:0;
      padding-right:0;
    }
  }

  @media screen and (min-width: 575px) {
    #root {
      background: url(${background});
      background-repeat: no-repeat;
      height:100vh;
      width:100%;
    } 
  }
    @media screen and (min-width: 1900px) {
    #root {
      background: url(${background}) ;
      background-repeat: no-repeat;
      background-size: 100% 460px;
      height:100vh;
      width:100%;
    }
  }
`;

function Layout() {
  const auth = useContext(AuthContext);
  return (
    <>
      <GlobalStyle />
      <IdleTimerPrompt />
      <Container fluid>
        <Row>
          <Navbar />
        </Row>
        <Row>
          {auth.is2FAEnabled && 
            <Col xs={12} md={12} lg={2} xl={2} xxl={2}>
              <LeftNav />
            </Col>
          }
          <Col xs={12} md={12} lg={auth.is2FAEnabled ? 10 : 12 } xl={auth.is2FAEnabled ? 10 : 12 } xxl={auth.is2FAEnabled ? 9 : 12 }>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Layout };
