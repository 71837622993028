import axios from "axios";
import { history } from "../../lib/history";
import { setupCache } from "axios-cache-interceptor";
import { memoizedRefreshToken } from "./refreshToken";

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
axios.defaults.headers.common["accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

const cacheInstance = setupCache(axios, { ttl: 1000 * 30 });

axios.interceptors.request.use(
  async (config) => {
    const localUser = window.localStorage.getItem("AppUser") || null;

    if (localUser) {
      const session = JSON.parse(localUser);
      if (session?.token) {
        if (config && config.headers) {
          config.headers["Authorization"] = `Bearer ${session?.token}`;
        }
      }
    } else {
      history.replace("/auth/login");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosPrivate = cacheInstance;
