import { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AccountContext } from '../../../services/accountService/accountsProvider';
import { BankAccountDetails } from '../../BankAccounts/AccountDetails';
import { UserBankConnections } from '../../BankAccounts/UserBankConnections';
import { AccountMonthBalance } from '../../Transactions/AccountMonthBalance';
import { AccountSummaryReport } from '../../Transactions/AccountSummaryReport';
import { AccountTransactions } from '../../Transactions/AccountTransactions';

interface IFinancialProps {
  id?: string;
  isLoading?: boolean;
}

const Financial = ({ id, isLoading }: IFinancialProps) => {
  const accountContext = useContext(AccountContext);
  
  return (
    <>
      <Row style={{ marginBottom: '24px' }}>
        <UserBankConnections bankAccountContext={accountContext}  userId={id} view='Financial' />
      </Row>
      {
        accountContext.accounts && accountContext.selectedAccounts &&
        <>
          <Row style={{ marginBottom: '24px' }}>
            <Col>
              <BankAccountDetails bankAccount={accountContext.selectedAccounts[0]} accountSummary={accountContext.accountSummary}   />
            </Col>
          </Row> 
          {accountContext.accountSummaryList && accountContext.accountSummaryList.length > 0 &&
          <>
          <Row style={{ marginBottom: '24px' }}>
            <Col>
              <AccountMonthBalance  />
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col>
              <AccountSummaryReport accountSummary={accountContext.accountSummary} />
            </Col>
          </Row>
          <Row>
            <Col>
              <AccountTransactions />
            </Col>
          </Row></>
          }

        </>
      }
    </>
  );
};

export default Financial;
