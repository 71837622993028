import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../components/Forms/FormInput';
import authService from '../../../services/authService/authService';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

export default function SendResetEmail() {
  const navigate = useNavigate();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [loading, setLoading] = useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required.')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address. Please try again.'),
  });

  const initialValues = {
    email: '',
  };

  const handleSubmit = async (data: { email: string }) => {
    try {
      setLoading(true);
      const response = await authService.setSendResetPasswordEmail(data.email);

      if (response?.status === 200) {
        window.localStorage.setItem('forgotEmail', data.email);
        navigate('/auth/email-success', { replace: true });
      } else {
        setAlertText('Unable to send reset email');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }

      setLoading(false);
    } catch (error) {
      setAlertText(error as string);
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
      <div className="login-form" style={{ maxWidth: '384px' }}>
        <h1 className="title">Forgot Password</h1>
        <p className="info-txt" style={{ paddingBottom: '25px' }}>
          Enter your email address and we will send you instructions to reset your password.
        </p>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="email" className="mb-4">
                <Form.Control
                  required
                  as={FormInput}
                  type="email"
                  value={values.email}
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  isInvalid={touched.email && !!errors.email}
                  className="form-input"
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['email'] === 'string' ? errors['email'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button
                  className="cancel-btn"
                  style={{ width: '175px' }}
                  onClick={() => {
                    navigate('/auth/login', { replace: true });
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" className="btn-form" style={{ height: 'auto', width: '175px' }}>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  <span>&nbsp;&nbsp;Send reset email</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Row>
  );
}
