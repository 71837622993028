import { useEffect, useState } from 'react';
import CheckBox from '../../../components/Forms/CheckBox';
import { type ClientInfoType } from '../../../types';
import { type FeaturesTypeResponse } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import { LoadingSpinnerComponent } from '../../../components/Loading';

import imgPlaid from '../../../assets/plaid.png';

export default function Plaid({ client }: ClientInfoType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FeaturesTypeResponse[]>([]);

  useEffect(() => {
    if (client) {
      setLoading(true);

      adminService
        .getFeaturesData(client?.clientId)
        .then((res: FeaturesTypeResponse[] | undefined) => {
          const filter = res?.filter(
            (rs: FeaturesTypeResponse) => rs.description !== '' && rs.key.search('plaid') !== -1
          );

          setData(filter as FeaturesTypeResponse[]);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [client]);

  return (
    <div className="client-settings">
      <h1 className="title">Plaid Settings</h1>
      <div style={{ marginBottom: '69px' }}>
        {loading && (
          <div style={{ marginTop: '100px' }}>
            <LoadingSpinnerComponent />
          </div>
        )}

        {data?.map((rs: FeaturesTypeResponse, key: number) => (
          <div key={key} style={{ borderBottom: '1px solid #ced4da' }}>
            <div className="result-row">
              <div className="item">
                <p className="txt">{rs.description}</p>
              </div>
              <CheckBox
                clientId={client?.clientId as string}
                targetKey={rs.key}
                type="switch"
                id={`custom-switch-${key}`}
                checked={rs.isEnabled}
                index={key}
                apiKind="Plaid"
              />
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: '40px' }}>
        <h3 className="txt-16">Powered By</h3>
        <img className="plaid-img" src={imgPlaid} alt="" />
      </div>
    </div>
  );
}
