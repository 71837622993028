import { useParams } from 'react-router-dom';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import '../../custom.css';

import debtpayproService from '../../../services/debtpayproService/debtpayproService';
import adminService from '../../../services/adminService/adminService';

import { FormInput } from '../../../components/Forms/FormInput';
import { type ClientInfoType, type Client, type ClientFormType, UrlRegex } from '../../../types';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';
import ModalComponent from '../../../components/Modal';
import { ComponentRef, forwardRef, useImperativeHandle, useState } from 'react';
import { MdRefresh, MdContentCopy } from "react-icons/md";
import settingsService from '../../../services/settingsService/settingsService';

type CrmBasicAuthProps = {
  client: Client;
};

type Handle = {
  handlerFunction: () => void;
};

export type CrmBasicAuthRef = ComponentRef<typeof CrmBasicAuth>;

const CrmBasicAuth = forwardRef<Handle,CrmBasicAuthProps>((props, ref) =>  {
  const params = useParams();
  const [key, setKey] = useState<string | undefined>(undefined);

  const handleSubmitBasicSecret = async () => {
    if(props?.client?.clientId && key){
      try{
        await settingsService.setBasicAuth(key, props?.client?.clientId);
      }
      catch(exception){

      }
    }
    
  }

  useImperativeHandle(ref, () => ({
    handlerFunction() {
      console.log('Creating asset Report');
      handleSubmitBasicSecret()
    },
  }));

  return (
          <>
              <>
                <InputGroup className="mb-3" style={{ marginBottom: '24px' }}>
                  <Form.Control
                    as={FormInput}
                    type={key ? "text" : "password"}
                    value={key ?? "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
                    readOnly
                  />
                  <Button variant='secondary' className='btnCustom' onClick={()=>setKey(crypto.randomUUID())}>
                    <MdRefresh />
                  </Button>
                  <Button 
                    disabled={!key}
                    variant='secondary' 
                    onClick={() => navigator.clipboard.writeText(key ?? '') }>
                    <MdContentCopy />
                  </Button>
                  
                 
              </InputGroup>
                <p>
                  Please copy the API Secret above and store it in a secure location. If you lose this key you must generate a new one.
                </p>
              </>
          </>
  );
});

export default CrmBasicAuth;
