/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import { MonthlySummary } from './monthlySummary';
import adminService from '../../services/adminService/adminService';
import { AuthContext } from '../../services/authService/authProvider';
import { MonthStatsResponse, StripeProduct, getFlatAmountPrice, getUnitPrice, renderMoneyAmount } from '../../types';
import stripeService from '../../services/stripeService/stripeService';
import { Data, TableNode } from '@table-library/react-table-library/table';
import 'react-datepicker/dist/react-datepicker.css';

const MonthlyUsage = () => {
  const auth = useContext(AuthContext);

  const [monthData, setMonthData] = useState<MonthStatsResponse[] | undefined>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [products, setProducts] = useState<StripeProduct[] | undefined>(undefined);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Data<TableNode>>({
    nodes: [],
  });

  useEffect(() => {
    (async () => {
      if (auth.clientId) {
        setTableLoaded(true);
        const data = await adminService.getClientMonthlyUsageList(auth.clientId);
        const prods = await stripeService.getProducts();
        setMonthData(data);

        if (data && data.length !== 0) {
          setSelectedMonth(data?.[0]?.id);

          if (prods) {
            setProducts(prods);
          }
        } else {
          setTableLoaded(false);
        }
      }
    })();
  }, [auth.clientId]);

  const getMonthlyUsageData = async (date: string) => {
    const summaryItems: TableNode[] = [];
    let totalPriceSum = 0;

    setTableLoaded(true);
    const monthlyData = await adminService.getClientMonthlyUsage(date, auth.clientId as string);

    if (monthlyData) {
      const amounts = [
        { id: 'asset_report', amount: monthlyData?.month?.plaidAssetReport },
        { id: 'notification', amount: monthlyData?.month?.notifications },
        {
          id: 'plaid_item',
          amount: (monthlyData?.month?.totalPlaidConnections || 0) + (monthlyData?.month?.removedPlaidConnections || 0),
        },
        { id: 'app_user', amount: monthlyData?.month?.totalUsers },
      ];

      if (products) {
        amounts.forEach(a => {
          const product = products.find(p => p.app_product === a.id);

          if (product) {
            const flatAmount = getFlatAmountPrice(product, a.amount || 0);
            const unitPrice = getUnitPrice(product, a.amount || 0);
            const totalPrice = getTotalPriceText(flatAmount, unitPrice);
            const totalNumber = getTotalPriceNumber(flatAmount, unitPrice, a.amount || 0);
            totalPriceSum += totalNumber ?? 0;

            summaryItems.push({
              id: a.id,
              product: product?.name,
              unit_amount: a.amount,
              flat_amount: totalPrice,
              total: renderMoneyAmount(totalNumber),
            });
          }
        });
      }
    }

    setTotalSum(totalPriceSum);
    setTableData({ nodes: summaryItems });
    setTableLoaded(false);
  };

  useEffect(() => {
    (async () => {
      if (selectedMonth !== '') await getMonthlyUsageData(selectedMonth);
    })();
  }, [selectedMonth]);

  const getTotalPriceText = (flatAmount: number | undefined, unitPrice: number | undefined) => {
    if (!flatAmount) {
      return renderMoneyAmount(unitPrice);
    } else {
      return renderMoneyAmount(flatAmount) + ' ' + (unitPrice ? renderMoneyAmount(unitPrice) + ' per unit' : '');
    }
  };

  const getTotalPriceNumber = (flatAmount: number | undefined, unitPrice: number | undefined, amount: number) => {
    if (!flatAmount) {
      return (unitPrice ?? 0) * amount;
    } else {
      return flatAmount + (unitPrice ?? 0) * amount;
    }
  };

  const handleChangeDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <>
      <p className="billing-cycle-title">Billing Cycle</p>
      <div className="billing-info-row">
        <div className="datepicker-content">
          <Form.Select
            className="customSelect"
            style={{ width: '100%', height: '33px', marginBottom: '24px' }}
            value={selectedMonth}
            onChange={handleChangeDate}
          >
            {monthData?.length === 0 && <option value="">Select date...</option>}

            {monthData?.map((rs: MonthStatsResponse, key: number) => (
              <option value={rs.id} key={key}>
                {rs.id}
              </option>
            ))}
          </Form.Select>
        </div>
        <h1 className="total">Total : {renderMoneyAmount(totalSum)}</h1>
      </div>
      <div style={{ marginBottom: '30px' }}>
        <MonthlySummary tableData={tableData} loading={tableLoaded} />
      </div>
    </>
  );
};

export { MonthlyUsage };
