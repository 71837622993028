import { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ContentCard } from '../../components/Card';
import MenuButtons from '../../components/MenuButtons';
import { AuthContext } from '../../services/authService/authProvider';
import { clientMenus, type Client } from '../../types';
import adminService from '../../services/adminService/adminService';
import ClientInfo from '../Clients/edit/ClientInfo';
import CrmKeys from '../Clients/edit/CrmKeys';
import DocTypes from '../Clients/edit/DocTypes';
import Notes from '../Clients/edit/Notes';
import Advances from '../Clients/edit/Advances';
import Plaid from '../Clients/edit/Plaid';
import PushNotification from '../Clients/edit/PushNotification';
import Email from '../Clients/edit/email/Email';
import Assets from '../Clients/edit/Assets';

export default function Settings() {
  const auth = useContext(AuthContext);
  const query = useLocation();

  const [selectView, setSelectView] = useState<string>('Client Info');
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
      const view = new URLSearchParams(query.search).get('view');

      adminService.getCurrentClient().then((rs: Client | undefined) => {
        setClient(rs);
      });
  }, [auth, query]);

  return (
    <Container fluid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
        <h3 style={{ color: 'white' }}>Settings</h3>
      </div>
      <ContentCard>
        <Container>
          <div style={{ marginBottom: '37px' }}>
            <MenuButtons titles={clientMenus} selectView={selectView} setSelectView={setSelectView} />
          </div>
          <div>
            <>
              {selectView === 'Client Info' && <ClientInfo client={client} />}

              {selectView === 'CRM' && <CrmKeys client={client} loading={loading} setLoading={setLoading} />}

              {selectView === 'Assets' && <Assets client={client}  />}

              {selectView === 'Email' && <Email/>}

              {selectView === 'Push Notification' && (
                <PushNotification client={client} loading={loading} setLoading={setLoading} />
              )}

              {selectView === 'Plaid Settings' && <Plaid client={client} />}

              {selectView === 'Advances' && <Advances client={client} />}


              {selectView === 'Notes' && <Notes client={client} />}

              {selectView === 'Documents' && <DocTypes client={client} />}

            </>
          </div>
        </Container>
      </ContentCard>
    </Container>
  );
}
