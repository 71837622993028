import { CardStored, InvoiceSettings } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

class paymentMethodService {
  async getPayemntMethods(customerId: string): Promise<Array<CardStored> | undefined> {
    try {
      const response = await axiosPrivate.get(`/stripe/v1/payment_methods?customer=${customerId}&type=card`);

      if (response?.status === 200) {
        return response?.data?.data?.map(
          (u: any) =>
            ({
              id: u.id,
              brand: u.card.brand,
              exp_month: u.card.exp_month,
              exp_year: u.card.exp_year,
              last4: u.card.last4,
            } as CardStored)
        );
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getCustomerMethods(customerId: string): Promise<InvoiceSettings | string> {
    try {
      const response = await axiosPrivate.get(`/stripe/v1/customers/${customerId}`);

      if (response?.status === 200) {
        return response?.data?.invoice_settings?.default_payment_method;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return '';
  }
}

export default new paymentMethodService();
