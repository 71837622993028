import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import styled from 'styled-components';
import { AccountContext } from '../../services/accountService/accountsProvider';
import { InstitutionLogo } from '../Institutions/institutionLogo';
import { Account, capitalize, moneyAmount } from '../../types';

interface IBankAccountSelector {
  setSelectedAccount : (account:Account , view:string) => void;
  selectedAccounts?: Array<Account>;
}
interface IBankAccountProps {
  account: Account;
  view:string
  bankAccountContext: IBankAccountSelector;
}

const Wrapper = styled(Card)`
    display: flex;
    flex-direction: row;
    margin:10px;
    padding:10px;
    align-items: center;
    cursor: pointer;
    div:last-of-type {
      margin-left: auto;
    }
    &:hover {
        background-color: #f8f9fa;
    }
`;

const UserBankAccount = ({ account, bankAccountContext, view}: IBankAccountProps) => {  
    const { id, institutionId, institutionName, subType, latestBalance, requiresLogin} = account;
    const selectedAccount = bankAccountContext.selectedAccounts?.includes(account);

    const handleClick = () => {
      bankAccountContext.setSelectedAccount(account,view);
    };

    return (
      <>
        <Wrapper onClick={handleClick} style={{borderColor:selectedAccount ? '#3d8acb' :'', borderWidth:selectedAccount ? 'medium': ''}}>
         <div><InstitutionLogo id={institutionId} size={45}></InstitutionLogo> </div>

          <div style={{ marginLeft: '16px' }}>
            <div style={{ fontSize: '14px', color: '#2D3142', fontWeight: '600', marginBottom: '1px' }}>
              {institutionName}
            </div>
            <div style={{ fontSize: '12px', color: '#2D3142', fontWeight: '300', marginBottom: '1px' }}>
              {capitalize(subType)}
            </div>
            <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '600', marginBottom: '3px' }}>
              {moneyAmount(latestBalance)}
            </div>
           
          </div>
          <div>
          {requiresLogin &&
            <div>
              <BsFillExclamationTriangleFill size={30} color='#ffcc00'/>
              </div>
            }
          </div>
        </Wrapper>
      </>
    );
  };
  
  export { UserBankAccount };
  