/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { ClientNotificationType, TableItem } from '../../../../types';
import { Data } from '@table-library/react-table-library/table';
import adminService from '../../../../services/adminService/adminService';
import NotificationList from './NotificationList';
import SendNotification from './SendNotification';
import { Button, Row as BRow, Col } from 'react-bootstrap';

export default function Notifications({
  userId,
  clientId,
  contactId,
  sendView,
  setSendView,
}: {
  userId: string;
  clientId: string;
  contactId: string;
  sendView: boolean;
  setSendView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Data<any>>({ nodes: [] });
  const [perPage, setPerPage] = useState<number>(10);

  useEffect(() => {
    if (userId) {
      (async () => {
        const data = await adminService.getClientUserNotification(userId, clientId);
        setTableLoaded(true);
        const tableData = { nodes: data } as Data<any>;
        setTableData(tableData);
      })();
    }
  }, [userId, alert]);

  return (
    <>
      <BRow>
        <Col>
          {sendView && <h1 style={{ fontSize: '24px', color: '#2d3142', marginBottom: '36px' }}>Send Notification</h1>}
        </Col>
        <Col>
          <Button
            className="btn-backround"
            type="submit"
            style={{ float: 'right', ...(sendView ? { visibility: 'hidden' } : {}) }}
            onClick={() => setSendView(true)}
          >
            <span>Send</span>
          </Button>
        </Col>
      </BRow>

      {!sendView && (
        <NotificationList
          tableLoaded={tableLoaded}
          tableData={tableData}
          perPage={perPage}
          sendView={sendView}
          setSendView={setSendView}
          setPerPage={setPerPage}
        />
      )}

      {sendView && <SendNotification contactId={contactId} setSendView={setSendView} />}
    </>
  );
}
