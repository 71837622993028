import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row as BRow, Form, InputGroup, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell, Data } from '@table-library/react-table-library/table';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import styled from 'styled-components';
import { TableItem, Client } from '../../types';
import { LoadingSpinnerComponent } from '../../components/Loading';
import { StatusBadge } from '../../components/Badges/StatusBadge';

import { FaChevronDown, FaChevronUp, FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';

interface ClientsProps {
  search: string;
  clientList: Client[] | undefined;
}

const StyledHeaderCell = styled(HeaderCell)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledRow = styled(Row)`
  cursor: pointer;
  &:focus {
    background-color: white;
    color: black;
  }
  &:hover {
    background-color: #4c5980;
    color: black;
  }
`;

const StyledGroupText = styled(InputGroup.Text)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
`;
const StyledGroupButton = styled(Button)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: #4c5980;
  padding: 5px;
  &.btn:disabled {
    background-color: transparent;
    color: #4c5980;
  }
`;

function ClientsTable({ search, clientList }: ClientsProps) {
  const navigate = useNavigate();

  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [client, setClient] = useState<Array<TableItem<Client>>>();
  const [tableData, setTableData] = useState<Data<any>>({
    nodes: [],
  });
  const [perPage, setPerPage] = useState<number>(10);

  const theme = useTheme({
    Row: `
    cursor: pointer;
    color: rgb(117, 117, 117);
    &:hover {
      color: black;
    }
    &:hover .td {
      background-color:#f8f9fa;
    }
    `,
  });

  useEffect(() => {
    if (clientList) {
      const tableItems = clientList?.map((r, i) => ({ ...r, number: i + 1 } as TableItem<Client>));
      setTableLoaded(true);
      setClient(tableItems);
      const tableData = { nodes: tableItems } as Data<any>;
      setTableData(tableData);
    }
  }, [clientList]);

  useEffect(() => {
    if (client) {
      const filteredData = {
        nodes: client.filter(f => f.clientName.toLowerCase().includes(search.toLowerCase())),
      } as Data<any>;
      setTableData(filteredData);
    }
  }, [search, client]);

  const pagination = usePagination(tableData, {
    state: {
      page: 0,
      size: perPage,
    },
  });

  const sort = useSort(
    tableData,
    {
      onChange: () => {},
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <FaChevronUp style={{ color: '#4C5980' }} />,
        iconDown: <FaChevronDown style={{ color: '#4C5980' }} />,
      },
      sortFns: {
        CLIENTNAME: array => array.sort((a, b) => a.name - b.clientName),
      },
    }
  );

  const Badge = (status: boolean): ReactNode => {
    switch (status) {
      case true:
        return <StatusBadge type="success">Active</StatusBadge>;
      case false:
        return <StatusBadge>Inactive</StatusBadge>;
    }
  };

  return (
    <>
      {!tableLoaded && <LoadingSpinnerComponent />}

      {tableLoaded && tableData.nodes.length === 0 && (
        <Container>
          <BRow className="justify-content-md-center">
            <Col md="auto">
              <span>Data not available</span>
            </Col>
          </BRow>
        </Container>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <>
          <Table data={tableData} pagination={pagination} sort={sort} theme={theme}>
            {(tableList: any[]) => (
              <>
                <Header>
                  <HeaderRow>
                    <StyledHeaderCell>#</StyledHeaderCell>
                    <StyledHeaderCell>ID</StyledHeaderCell>
                    <StyledHeaderCell>Name</StyledHeaderCell>
                    <StyledHeaderCell>Created Date</StyledHeaderCell>
                    <StyledHeaderCell>Status</StyledHeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map(item => (
                    <StyledRow item={item} key={item.number} onClick={() => navigate(`/admin/clients/${item.id}`)}>
                      <Cell>{item.number}</Cell>
                      <Cell>{item.clientId}</Cell>
                      <Cell>{item.clientName}</Cell>
                      <Cell>{format(new Date(item.created), 'dd/MM/yy')}</Cell>
                      <Cell>{Badge(item.isActive)}</Cell>
                    </StyledRow>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <BRow style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            {tableData.nodes.length} items in total
          </Col>
          <Col md={9} xs={7}>
            <InputGroup className="justify-content-end">
              <div style={{ width: '270px' }}>
                <Form.Select
                  defaultValue={perPage}
                  style={{ outline: 'none', boxShadow: 'none', border: 'none' }}
                  onChange={e => setPerPage(Number(e.target.value))}
                >
                  <option value="10">Showing 10 items per page</option>
                  <option value="20">Showing 20 items per page</option>
                  <option value="50">Showing 50 items per page</option>
                  <option value="100">Showing 100 items per page</option>
                </Form.Select>
              </div>
              <StyledGroupButton disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                <FaStepBackward></FaStepBackward>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page === 0}
                onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
              >
                <FaPlay style={{ transform: 'rotate(180deg)' }}></FaPlay>
              </StyledGroupButton>
              <StyledGroupText>
                {pagination.state.getPageBoundaries(tableData.nodes).start}
                {'-'}
                {pagination.state.getPageBoundaries(tableData.nodes).end}
              </StyledGroupText>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
              >
                <FaPlay></FaPlay>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(tableData.nodes) - 1)}
              >
                <FaStepForward></FaStepForward>
              </StyledGroupButton>
            </InputGroup>
          </Col>
        </BRow>
      )}
    </>
  );
}

export { ClientsTable };
