import { Advance, AdvanceDetails, AssignmentFilterType, AssignmentType } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';

class AdvancesService {

  async getContactAdvances(clientId: string, contactId: string): Promise<Advance[] | undefined> {
    try {
      const response = await axiosPrivate.get(`${Endpoints.GetContactAdvancesUrl}?clientId=${clientId}&contactId=${contactId}`, { cache: false });

      if (response?.status === 200) {
        return response?.data.advances;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }

  async getAdvanceDetails(clientId: string, contactId: string, advanceId:string): Promise<AdvanceDetails | undefined> {
    try {
      const response = await axiosPrivate.get(`${Endpoints.GetAdvanceDetailsUrl}?clientId=${clientId}&contactId=${contactId}&advanceId=${advanceId}`, { cache: false });

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }
  
  async getAssignmentType(clientId: string): Promise<AssignmentType[] | undefined> {
    try {
      const response = await axiosPrivate.get(`${Endpoints.AssignmentTypesUrl}?clientId=${clientId}`, { cache: false });

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }

  async setAssignmentTypeFilter(filter: AssignmentFilterType): Promise<any> {
    try {
      const response = await axiosPrivate.put(Endpoints.AssignmentTypesFilterUrl, filter);

      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      return Promise?.reject(error);
    }

    return undefined;
  }
}

export default new AdvancesService();
