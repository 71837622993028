import { CompactTable } from '@table-library/react-table-library/compact';
import { Data, TableNode } from '@table-library/react-table-library/table';
import { LoadingSpinnerComponent } from '../../components/Loading';
import { Col, Row } from 'react-bootstrap';

type SummaryProps = {
  tableData: Data<TableNode>;
  loading: boolean;
};

const MonthlySummary = ({ tableData, loading }: SummaryProps) => {
  const COLUMNS = [
    { label: 'Product', renderCell: (item: any) => item.product },
    { label: 'Amount', renderCell: (item: any) => item.unit_amount },
    { label: 'Price', renderCell: (item: any) => item.flat_amount },
    { label: 'Total', renderCell: (item: any) => item.total },
  ];

  return (
    <>
      {loading ? (
        <LoadingSpinnerComponent />
      ) : tableData.nodes.length === 0 ? (
        <Row className="justify-content-md-center">
          <Col md="auto">
            <span>Data not available</span>
          </Col>
        </Row>
      ) : (
        <CompactTable columns={COLUMNS} data={tableData} />
      )}
    </>
  );
};

export { MonthlySummary };
