import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import adminService from '../../services/adminService/adminService';
import DocumentService from '../../services/crmService/DocumentService';
import AdvancesService from '../../services/crmService/AdvancesService';
import { useAlertComponent } from '../Alert/AlertProvider';

interface CheckBoxProps {
  type: 'checkbox' | 'radio' | 'switch';
  id: string;
  label?: string;
  checked?: boolean;
  index: number;
  clientId: string;
  targetKey: string;
  apiKind: string;
}

export default function CheckBox({ clientId, targetKey, type, id, label, checked, index, apiKind }: CheckBoxProps) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const switchRef = useRef<any>([]);

  const handleChangeSwitch = async (isEnabled: boolean) => {
    try {
      let response: any = null;

      if (apiKind === 'Plaid') {
        response = await adminService.setFeaturesStatus({
          clientId,
          key: targetKey,
          isEnabled,
        });
      }

      if (apiKind === 'Document') {
        response = await DocumentService.setDocumentTypeFilter({
          clientId,
          documentId: targetKey,
          isEnabled,
        });
      }

      if (apiKind === 'Assignments') {
        response = await AdvancesService.setAssignmentTypeFilter({
          clientId,
          key: targetKey,
          isEnabled,
        });
      }

      if (response?.status === 200) {
        switchRef.current[index].checked = isEnabled;
      } else {
        setAlertText('features update was failed.');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }
    } catch (error) {
      setAlertText('features update was failed.');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }
  };

  return (
    <Form.Check
      type={type}
      ref={(e: any) => (switchRef.current[index] = e)}
      id={id}
      label={label}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSwitch(e.target.checked)}
      checked={checked}
    />
  );
}
