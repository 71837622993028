import { MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormControlProps, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Formik, FormikValues, useFormikContext } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../../components/Forms/FormInput';
import { isValidSendGridKey, type ClientInfoType } from '../../../../types';
import { type Client, ClientEmailSettings, EmailRegex, SendGridRegex } from '../../../../types';
import emailService from '../../../../services/emailService/emailService';
import { AuthContext } from '../../../../services/authService/authProvider';
import { useAlertComponent } from '../../../../components/Alert/AlertProvider';
import settingsService from '../../../../services/settingsService/settingsService';
import { MdEdit } from 'react-icons/md';
import { SubmitTest } from './SubmitTest';

export default function SendgridEmail({ client, loading, setLoading }: ClientInfoType) {
  const auth = useContext(AuthContext);
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const initialValues = {
    sendgridApiEnabled: client?.sendgridApiEnabled ?? false,
    sendgridApiKey: '',
    emailFrom: client?.emailFrom,
    nameFrom: client?.nameFrom
  };
  
  const [inputStarted,setInputStarted] = useState<boolean>(false);

  const schema = yup.object().shape({
    sendgridApiKey: yup.string().matches(SendGridRegex, 'Please enter a valid Sendgrid Key').required('Sendgrid API key is required'),
    emailFrom: yup.string().matches(EmailRegex, 'Please type a valid email address').required('Email is required'),
    nameFrom: yup.string().nullable().required('Email Name From is required'),
  });


const onResetForm = () => {
  setInputStarted(false);

}
  
  const handleSubmit = async (data: ClientEmailSettings) => {
    if (setLoading) setLoading(true);

    try {
      const submit = {
        sendgridApiEnabled: true,
        sendgridApiKey: data.sendgridApiKey,
        emailFrom: data.emailFrom,
        nameFrom: data.nameFrom
      };

      const response = await settingsService.setEmail(submit as ClientEmailSettings,client?.clientId!);
        setAlertText('Succesfully update email');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
    } catch (error: any) {
      setAlertText('Unable to update email');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    if (setLoading) setLoading(false);
  };

  return (
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnChange
          validateOnBlur
          enableReinitialize
          onReset={onResetForm}
        >
          
          {({ handleChange, handleSubmit,validateForm, values, touched, errors, resetForm, isValid, isInitialValid }) => (
            <>
                <Row>
                  <Col style={{ textAlign: 'right' }}>
                    {!inputStarted && 
                      <Button variant='secondary' className='btnCustom' onClick={()=>{setInputStarted(true); validateForm();}}>
                        Edit
                      </Button>
                    }
                    {inputStarted && 
                    <Button variant='secondary' className='btnCustom' onClick={()=>{resetForm();}}>
                      Cancel
                    </Button>
                    }
                  </Col>
                </Row>
              <Form noValidate onSubmit={handleSubmit} >
                
                <Form.Group controlId="sendgridApiKey" style={{ marginBottom: '40px' }}>
                <Form.Label className="label">Send Grid API Key</Form.Label>
                  <InputGroup>
                        <Form.Control
                            autoFocus={true}
                            as={FormInput}
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleChange(event)}}
                            value={values.sendgridApiKey}
                            isInvalid={!!errors.sendgridApiKey}
                            autoComplete="off"
                            style={{'display' : inputStarted ? 'inline' :'none' }}
                        /> 
                   {
                    !inputStarted &&
                        <Form.Control
                            as={FormInput}
                            type="password"
                            readOnly
                            defaultValue={"****************************"}
                            autoComplete="off"
                        /> 
                        
                  }
                  </InputGroup>
                  <div className='text-danger'>
                   <small>{typeof errors['sendgridApiKey'] === 'string' ? errors['sendgridApiKey'] : ''}</small>
                  </div>
                </Form.Group>
              <Form.Group controlId="emailFrom" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Email From</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  defaultValue={values.emailFrom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  readOnly={!inputStarted}
                  isInvalid={!!errors.emailFrom}
                  autoComplete="off"
                />
                <div className='text-danger'>
                   <small> {typeof errors['emailFrom'] === 'string' ? errors['emailFrom'] : ''}</small>
                  </div>
              </Form.Group>
              <Form.Group controlId="nameFrom" style={{ marginBottom: '40px' }}>
                <Form.Label className="label">Name From</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value = {values.nameFrom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  readOnly={!inputStarted}
                  isInvalid={!!errors.nameFrom}
                  autoComplete="off"
                />
                  <div className='text-danger'>
                    <small> {typeof errors['nameFrom'] === 'string' ? errors['nameFrom'] : ''}</small>
                  </div>
              </Form.Group>
             
              <Button type="submit" disabled={!!touched && !inputStarted }className="btn-backround" style={{ float: 'right', marginLeft: '15px' }}>
                Save
                {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
             <SubmitTest clientId={client?.clientId!} emailFrom={values.emailFrom} fromName={values.nameFrom} sendgridApiKey={values.sendgridApiKey} enabled={inputStarted}    />
              {/* <pre>
              {JSON.stringify(
                {
                  touched,
                  errors,
                  handleChange,
                  values,
                  validateForm,
                  isValid,
                  isInitialValid
                },
                null,
                2
              )}
            </pre> */}
            </Form>
            </>
          )}
        </Formik>
  );
}



