import { Client, ClientAssets, ClientBilling, ClientEmailSettings, User } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

class SettingsService {
  async setCrmKey(keyId:string, secret: string, clientId: string) {
    try {
      const response = await axiosPrivate.post(`/admin/api/settings/crm/api-key`,
      { 
        clientId: clientId,
        keyId: keyId,
        secret: secret
      });

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async setAssets(assets: ClientAssets, clientId: string) {
    try {
      const response = await axiosPrivate.post(`/admin/api/settings/assets`,
      { 
        clientId,
        ...assets
      });

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async setInfo(clientInfo: Client) {
    try {
      const response = await axiosPrivate.post(`/admin/api/settings/info`,
      { 
        ...clientInfo
      });

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async setEmail(emailSettings: ClientEmailSettings, clientId: string) {
    try {
      const response = await axiosPrivate.post(`/admin/api/settings/email`,
      { 
        clientId,
        ...emailSettings
      });

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async setBasicAuth(password: string, clientId: string) {
    try {
      const response = await axiosPrivate.post(`/admin/api/settings/crm/basic-auth`,
      { 
        clientId,
        password
      });

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async setTestAccount(clientId: string, isTestAccount: boolean) {
    try {
      const response = await axiosPrivate.post(`/admin/api/clients/set-test-account`,
      { 
        clientId,
        isTestAccount
      });

      if (response?.status === 200) {
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new SettingsService();
