import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';
class NotificationsService {
  async sendNotification(title: string, message: string, clientId: string): Promise<any> {
    try {
      const response = await axiosPrivate.post(Endpoints.SendNotification, {
        title: title,
        message: message,
        clientId: clientId,
      });

      if (response?.status === 200) {
        return response;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new NotificationsService();
