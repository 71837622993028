import { Fragment, PropsWithChildren, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Dropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';
import type { btnOption } from '../MenuButtons';
import MenuButtons from '../MenuButtons';
import { SelectFilterType } from '../../containers/Dashboard';

import { FaFilter} from 'react-icons/fa';

interface ContentProps {
  title?: string;
  period: string;
  bodyPadding?: string;
  selectFilter: SelectFilterType[];
  menu: btnOption[];
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  setSelectFilter: React.Dispatch<React.SetStateAction<SelectFilterType[]>>;
}

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;
const PaddedDiv = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    padding: 0em;
  }
`;
const StyledCard = styled(Card)`
  border-radius: 1em;
  @media screen and (max-width: 575px) {
    border: none;
    padding: 0;
    .card-body {
      padding: 0;
    }
  }
`;

const RoundCard = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledCard {...newprops} />;
};

const OverviewCard = ({
  title,
  period,
  setPeriod,
  bodyPadding,
  menu,
  selectFilter,
  setSelectFilter,
  children,
}: PropsWithChildren<ContentProps>) => {



  const handleClickFilter = (event: any) => {
    setSelectFilter(prev => {
      const find = [...prev].find((f: SelectFilterType) => f.value === event.target.id && f.checked === true);

      if (find) {
        return [...prev].map((item: SelectFilterType) => {
          if (item.value === find.value) item.checked = false;
          return item;
        });
      } else {
        return [...prev].map((item: SelectFilterType) => {
          if (item.value === event.target.id) item.checked = true;
          return item;
        });
      }
    });
  };

  return (
    <>
      <Wrapper>
        <RoundCard>
          <PaddedDiv>
            {title && (
              <Card.Title>
                <h3 style={{ fontSize: '20px', color: '#2D3142' }}>{title}</h3>
              </Card.Title>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  className="btnNavCustom gray"
                  style={{ marginRight: '5px', paddingLeft: '9px', paddingTop: '3px', marginLeft: '3px' }}
                >
                  <FaFilter style={{ color: '#2D3142' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="super-colors" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {selectFilter?.map((item: SelectFilterType, key: number) => (
                    <Fragment key={item.id}>
                      <Dropdown.Item
                        id={item.value}
                        onClick={handleClickFilter}
                        {...(item.checked ? { active: true } : {})}
                        style={{ borderRadius: '5px' }}
                      >
                        {item.label}
                      </Dropdown.Item>

                      {key < selectFilter.length - 1 && <Dropdown.Divider />}
                    </Fragment>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <MenuButtons titles={menu} selectView={period} setSelectView={setPeriod} />
            </div>
          </PaddedDiv>
          <Card.Body style={{ padding: bodyPadding }}>{children}</Card.Body>
        </RoundCard>
      </Wrapper>
    </>
  );
};

export { OverviewCard };
