import { useContext, useState } from 'react';
import { Row, Spinner, Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormInput } from '../../components/Forms/FormInput';
import { AuthContext } from '../../services/authService/authProvider';
import { LocationProps } from '../../types';

type loginFormErrors = {
  email: string;
  password: string;
};

type loginForm = {
  email: string;
  password: string;
};

function LoginForm() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation() as unknown as LocationProps;
  const from = location.state?.from?.pathname || '/';

  const [form, setForm] = useState<loginForm>({ email: '', password: '' });
  const [errors, setErrors] = useState<loginFormErrors>({ email: '', password: '' });
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [signinIn, setSigninIn] = useState<boolean>(false);

  const setField = (field: string, value: string) => {
    setForm({ ...form, [field]: value });
    setServerError(undefined);

    if (errors.hasOwnProperty(field)) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateform = (): loginFormErrors => {
    const newErrors: loginFormErrors = { password: '', email: ''};

    if (!form.password || form.password === '') {
      newErrors.password = 'Password is required';
    }

    if (!emailValidation(form.email)) {
      newErrors.email = 'Email is not valid';

    }
    
    if (!form.email || form.email === '' ) {
      newErrors.email = 'Email is required';
    }

    return newErrors;
  };

  const emailValidation = (email: string) => {
    const isEmailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ); // use any validator you want

    return isEmailValid;
  };

  const handleLogin = (event: any) => {
    event.preventDefault();
    const formErrors = validateform();

    if (Object.values(formErrors).some(val => val)) {
      setErrors(formErrors);
      return;
    }

    setSigninIn(true);

    try {
      auth
        .signIn(form.email, form.password, () => {
          navigate(from, { replace: true });
        })
        .then(
          () => {},
          error => {
            if (typeof error === 'string') {
              setServerError(error);
            } else {
              setServerError('Unable to login');
              console.log(error);
            }
            setSigninIn(false);
          }
        );
    } catch (e) {
      setSigninIn(false);
    }
  };

  const goToUrl = (url: string) => {
    window.location.href = url;
  };

  return (
    <Row className="justify-content-center">
      <div className="login-form" style={{ maxWidth: '384px' }}>
        <h1 className="title">Login</h1>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="form-label">Username</Form.Label>
            <Form.Control
              required
              as={FormInput}
              type="email"
              placeholder="Enter your username"
              onChange={e => setField('email', e.target.value)}
              isInvalid={!!errors.email}
              className="form-input"
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="mt-4 mb-3">
            <Form.Label className="form-label">Password</Form.Label>
            <Form.Control
              required
              as={FormInput}
              type="password"
              placeholder="Enter Your Password"
              onChange={e => setField('password', e.target.value)}
              isInvalid={!!errors.password}
              className="form-input"
            />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <div className="option">
            <Form.Check // prettier-ignore
              type="checkbox"
              id="default"
              label="Remember Password"
            />
            <p className="reset-txt" onClick={() => navigate('/auth/reset-email')}>
              Forgot Password?
            </p>
          </div>
          <span>{serverError ?? serverError}</span>
          <div className="d-grid gap-2 mt-3">
            <Button
              size="lg"
              variant="primary"
              type="submit"
              onClick={handleLogin}
              disabled={signinIn}
              className="btn-form"
            >
              {signinIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              <span>&nbsp;&nbsp;Login Now</span>
            </Button>
          </div>
        </Form>
        <div className="sign-up">
          <span className="txt-1">Don't have an account?</span>
          <span className="txt-2" onClick={() => goToUrl('https://www.centrexsoftware.com/request-a-demo/')}>
            {' '}
            Sign Up
          </span>
        </div>
      </div>
    </Row>
  );
}

export { LoginForm };
