import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import { object, ObjectSchema, string } from 'yup';
import YupPassword from 'yup-password';
import { ContentCard } from '../../components/Card';
import { CentrexDropdown } from '../../components/Dropdown';
import { FormInput } from '../../components/Forms/FormInput';
import adminService from '../../services/adminService/adminService';
import { Client, CreateWebUserForm, EmailRegex, KeyValuePair, Roles } from '../../types';

YupPassword(Yup);
const Wrapper = styled.div`
  margin: 2em;
`;
const ServerError = styled.p`
  color: red;
`;
const ServerSucess = styled.p`
  color: green;
`;
const FormWrapper = styled.div`
  margin-bottom: 1.5em;
`;

function CreateWebUser() {
  const initialValues = { email: '', password: '', roles: '' };
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [clients, setClients] = useState<Array<Client> | undefined>(undefined);
  const [selectedClient, setSelectedClient] = React.useState<Client | undefined>(undefined);
  const [selectedAdminRol, setSelectedAdminRole] = React.useState<KeyValuePair | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  React.useEffect(() => {
    adminService.getClients().then(result => {
      if (result) {
        setClients(result);
      }
    });
  }, []);

  const schema: ObjectSchema<any> = object({
    email: string().matches(EmailRegex, 'Please type a valid email address').required('Email is required'),
    password: string()
      .min(8)
      .minLowercase(1, 'password must contain at least 1 lower case letter')
      .minUppercase(1, 'password must contain at least 1 upper case letter')
      .minNumbers(1, 'password must contain at least 1 number')
      .minSymbols(1, 'password must contain at least 1 special character')
      .required('Password  is required'),
    roles: string().oneOf(['Admin', 'Client']).required('Please select a role'),
    clientId: string().when('roles', (roles: Array<string>, schema: any) => {
      if (roles[0] === 'Client') {
        return schema.required();
      } else {
        return schema.notRequired();
      }
    }),
    firstName: string().optional(),
    lastName: string().optional(),
    id: string().optional(),
  });

  const handleClientSelect = (client: Client) => {
    setSelectedClient(client);
  };

  const handleAdminSelect = (item: KeyValuePair) => {
    setSelectedAdminRole(item);
    if (item.value === 'Admin') {
      setSelectedClient(undefined);
    }
  };

  const handleCreateUser = async (form: CreateWebUserForm) => {
    setIsProcessing(true);

    try {
      if (typeof form.roles === 'string') {
        form.roles = [form.roles.toLocaleLowerCase()];
      }

      await adminService.createWebUser(form);
      setIsSuccess(true);
      setIsProcessing(false);
    } catch (exception: any) {
      if (exception && typeof exception.message === 'string') {
        setServerError(exception.message);
      }

      if (typeof exception === 'object') {
        let errorString = '';

        Object.keys(exception).forEach(e => {
          errorString += exception[e].join(e, ', ');
        });

        setServerError(errorString);
      }

      setIsProcessing(false);
    }
  };
  return (
    <>
      <Container fluid>
        <ContentCard>
          <Wrapper>
            <h2>Create web user</h2>
            <Row>
              <Col md={4}>
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={handleCreateUser}
                >
                  {({ handleChange, handleBlur, values, touched, isValid, errors, setFieldValue }) => (
                    <Form noValidate>
                      <FormWrapper>
                        <Form.Group controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Text
                            required
                            as={FormInput}
                            type="text"
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid"> {errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                      </FormWrapper>
                      <FormWrapper>
                        <Form.Group controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Text
                            required
                            as={FormInput}
                            type="text"
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid"> {errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                      </FormWrapper>
                      <FormWrapper>
                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Text
                            required
                            as={FormInput}
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={!errors.email}
                            isInvalid={!!errors.email && touched.email}
                          />
                          <Form.Control.Feedback type="invalid"> {errors.email}</Form.Control.Feedback>
                        </Form.Group>
                      </FormWrapper>
                      <FormWrapper>
                        <Form.Group controlId="roles">
                          <Form.Label>Role</Form.Label>
                          <CentrexDropdown
                            controlId="roles"
                            labelKey="value"
                            valueKey="key"
                            items={Roles}
                            onSelect={a => {
                              handleAdminSelect(a);
                              setFieldValue('clientId', undefined, true);
                              setFieldValue('roles', a.value, true);
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={'Select Role'}
                            selectedItem={selectedAdminRol}
                          />
                          <Form.Control.Feedback type="invalid"> {errors.roles}</Form.Control.Feedback>
                        </Form.Group>
                      </FormWrapper>
                      {selectedAdminRol && selectedAdminRol.value === 'Client' && (
                        <FormWrapper>
                          <Form.Group controlId="clientId">
                            <Form.Label>Client</Form.Label>
                            <CentrexDropdown
                              controlId="clientId"
                              labelKey="clientName"
                              valueKey="id"
                              items={clients}
                              onSelect={a => {
                                handleClientSelect(a);
                                setFieldValue('clientId', a.id, true);
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder={'Select Client'}
                              selectedItem={selectedClient}
                            />
                            <Form.Control.Feedback type="invalid"> {errors.clientId}</Form.Control.Feedback>
                          </Form.Group>
                        </FormWrapper>
                      )}
                      <FormWrapper>
                        <Form.Group controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            required
                            as={FormInput}
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.password && touched.password}
                          />
                          <Form.Control.Feedback type="invalid"> {errors.password}</Form.Control.Feedback>
                        </Form.Group>
                      </FormWrapper>
                      <FormWrapper>
                        <ServerError> {serverError ?? serverError}</ServerError>
                        {isSuccess && <ServerSucess> Succesfully created new user </ServerSucess>}
                      </FormWrapper>

                      <Button
                        size="lg"
                        variant="primary"
                        type="button"
                        onClick={() => handleCreateUser(values)}
                        disabled={!isValid}
                      >
                        {isProcessing && (
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        )}
                        Create
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Wrapper>
        </ContentCard>
      </Container>
    </>
  );
}

export { CreateWebUser };
