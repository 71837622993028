import { PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledH1 = styled.h3`
  color: white;
  font-weight: 400;
  margin-bottom: 30px;
`;

const StyledHeader = ({ children }: PropsWithChildren<any>) => {
  return (
    <>
      <Container fluid>
        <StyledH1>{children}</StyledH1>
      </Container>
    </>
  );
};

export { StyledHeader as PageHeader };
