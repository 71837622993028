import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Form, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { FormInput } from '../../components/Forms/FormInput';
import { PusnNotification } from '../../types';
import { FaBell } from 'react-icons/fa';
import notificationsService from '../../services/notifications/notificationsService';
import { AuthContext } from '../../services/authService/authProvider';

const FormWrapper = styled.div`
  margin-bottom: 1.5em;
`;
const LeftAlignedButton = styled(Button)`
  float: left;
  background: linear-gradient(139.4deg, #50cef8 19.62%, #337ff1 85%);
  width: 30%;
  border: none;
  height: 38px;
  border-radius: 12px;
`;
const ServerError = styled.p`
  color: red;
`;
const ServerSucess = styled.p`
  color: green;
`;

const styleFa = { color: 'gold' };

type loginFormErrors = {
  Title: boolean;
  Description: boolean;
};

function PushNotifications() {
  let auth = useContext(AuthContext);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState<loginFormErrors>({ Title: false, Description: false });
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [form, setForm] = useState<PusnNotification>({ title: '', description: '' });
  const [validForm, setValidForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const formErrors = validateform() as loginFormErrors;
    if (!Object.values(formErrors).some(val => val === true)) {
      setValidForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (auth) {
      setClientId(auth.clientId);
      if (!auth.clientId) {
        setValidForm(false);
      }
    }
  }, [auth]);

  const validateform = (): loginFormErrors => {
    const newErrors: loginFormErrors = { Title: false, Description: false };
    if (!form.title || !form.description) {
      newErrors.Title = true;
      newErrors.Description = true;
    }
    if (form.title === '' || form.description === '') {
      newErrors.Title = true;
      newErrors.Description = true;
    }
    return newErrors;
  };

  const setField = (field: string, value: string | boolean) => {
    setForm({ ...form, [field]: value });
    setValidForm(false);
    setValidated(false);
    if (errors.hasOwnProperty(field)) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleSubmit = (event: any) => {
    if (clientId) {
      event.preventDefault();
      const formErrors = validateform();

      if (Object.values(formErrors).some(val => val === true)) {
        setErrors(formErrors);
        return;
      }

      setIsProcessing(true);
      notificationsService.sendNotification(form.title, form.description, clientId).then(
        (result: any) => {
          setIsProcessing(false);
          if (result) {
            setForm({ ...form });
            setServerError(undefined);
            return result;
          } else {
            setServerError('Unable to sent push notification');
          }
        },
        (error: any) => {
          setIsProcessing(false);
        }
      );
    }
  };

  return (
    <>
      <Container fluid>
        <h3 style={{ color: '#2D3142', fontSize: '20px', marginBottom: '16px' }}>
          <FaBell style={styleFa} /> Send Push Notifications
        </h3>
        <br></br>
        <Row>
          <Col>
            Push Notifications will be delivered to users that have granted you permission to receive them. All users will see notification in the
            notifications page of the app.
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Form noValidate validated={validated}>
              <FormWrapper>
                <Form.Group controlId="Title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    required
                    as={FormInput}
                    type="text"
                    placeholder="Enter Title"
                    onChange={e => setField('title', e.target.value)}
                    isInvalid={!!errors.Title}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid"> Title is required!</Form.Control.Feedback>
                </Form.Group>
              </FormWrapper>
              <FormWrapper>
                <Form.Group controlId="Description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    required
                    as={FormInput}
                    type="text"
                    placeholder="Write Something..."
                    onChange={e => setField('description', e.target.value)}
                    isInvalid={!!errors.Description}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid"> Description is required!</Form.Control.Feedback>
                </Form.Group>
              </FormWrapper>

              <LeftAlignedButton variant="primary" type="submit" onClick={handleSubmit} disabled={isProcessing || !validForm}>
                Send
                {isProcessing && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </LeftAlignedButton>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {serverError && <ServerError> {serverError}</ServerError>}
            {validated && <ServerSucess> Succesfully sent push notification </ServerSucess>}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { PushNotifications };
