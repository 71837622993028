import { format } from 'date-fns';
import {
  Body,
  Cell,
  Data,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
  TableNode,
} from '@table-library/react-table-library/table';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
import styled from 'styled-components';
import { Button, Container, InputGroup, Row as BRow, Col, Form } from 'react-bootstrap';
import { LoadingSpinnerComponent } from '../../../Loading';
import { FaCheck, FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';

const StyledHeaderCell = styled(HeaderCell)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledRow = styled(Row)`
  cursor: pointer;
  &:focus {
    background-color: white;
    color: black;
  }
  &:hover {
    background-color: #4c5980;
    color: black;
  }
`;

const StyledGroupText = styled(InputGroup.Text)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
`;
const StyledGroupButton = styled(Button)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: #4c5980;
  padding: 5px;
  &.btn:disabled {
    background-color: transparent;
    color: #4c5980;
  }
`;

export default function NotificationList({
  tableLoaded,
  tableData,
  perPage,
  setPerPage,
}: {
  tableLoaded: boolean;
  tableData: Data<any>;
  perPage: number;
  sendView: boolean;
  setSendView: React.Dispatch<React.SetStateAction<boolean>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
}) {
  const theme = useTheme({
    Row: `
    cursor: pointer;
    color: rgb(117, 117, 117);
    &:hover {
      color: black;
    }
    &:hover .td {
      background-color:#f8f9fa;
    }
    `,
  });

  const pagination = usePagination(tableData, {
    state: {
      page: 0,
      size: perPage,
    },
  });

  return (
    <>
      {!tableLoaded && <LoadingSpinnerComponent />}

      {tableLoaded && tableData.nodes.length === 0 && (
        <Container>
          <BRow className="justify-content-md-center">
            <Col md="auto">
              <span>Data not available</span>
            </Col>
          </BRow>
        </Container>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <>
          <Table data={tableData} pagination={pagination} theme={theme}>
            {(tableList: TableNode[]) => (
              <>
                <Header>
                  <HeaderRow>
                    <StyledHeaderCell>Title</StyledHeaderCell>
                    <StyledHeaderCell>Message</StyledHeaderCell>
                    <StyledHeaderCell>Date</StyledHeaderCell>
                    <StyledHeaderCell>Read</StyledHeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map((item: TableNode, key: number) => (
                    <StyledRow item={item} key={key}>
                      <Cell>{item.title}</Cell>
                      <Cell>{item.message}</Cell>
                      <Cell>{format(new Date(item.createdOn), 'dd/MM/yy')}</Cell>
                      <Cell>{item.notificationStatus === 2 ? <FaCheck /> : <>-</>}</Cell>
                    </StyledRow>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}

      {tableLoaded && tableData.nodes.length !== 0 && (
        <BRow style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            {tableData.nodes.length} items in total
          </Col>
          <Col md={9} xs={7}>
            <InputGroup className="justify-content-end">
              <div style={{ width: '270px' }}>
                <Form.Select
                  defaultValue={perPage}
                  style={{ outline: 'none', boxShadow: 'none', border: 'none' }}
                  onChange={e => setPerPage(Number(e.target.value))}
                >
                  <option value="10">Showing 10 items per page</option>
                  <option value="20">Showing 20 items per page</option>
                  <option value="50">Showing 50 items per page</option>
                  <option value="100">Showing 100 items per page</option>
                </Form.Select>
              </div>
              <StyledGroupButton disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                <FaStepBackward />
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page === 0}
                onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
              >
                <FaPlay style={{ transform: 'rotate(180deg)' }} />
              </StyledGroupButton>
              <StyledGroupText>
                {pagination.state.getPageBoundaries(tableData.nodes).start}
                {'-'}
                {pagination.state.getPageBoundaries(tableData.nodes).end}
              </StyledGroupText>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
              >
                <FaPlay></FaPlay>
              </StyledGroupButton>
              <StyledGroupButton
                disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(tableData.nodes) - 1)}
              >
                <FaStepForward />
              </StyledGroupButton>
            </InputGroup>
          </Col>
        </BRow>
      )}
    </>
  );
}
