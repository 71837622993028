import { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Client, isValidSendGridKey, type ClientInfoType } from '../../../../types';

import { AuthContext } from '../../../../services/authService/authProvider';

import DefaultEmail from './DefaultEmail';
import SendgridEmail from './SendgridEmail';
import { useParams } from 'react-router-dom';
import adminService from '../../../../services/adminService/adminService';
import { LoadingSpinnerComponent } from '../../../../components/Loading';

export default function Email() {
  const auth = useContext(AuthContext);
  const params = useParams();

  const [sendgridApiEnabled, setSendgridApiEnabled] = useState<boolean>(false);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (params.id) {
      setLoading(true);
      adminService.getClientDetails(params.id, params.id).then((rs: Client | undefined) => {
        setClient(rs);
        setSendgridApiEnabled(rs?.sendgridApiEnabled ?? false)
        setLoading(false);
      });
    }
  }, [params.id]);

  return (
    <div className="client-settings">
      <h1 className="title">Email Settings</h1>
      {loading && <LoadingSpinnerComponent />}
      {
        !loading &&
        <div style={{ marginBottom: '100px' }}>
                  <Form.Group controlId="sendgridApiEnabled" style={{ marginBottom: '40px' }}>
                  <Form.Check // prettier-ignore
                      type="switch"
                      id="sendgridApiEnabled"
                      checked={!sendgridApiEnabled}
                      label={ 'Use default Centrex Sendgrid settings'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSendgridApiEnabled(!sendgridApiEnabled)
                      }}
                  />
                </Form.Group>
                { sendgridApiEnabled &&
                  <SendgridEmail client={client} />
                }
                { !sendgridApiEnabled &&
                  <DefaultEmail client={client} />
                }
        </div>
      }
    </div>
  );
}



