import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../services/authService/authProvider';
import paymentMethodService from '../../services/paymentMethod/paymentMethodService';
import { CardStored } from '../../types';
import stripeService from '../../services/stripeService/stripeService';
import { LoadingSpinnerComponent } from '../../components/Loading';
import { AddPaymentMethod } from './AddpaymentMethod';
import PaymentCardItem from './PaymentCardItem';

interface PaymentMethodProps {
  selectView: string;
  setSelectView: React.Dispatch<React.SetStateAction<string>>;
}

function PaymentMethodTable({ selectView, setSelectView }: PaymentMethodProps) {
  const auth = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [data, setData] = useState<CardStored[]>([]);

  useEffect(() => {
    setLoading(true);

    if (auth.clientId) {
      stripeService.searchCustomer(auth.clientId).then(result => {
        if (result) {
          setCustomerId(result);
        } else {
          setLoading(false);
          setCustomerId('');
          setData([]);
        }
      });
    } else {
      setLoading(false);
      setCustomerId('');
      setData([]);
    }
  }, [auth.clientId]);

  useEffect(() => {
    if (customerId !== '') {
      paymentMethodService.getCustomerMethods(customerId).then(result => {
        if (result) {
          setDefaultPaymentMethod(result?.toString());
        }
      });
    } else {
      setCustomerId('');
      setData([]);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId !== '') {
      paymentMethodService.getPayemntMethods(customerId).then(result => {
        setLoading(false);

        if (result && result.length !== 0) {
          setData(result);
        } else {
          setData([]);
        }
      });
    } else {
      setData([]);
    }
  }, [customerId]);

  return (
    <>
      <div className="payment-status-row">
        {selectView === 'Add Payment' && <p className="txt-status mb-0">Add payment details</p>}

        {!loading && data.length === 0 && selectView === 'Manage Payment' ? (
          <p className="txt-status mb-0">No payment methods have been added yet. Please add.</p>
        ) : (
          <>{selectView === 'Manage Payment' && <div></div>}</>
        )}

        <Button size="sm" onClick={() => setSelectView('Add Payment')} disabled={selectView === 'Add Payment'}>
          Add payment method
        </Button>
      </div>
      <div className="mb-4">
        {selectView === 'Manage Payment' ? (
          <>
            {loading ? (
              <LoadingSpinnerComponent />
            ) : (
              <>
                <div className="payment-card-content">
                  {data.length !== 0 &&
                    customerId &&
                    data.map((rs: CardStored, key: number) => (
                      <PaymentCardItem cardInfo={rs} key={key} defaultPaymentMethod={defaultPaymentMethod} />
                    ))}
                </div>
              </>
            )}
          </>
        ) : (
          <AddPaymentMethod />
        )}
      </div>
    </>
  );
}

export { PaymentMethodTable };
