import React, { createRef, useImperativeHandle } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikProps, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { Client, EmailRegex, NoWhiteSpaceRegex } from '../../../types';
import { FormInput } from '../../../components/Forms/FormInput';

interface ClientInfoProps {
  client?: Client | undefined;
}

const ClientInfo = React.forwardRef(({ client }: ClientInfoProps, ref) => {
  const formRef = createRef<FormikProps<FormikValues>>();

  const schema = yup.object().shape({
    clientName: yup.string().required('Client Name is required'),
    subdomain: yup.string().matches(NoWhiteSpaceRegex, 'Please type a valid subdomain witouth whitespaces').required('Subdomain is required'),
    billingEmail: yup.string().matches(EmailRegex, 'Please type a valid email address').required('Client billing email is required'),
    phone: yup.string().nullable().optional(),
    address: yup.string().nullable().optional(),
    website: yup.string().nullable().optional(),
    appStoreLink: yup.string().nullable().optional(),
    playStoreLink: yup.string().nullable().optional(),
  });

  const initialValues = {
    clientName: client?.clientName || '',
    subdomain: client?.subdomain || '',
    billingEmail: client?.billingEmail || '',
    phone: client?.phone || '',
    address: client?.address || '',
    website: client?.website || '',
    appStoreLink: client?.appStoreLink || '',
    playStoreLink: client?.playStoreLink || '',
  };

  const validateForm = () => {
    if (formRef?.current) {
      formRef?.current?.submitForm();

      return formRef?.current?.isValid;
    }

    return false;
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return validateForm();
    },
    getValues() {
      if (formRef?.current) {
        return formRef.current?.values;
      }

      return null;
    },
  }));

  return (
    <Row>
      <Col md={6}>
        <Formik
          innerRef={formRef}
          validationSchema={schema}
          onSubmit={() => {}}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
        >
          {({ handleChange, values, touched, errors }) => (
            <Form noValidate>
              <Form.Group controlId="clientName" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Client Name</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.clientName}
                  onChange={handleChange}
                  isInvalid={touched.clientName && !!errors.clientName}
                />
                <Form.Control.Feedback type="invalid">{typeof errors['clientName'] === 'string' ? errors['clientName'] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="subdomain" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Short Name / Subdomain</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.subdomain}
                  onChange={handleChange}
                  isInvalid={touched.subdomain && !!errors.subdomain}
                />
                <Form.Control.Feedback type="invalid">{typeof errors['subdomain'] === 'string' ? errors['subdomain'] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="address" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Address</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                  isInvalid={touched.address && !!errors.address}
                />
                <Form.Control.Feedback type="invalid">{typeof errors['address'] === 'string' ? errors['address'] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phone" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Phone Number</Form.Label>
                <Form.Control as={FormInput} type="text" value={values.phone} onChange={handleChange} isInvalid={touched.phone && !!errors.phone} />
                <Form.Control.Feedback type="invalid">{typeof errors['phone'] === 'string' ? errors['phone'] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="billingEmail" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Billing Email</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.billingEmail}
                  onChange={handleChange}
                  isInvalid={touched.billingEmail && !!errors.billingEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['billingEmail'] === 'string' ? errors['billingEmail'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="website" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Website</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.website}
                  onChange={handleChange}
                  isInvalid={touched.website && !!errors.website}
                />
                <Form.Control.Feedback type="invalid">{typeof errors['website'] === 'string' ? errors['website'] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="appStoreLink" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Apple App Store Link</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.appStoreLink}
                  onChange={handleChange}
                  isInvalid={touched.appStoreLink && !!errors.appStoreLink}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['appStoreLink'] === 'string' ? errors['appStoreLink'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="playStoreLink" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Android Playstore Link</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.playStoreLink}
                  onChange={handleChange}
                  isInvalid={touched.playStoreLink && !!errors.playStoreLink}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['playStoreLink'] === 'string' ? errors['playStoreLink'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={6}></Col>
    </Row>
  );
});

ClientInfo.displayName = 'ClientInfo';
export default ClientInfo;
