import Steps from '@uiw/react-steps';
import { format } from 'date-fns';
import { RecentActivityType, capitalize } from '../../types';

const RecentActivity = ({ activity }: { activity: RecentActivityType[] }) => {


  const formatDate = (dateString: string) : string => {
    const parsedDate = new Date(dateString);
    const today = new Date();
    let formatStr = 'MM/dd/yy';
    if(parsedDate.getFullYear() === today.getFullYear() && 
    parsedDate.getMonth() === today.getMonth() && 
    parsedDate.getDate() === today.getDate()){
      formatStr = 'MM/dd/yy hh:mm a';
    }
    return format(parsedDate, formatStr);
  }

  return (
    <Steps direction="vertical">
      {activity?.map((item: RecentActivityType, key: number) => {
        return (
          <Steps.Step
            key={key}
            title={
              <>
                <div className="stepItem">
                  <p className="txt1" style={{ width: '70%' }}>{capitalize(item.eventType)}</p>
                  <p className="txt3" style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                    {formatDate(item.date)}
                  </p>
                </div>
              </>
            }
            description={
              <div className="stepItem">
                <div className="txt2" style={{ width: '70%' }}>
                  {item.description}
                </div>
              </div>
            }
          />
        );
      })}
    </Steps>
  );
};

export default RecentActivity;
