import { PropsWithChildren, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import useInterval from '../../lib/useInterval';

type ContentProps = {
  show: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
  title?: string;
  text?: string;
  defaultIntevalNum: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  intervalDelay: number;
  setIntervalDelay: React.Dispatch<React.SetStateAction<number>>;
};

const AlertComponent = ({
  show,
  variant,
  title,
  text,
  defaultIntevalNum,
  setShow,
  intervalDelay,
  setIntervalDelay,
}: PropsWithChildren<ContentProps>) => {
  const [increment, setIncrement] = useState<number>(0);

  const countDown = () => {
    if (increment >= defaultIntevalNum) {
      setShow(false);
      setIntervalDelay(-1);
      setIncrement(0);
    } else {
      setIncrement(preInc => preInc + 1);
    }
  };

  useInterval(() => {
    countDown();
  }, intervalDelay);

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: '10000',
        display: show ? 'flex' : 'none',
        justifyContent: 'center',
      }}
    >
      <Alert variant={variant} show={show} style={{ height: '60px', marginTop: '50px' }}>
        {title && <Alert.Heading>{title}</Alert.Heading>}

        <p className="mb-0">{text}</p>
      </Alert>
    </div>
  );
};

export default AlertComponent;
