import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormInput } from '../../../../components/Forms/FormInput';
import { useState } from 'react';
import transactionsService from '../../../../services/transactionsService/transactionsService';
import { useAlertComponent } from '../../../Alert/AlertProvider';

export default function SendNotification({
  contactId,
  setSendView,
}: {
  contactId: string;
  setSendView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();

  const [loading, setLoading] = useState<boolean>(false);

  const schema = yup.object().shape({
    title: yup.string().required('Title is required'),
    message: yup.string().required('Message is required'),
  });

  const initialValues = {
    title: '',
    message: '',
  };

  const handlerSubmit = async (data: { title: string; message: string }) => {
    setLoading(true);
    const res = await transactionsService.sendClientNotification({ ...data, contactId });

    if (res && res?.status === 200) {
      setAlertText('Succesfully send notification.');
      setVariant('success');
      setAlert(true);
      setIntervalDelay(1000);

      setSendView(false);
    } else {
      setAlertText('Unable to send notification.');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }

    setLoading(false);
  };

  return (
    <div className="client-settings">
      <div style={{ marginBottom: '100px' }}>
        <Formik
          validationSchema={schema}
          onSubmit={handlerSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="title" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Title</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                  isInvalid={touched.title && !!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['title'] === 'string' ? errors['title'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="message" style={{ marginBottom: '24px' }}>
                <Form.Label className="label">Message</Form.Label>
                <Form.Control
                  as={FormInput}
                  type="text"
                  value={values.message}
                  onChange={handleChange}
                  isInvalid={touched.message && !!errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {typeof errors['message'] === 'string' ? errors['message'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="btn-backround" type="submit" style={{ float: 'right' }}>
                <span>Submit &nbsp;</span>
                {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
              <Button
                variant="secondary"
                style={{ float: 'right', marginRight: '15px' }}
                onClick={() => setSendView(false)}
              >
                <span>Back</span>
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
