import { Container, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BreadCrumbItem, UserData } from '../../types';
import { ChangeEvent, useContext } from 'react';
import { AssetRequestHandle } from '../BankAccounts/AssetReports';
import { AccountContext } from '../../services/accountService/accountsProvider';

type BreadCrumbProps = {
  items: Array<BreadCrumbItem>;
  setCanRequestAssetReport: React.Dispatch<React.SetStateAction<boolean>>;
  requestedAssetReport?: boolean;
  canRequestAssetReport?: boolean;
  loginRequired?: any;
  index?: string;
  childCompRef?: React.RefObject<AssetRequestHandle>;
  userDetails?: UserData | undefined;
};

const StyledBreadcrumbItem = styled.a`
  color: white;
  font-weight: 400;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  margin-right: 0.5em;
`;

const LastStyledBreadcrumbItem = styled.a`
  color: white;
  font-weight: 400;
  font-size: 1.5rem;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledItem = styled.div`
  margin-right: 0.5em;
`;

function CentrexBreadcrumb({
  items,
  setCanRequestAssetReport,
  requestedAssetReport,
  canRequestAssetReport,
  loginRequired,
  index,
  childCompRef,
  userDetails,
}: BreadCrumbProps) {
  const params = useParams();
  const navigate = useNavigate();
  const accountContext = useContext(AccountContext);

  const handleAction = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'account') {
      navigate(`/users/${params.id}/bank-accounts`);
    }

    if (e.target.value === 'report') {
      if (childCompRef) {
        childCompRef.current?.request();
        setCanRequestAssetReport(false);
      }
    }
  };

  return (
    <Container fluid>
      <StyledWrapper>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
          {items.map((i, index) => (
            <StyledItem key={index}>
              <h3>
                {index + 1 !== items.length ? (
                  <>
                    <StyledBreadcrumbItem onClick={() => navigate(i.nav)}>{i.text} </StyledBreadcrumbItem>{' '}
                    <StyledBreadcrumbItem> / </StyledBreadcrumbItem>
                  </>
                ) : (
                  <>
                    <LastStyledBreadcrumbItem onClick={() => navigate(i.nav)}>{i.text} </LastStyledBreadcrumbItem>
                  </>
                )}
              </h3>
            </StyledItem>
          ))}
        </div>

        {index === 'user_detail' && userDetails?.userStatus === 4 && (
          <div style={{ width: '230px' }}>
            <Form.Select style={{ height: '44px' }} defaultValue="" onChange={handleAction}>
              <option value="" disabled>
                Action
              </option>
              <option value="account">Manage accounts</option>
              <option
                value="report"
                disabled={(!requestedAssetReport && (!accountContext.hasAccounts || !canRequestAssetReport)) || loginRequired}
              >
                Request Asset Report
              </option>
            </Form.Select>
          </div>
        )}
      </StyledWrapper>
    </Container>
  );
}

export { CentrexBreadcrumb };
