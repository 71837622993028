import { PropsWithChildren } from 'react'
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

type ContentProps = {
    type?:string,
}

const SuccessBadge = styled(Badge)`
    background-color: rgba(44, 209, 156, 0.1) !important;
    color:#2CD19C;
`
const AlertBadge = styled(Badge)`
    background: #FFFF00 !important;
    color:rgba(44, 44, 44, 0.8);
`
const DangerBadge = styled(Badge)`
    background: rgba(215, 80, 83, 0.1) !important;
    color:#D75053;
`
const DefaultBadge = styled(Badge)`
    background: rgba(104, 109, 107, 0.1) !important;
    color:#4C5980;
`

const CustomBadge = (props: any) =>{
    const {id, ...newprops} = props;
    const {type } = props;
    switch(type){
        case "alert":
            return <AlertBadge {...newprops} />
        case "success":
            return <SuccessBadge {...newprops} />
        case "danger":
            return <DangerBadge {...newprops} />
        default:
            return <DefaultBadge {...newprops} />
    }    
}


const StatusBadge = ({ type, children } : PropsWithChildren<ContentProps>) => { 
    return (
        <>
            <CustomBadge type={type}>
                {children}
            </CustomBadge>
        </>
    )
}
 
export { StatusBadge };