import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ContentCard } from '../../../components/Card';
import { FormInput } from '../../../components/Forms/FormInput';
import adminService from '../../../services/adminService/adminService';
import { EmailRegex, type WebUser } from '../../../types';

import { FaRegUser } from 'react-icons/fa';
import { LoadingSpinnerComponent } from '../../../components/Loading';
import { useAlertComponent } from '../../../components/Alert/AlertProvider';

export default function EditProfile() {
  const param = useParams();
  const navigate = useNavigate();
  const { setAlert, setAlertText, setIntervalDelay, setVariant } = useAlertComponent();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const [user, setUser] = useState<WebUser | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageFile, setImageFile] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const schema = yup.object().shape({
    Email: yup.string().matches(EmailRegex, 'Please type a valid email address').required('Email is required'),
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await adminService.getUserDetails(param?.id as string);
        setUser(res);
        setImageUrl(`${process.env.REACT_APP_STORAGEURL}/web-profile-images/${res?.profileImage as string}`);
      } catch (error) {}

      setLoading(false);
    })();
  }, [param]);

  const initialValues = {
    FirstName: user?.firstName || '',
    LastName: user?.lastName || '',
    Email: user?.id || '',
    profileImage: `${process.env.REACT_APP_STORAGEURL}/web-profile-images/${user?.profileImage as string}` || undefined,
  };

  const handleFileBrowser = () => {
    fileRef?.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = [];
    if (e.target.files) {
      if (
        e.target.files[0]?.type !== 'image/jpeg' &&
        e.target.files[0]?.type !== 'image/png' &&
        e.target.files[0]?.type !== 'image/jpg'
      ) {
        return;
      }

      files.push(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
        setImageFile(files);
      };
    }
  };

  const handleSubmit = async (data: { FirstName: string; LastName: string; Email: string }) => {
    try {
      setLoadingUpdate(true);
      const response = await adminService.updateUserProfile(data.FirstName, data.LastName, data.Email, imageFile);
      setLoadingUpdate(false);

      if (response && response?.status === 200) {
        setAlertText('Succesfully update profile');
        setVariant('success');
        setAlert(true);
        setIntervalDelay(1000);
      } else {
        setAlertText('Unable to update profile');
        setVariant('danger');
        setAlert(true);
        setIntervalDelay(1000);
      }
    } catch (error) {
      setAlertText('Unable to update profile');
      setVariant('danger');
      setAlert(true);
      setIntervalDelay(1000);
    }
  };

  return (
    <Container fluid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
        <h3 style={{ color: 'white' }}>Edit Profile</h3>
      </div>
      <ContentCard>
        <Container style={{ height: '653px' }}>
          <div className="client-settings">
            <h1 className="title">Profile Image</h1>

            {loading ? (
              <>
                <div style={{ height: '200px' }}></div>
                <LoadingSpinnerComponent />
              </>
            ) : (
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validateOnMount={true}
                validateOnChange={true}
                enableReinitialize
              >
                {({ handleChange, handleSubmit, values }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="row-item" style={{ marginBottom: '40px' }}>
                      <Form.Group controlId="profileImage">
                        <input
                          ref={fileRef}
                          type="file"
                          style={{ display: 'none' }}
                          accept=".png, .jpeg, .jpg"
                          onChange={handleFileChange}
                        />
                        <div className="profile-user-info" style={{ paddingBottom: 0, borderBottom: 'none' }}>
                          <div className="profile-avatar" onClick={handleFileBrowser} style={{ cursor: 'pointer' }}>
                            {imageUrl && imageUrl !== '' ? (
                              <img src={imageUrl} alt="" width={97} height={97} style={{ borderRadius: '100%' }} />
                            ) : (
                              <FaRegUser style={{ fontSize: '55px' }} />
                            )}
                          </div>
                        </div>
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </Form.Group>
                      <div className="logo-info">
                        <p className="txt-1" style={{ marginBottom: '11px' }}>
                          Upload Avatar Image
                        </p>
                        <p className="txt-2" style={{ marginBottom: '3px' }}>
                          Supported Format
                        </p>
                        <p className="txt-3" style={{ marginBottom: '0' }}>
                          PNG and JPG/JPEG
                        </p>
                      </div>
                    </div>
                    <h1 className="title">Personal Details</h1>
                    <Form.Group controlId="FirstName" style={{ marginBottom: '24px' }}>
                      <Form.Label className="label">First Name</Form.Label>
                      <Form.Control as={FormInput} type="text" value={values.FirstName} onChange={handleChange} />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="LastName" style={{ marginBottom: '24px' }}>
                      <Form.Label className="label">Last Name</Form.Label>
                      <Form.Control as={FormInput} type="text" value={values.LastName} onChange={handleChange} />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="Email" style={{ marginBottom: '24px' }}>
                      <Form.Label className="label">Email</Form.Label>
                      <Form.Control as={FormInput} type="text" value={values.Email} onChange={handleChange} readOnly />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-center" style={{ padding: '40px 0' }}>
                      <Button variant="outline-secondary" onClick={() => navigate(-1)}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{ marginLeft: '40px' }}>
                        <span>Update &nbsp;</span>
                        {loadingUpdate && (
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Container>
      </ContentCard>
    </Container>
  );
}
