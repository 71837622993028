import { createContext, useContext, useMemo, useState } from 'react';
import AlertComponent from '.';

type Context = {
  setAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertText: React.Dispatch<React.SetStateAction<string>>;
  setIntervalDelay: React.Dispatch<React.SetStateAction<number>>;
  setVariant: React.Dispatch<
    React.SetStateAction<
      'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | undefined
    >
  >;
};

const AlertContext = createContext<Context | null>(null);

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [alert, setAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [intervalDelay, setIntervalDelay] = useState<number>(-1);
  const [variant, setVariant] = useState<
    'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | undefined
  >();

  const values = useMemo(
    () => ({
      setAlert,
      setAlertText,
      setIntervalDelay,
      setVariant,
    }),
    [setAlert, setAlertText, setIntervalDelay, setVariant]
  );

  return (
    <AlertContext.Provider value={values}>
      <>
        <AlertComponent
          show={alert}
          variant={variant}
          text={alertText}
          setShow={setAlert}
          defaultIntevalNum={3}
          intervalDelay={intervalDelay}
          setIntervalDelay={setIntervalDelay}
        />
        {children}
      </>
    </AlertContext.Provider>
  );
}

export function useAlertComponent(): Context {
  const context = useContext(AlertContext);

  if (context === null) {
    throw new Error('useAlertComponent must be used within an AlertProvider');
  }

  return context;
}
