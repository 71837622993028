import { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { FaFileInvoice, FaAlignLeft, FaChartBar, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../services/authService/authProvider';

const StyledNav = styled(Nav)`
  display: flex;
  flex-direction: column;
`;
const CanvasNav = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledNav {...newprops} />;
};

function StyledLeftNav() {
  let auth = useContext(AuthContext);
  let navigate = useNavigate();
  <style type="text/css">
    {`
                .nav-link {
                    color:blue; `}
  </style>;
  return (
    <>
      <CanvasNav defaultActiveKey="/">
        <Nav.Link onClick={e => navigate('/', { replace: true })}>
          <FaChartBar /> Dashboard
        </Nav.Link>
        <Nav.Link onClick={e => navigate('/users')}>
          <FaUsers /> Users
        </Nav.Link>
        <Nav.Link onClick={e => navigate('/billing')}>
          <FaFileInvoice /> Billing{' '}
        </Nav.Link>
        {auth.isAdmin && (
          <Nav.Link onClick={e => navigate('/admin')} eventKey="link-2">
            <FaAlignLeft /> Admin
          </Nav.Link>
        )}
      </CanvasNav>
    </>
  );
}

export { StyledLeftNav as OffCanvasNav };
