import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AuthContext } from '../../services/authService/authProvider';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, ChartData, Legend, Tooltip } from 'chart.js';
import adminService from '../../services/adminService/adminService';

Chart.register(Legend, ArcElement, Tooltip);

const FixedDiv = styled.div`
  width: 100%;
  height: 250px;
`;

function UsersSales() {
  const auth = React.useContext(AuthContext);
  const [chartData, setChartData] = React.useState<ChartData<'doughnut', number[], unknown>>();

  const getTotal = (a: number, b: Array<number>) => {
    let result = a;
    b.forEach(num => {
      result = result - num;
    });
    return result < 0 ? 0 : result;
  };

  React.useEffect(() => {
    const user = auth.getUser();

    if (user && auth.clientId) {
      adminService.getLatestUsersStats(auth.clientId).then(stats => {
        if (stats) {
          const data: ChartData<'doughnut', number[], unknown> = {
            labels: [
              `This Year: ${stats?.thisYear}`,
              `Last Month: ${stats?.lastMonth}`,
              `Over Last Week: ${stats?.lastWeek}`,
              `Over Today: ${stats?.today}`,
            ],
            datasets: [
              {
                label: 'My First Dataset',
                data: [
                  getTotal(stats.thisYear, [stats.lastMonth, stats.lastWeek, stats.today]) ?? 0,
                  getTotal(stats.lastMonth, [stats.lastWeek, stats.today]) ?? 0,
                  getTotal(stats.lastWeek, [stats.today]) ?? 0,
                  stats.today ?? 0,
                ],
                backgroundColor: ['#10BCD2', '#337FF1', '#F6B100', '#F03D32'],
                hoverOffset: 4,
              },
            ],
          };
          setChartData(data);
        }
      });
    }
  }, [auth]);

  return (
    <>
      <Container fluid>
        <Row>
          <FixedDiv>
            {chartData && (
              <Doughnut
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'left',
                      align: 'start',
                      labels: {
                        pointStyle: 'circle',
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            )}
          </FixedDiv>
        </Row>
      </Container>
    </>
  );
}

export { UsersSales };
