import { Dashboard } from './containers/Dashboard';
import { Login } from './containers/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './services/authService/authProvider';
import { RequireAuth } from './services/authService/requireAuth';
import { NotFound } from './containers/NotFound';
import { UsersList } from './containers/Users';
import  UserDetails  from './containers/Users/userDetails';
import { AdminPage } from './containers/Admin';
import { CreateWebUser } from './containers/Admin/addWebUser';
import EditClient from './containers/Clients/edit/EditClient';
import { Layout } from './components/Layout';
import { ClientUsage } from './containers/Usage';
import PlaidLinkWithOAuth from './containers/PlaidLink/plaidLink';
import { UserBankAccounts } from './containers/Users/userBankAccounts';
import Settings from './containers/Settings';
import useScript from './lib/useScript';
import NotificationSend from './containers/Users/NotificationSend';
import MyProfile from './containers/Users/myprofile/MyProfile';
import EditProfile from './containers/Users/myprofile/EditProfile';
import { LoginForm } from './containers/Login/LoginForm';
import SendResetEmail from './containers/Login/forgot/SendResetEmail';
import SendEmailSuccess from './containers/Login/forgot/SendEmailSuccess';
import ResetPassword from './containers/Login/forgot/ResetPassword';
import { AlertProvider } from './components/Alert/AlertProvider';
import TwoFactorAuth from './containers/Login/2fa/TwoFactorAuth';
import VerifyPhone from './containers/Account/VerifyPhone';
import CreateClient from './containers/Clients/create/CreateClient';

function App() {

  return (
    <>
      <AuthProvider>
        <AlertProvider>
          <Routes>
            <Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
              <Route index element={<RequireAuth><Dashboard /></RequireAuth>} />
              <Route path="/users" element={<RequireAuth><UsersList /></RequireAuth>} />
              <Route path="/users/:id" element={<RequireAuth><UserDetails /></RequireAuth>} />
              <Route path="/users/:id/notification-send" element={<RequireAuth><NotificationSend /></RequireAuth>} />
              <Route path="/users/:id/bank-accounts" element={<RequireAuth><UserBankAccounts /></RequireAuth>} />
              <Route path="/billing" element={<RequireAuth><ClientUsage /></RequireAuth>} />
              <Route path="/admin" element={<RequireAuth><AdminPage /></RequireAuth>} />
              <Route path="/admin/create-webuser" element={<RequireAuth><CreateWebUser /></RequireAuth>} />
              <Route path="/admin/clients/:id" element={<RequireAuth><EditClient /></RequireAuth>} />
              <Route path="/admin/clients/create" element={<RequireAuth><CreateClient /></RequireAuth>} />
              <Route path="/plaid/link" element={<RequireAuth><PlaidLinkWithOAuth /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
              <Route path="/account/verify-phone" element={<RequireAuth><VerifyPhone /></RequireAuth>} />
              <Route path="/myprofile" element={<RequireAuth><MyProfile /></RequireAuth>} />
              <Route path="/myprofile/:id" element={<RequireAuth><EditProfile /></RequireAuth>} />
              <Route path="*" element={<RequireAuth><NotFound /></RequireAuth>} />
            </Route>
            <Route path="/auth" element={<Login />}>
              <Route path="login" element={<LoginForm />} />
              <Route path="reset-email" element={<SendResetEmail />} />
              <Route path="email-success" element={<SendEmailSuccess />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="verify-phone" element={<VerifyPhone />} /> 
              <Route path="2fa" element={<TwoFactorAuth />} />
            </Route>
          </Routes>
        </AlertProvider>
      </AuthProvider>
    </>
  );
}

export default App;
