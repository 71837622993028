import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';

type ProfileImageProps = {
  sizePx: number;
  url: string;
  urlPrefix?: boolean;
};

const storageUrl = process.env.REACT_APP_STORAGEURL;
const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledProfileImage = styled(Image)`
  width: 100%;
  max-width: ${props => props.width}px;
  min-width: ${props => props.width}px;
  height: auto;
`;

const ProfileImage = ({ sizePx, url, urlPrefix }: ProfileImageProps) => {
  return (
    <>
      <CenteredDiv>
        {url && (
          <StyledProfileImage
            src={urlPrefix ? `${storageUrl}/web-profile-images/${url}` : `${storageUrl}/profile-images/${url}`}
            width={sizePx}
          ></StyledProfileImage>
        )}

        {!url && <FaUserCircle size={sizePx + 'px'} color={'#858796'}></FaUserCircle>}
      </CenteredDiv>
    </>
  );
};

export { ProfileImage };
