/* eslint-disable react-hooks/exhaustive-deps */

import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import transactionsService from '../../services/transactionsService/transactionsService';
import { AccountContext } from '../../services/accountService/accountsProvider';
import { AssetReport } from '../../types';
import { useParams } from 'react-router-dom';
import plaidService from '../../services/plaidService/plaidService';
import { FaRegFilePdf } from 'react-icons/fa';
import { ContentCard } from '../Card';

export type AssetRequestHandle = {
  request: () => void;
};

type UserAssetReportsProps = {
  onLoaded: (canRequest: boolean) => void;
  onRequested: () => void;
};

const UserAssetReports = forwardRef<AssetRequestHandle, UserAssetReportsProps>((props, ref) => {
  const accountContext = useContext(AccountContext);
  const params = useParams();
  const [assetReport, SetAssetReport] = useState<AssetReport | undefined>(undefined);
  const [isLoading, setisLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    request() {
      console.log('Creating asset Report');
      props.onLoaded(false);
      handleCreateReport();
    },
  }));

  const fetchData = useCallback(() => {
    if (params.id) {
      setisLoading(true);

      transactionsService.getAssetReports(params.id, true).then(result => {
        if (result && result.length > 0) {
          const item = result?.sort((a: any, b: any) => b.requestDate.localeCompare(a.requestDate))[0];
          SetAssetReport(item);
          const today = new Date();
          const date = new Date(Date.parse(item.requestDate));

          if (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
          ) {
            props.onLoaded(false);
          }
        }
        setisLoading(false);
      });
    }
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateReport = () => {
    if (accountContext.userId && accountContext.accounts && accountContext.accounts.length > 0) {
      transactionsService.createAssetReport(accountContext.userId).then(
        () => {
          let item = {
            clientId: '',
            userId: '',
            contactId: '',
            assetReportToken: '',
            assetReportId: '',
            id: '',
            number: 1,
            requestDate: new Date().toISOString(),
            status: 0,
          };

          SetAssetReport(item);
          setTimeout(() => fetchData(), 5000);
        },
        error => {
          if (accountContext.loginRequired) {
            console.log('Cant request asset report! user login is required');
          }
        }
      );
    } else {
      props.onLoaded(false);
      console.log('Cant request asset report!');
    }
  };

  const handleOpenReportPdf = (assetReportToken: string, requestDate: string) => {
    plaidService.getAssetReportPdf(assetReportToken).then(a => {
      if (a) {
        const report = new Blob([a], {
          type: 'application/pdf',
        });

        const reportUrl = URL.createObjectURL(report);
        window.open(reportUrl);
      }
    });
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'Requested';
      case 1:
        return 'Ready';
    }
  };

  return (
    <>
      {!isLoading && !assetReport && <></>}
      {!isLoading && assetReport && (
        <ContentCard>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3 style={{ fontSize: '24px' }}>Asset Report</h3>
            <div style={{ color: '#2D3142' }}>{format(new Date(assetReport.requestDate), 'dd/MM/yy')}</div>
            <div>
              <>
                {assetReport.status === 0 ? (
                  <div>{getStatus(assetReport.status)}</div>
                ) : (
                  <>
                    <div>
                      <Button
                        onClick={() => handleOpenReportPdf(assetReport.assetReportToken, assetReport.requestDate)}
                      >
                        <FaRegFilePdf />
                      </Button>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </ContentCard>
      )}
    </>
  );
});

export { UserAssetReports };
