import { PropsWithChildren } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import styled from 'styled-components';

type ContentProps = {
  items?: Array<any>;
  selectedItem?: any;
  placeholder?: string;
  labelKey: string;
  valueKey: string;
  onSelect: (item: any) => void;
  onBlur: (item: any) => void;
  onChange: (item: any) => void;
  controlId?: string;
};

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;
const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border: 1px solid #ced4da;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 5px 0;
  box-shadow: none !important;
  color: black;
  background-color: white;
  &:focus {
    background-color: white;
    color: black;
  }
  &:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: 0 none;
    color: blue;
    background-color: white;
  }
  &:disabled {
    color: black;
    background-color: white;
  }
  &.btn:disabled {
    border-color: none;
  }
  &.is-invalid {
    color: red;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
  &.btn.show {
    background-color: white;
    color: black;
  }
  &.btn {
    text-align: left;
    width: 100%;
  }
  &.btn svg {
    float: right;
  }

  &.btn:hover.svg {
    color: blue;
  }
`;

const CentrexDropdown = ({
  items,
  selectedItem,
  onSelect,
  onChange,
  onBlur,
  controlId,
  placeholder,
  labelKey,
  valueKey,
}: PropsWithChildren<ContentProps>) => {
  return (
    <>
      <Wrapper>
        <StyledDropdown>
          <StyledDropdownToggle splitvariant="success">
            {selectedItem ? selectedItem[labelKey] : placeholder}
            <FaCaretDown></FaCaretDown>
          </StyledDropdownToggle>
          <Dropdown.Menu>
            {items &&
              items.map((a, i) => (
                <Dropdown.Item key={i} onClick={() => onSelect(a)} onChange={onChange} onBlur={onBlur} id={controlId}>
                  {' '}
                  {a[labelKey]}{' '}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </StyledDropdown>
      </Wrapper>
    </>
  );
};

export { CentrexDropdown };
