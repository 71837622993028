import { DocumentFilterType, DocumentsType } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';

class DocumentService {
  async getDocumentsType(clientId: string): Promise<DocumentsType[] | undefined> {
    try {
      const response = await axiosPrivate.get(`${Endpoints.DocumentTypesUrl}?clientId=${clientId}`, { cache: false });

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }

  async setDocumentTypeFilter(filter: DocumentFilterType): Promise<any> {
    try {
      const response = await axiosPrivate.put(Endpoints.DocumentTypesFilterUrl, filter);

      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }
}

export default new DocumentService();
