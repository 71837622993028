import React from 'react';
import authService from './authService';
import { AppUser, AuthResponse, TwoFactorRequiredResponse, getAppUserFromAuth } from '../../types';
import { object } from 'yup';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  clientId: string | undefined;
  isAdmin: boolean;
  is2FAEnabled: boolean;
  setIs2FAEnabled: (isEnabled: boolean) => void;
  setSelectedClientId: (clientId: string) => void;
  signIn: (username: string, password: string, callback: VoidFunction) => Promise<void>;
  signIn2fa: (username: string, code: string, callback: VoidFunction) => Promise<void>;
  signOut: (callback: VoidFunction) => void;
  getUser: () => AppUser | undefined;
}

const AuthContext = React.createContext<AuthContextType>(null!);
const appUser = window.localStorage.getItem('AppUser');
const initialLocalAdmin = appUser && JSON.parse(appUser).role.includes('admin');
const initialSelectedClientId = window.localStorage.getItem('SelectedClientId') ?? undefined;
const initial2FAEnabled = (appUser && JSON.parse(appUser).mobilePhone !== undefined ? true : false);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  
  const [is2FAEnabled, setIs2FAEnabled] = React.useState<boolean>(initial2FAEnabled);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(initialLocalAdmin);
  const [clientId, setClientId] = React.useState<string | undefined>(initialSelectedClientId);

  const getUser = (): AppUser | undefined => {
    const appUser = window.localStorage.getItem('AppUser');

    if (appUser) {
      const localUser = JSON.parse(appUser);
      return localUser;
    }

    return undefined;
  };

  const setSelectedClientId = (clientId: string) => {
    setClientId(clientId);
    window.localStorage.setItem('SelectedClientId', clientId);
  };

  const signIn = (username: string, password: string, callback: VoidFunction): Promise<void> => {
    return authService.login(username, password).then((data: TwoFactorRequiredResponse | AuthResponse | undefined) => {
        if (data && "accessToken" in data) {
          const appUser = getAppUserFromAuth(data);
          setIsAdmin(appUser.role.includes('admin'));

          if (!appUser.role.includes('admin')) {
            setClientId(appUser.clientId);
          }
          
          window.localStorage.setItem('AppUser', JSON.stringify(appUser));
          if(!appUser.mobilePhone){
            setIs2FAEnabled(false);
            navigate('/auth/verify-phone', { replace: true })
          }
          else{
            callback();
          }
        }
        if(data && "requiresTwoFactor" in data) {
            window.localStorage.setItem('emailAuth', username);
            window.localStorage.setItem('phone2fa', data.twoFactorPhone);
            navigate('/auth/2fa', { replace: true })
            console.log("Two factor required")
        }

        return undefined;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  };

  const signIn2fa = (username: string, code: string, callback: VoidFunction): Promise<void> => {
    return authService.login2fa(username, code).then((data:  AuthResponse | undefined) => {
        if (data && "accessToken" in data) {
          const appUser = getAppUserFromAuth(data);
          setIsAdmin(appUser.role.includes('admin'));

          if (!appUser.role.includes('admin')) {
            setClientId(appUser.clientId);
          }
          if(appUser.mobilePhone){
            setIs2FAEnabled(true);
          }
          
          window.localStorage.setItem('AppUser', JSON.stringify(appUser));
          callback();
        }
      
        return undefined;
      },
      function (error:any) {
        if(error.response && typeof error.response.data ==='string' ){
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      }
    );
  };
  const signOut = (callback: VoidFunction) => {
    window.localStorage.removeItem('AppUser');
    window.localStorage.removeItem('forgotEmail');
    localStorage.clear();
    callback();
  };

  const value = { signIn, signIn2fa, signOut, getUser, is2FAEnabled, setIs2FAEnabled, isAdmin, clientId, setSelectedClientId };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
