import {
  Client,
  ClientNotificationType,
  CreateWebUserForm,
  DailyStatsResponse,
  FeaturesType,
  FeaturesTypeResponse,
  LatestUsersStats,
  MonthStatsResponse,
  PushNotificationType,
  RecentActivityType,
  WebUser,
} from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';
class AdminService {
  async getClients(): Promise<Array<Client> | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/clients`);

      if (response?.status === 200) {
        return response?.data?.map((r: any) => ({
          id: r.id,
          clientId: r.clientId,
          clientName: r.name,
          RegistrationDate: new Date(),
          StripeCustomerId: '',
          logo: r.logo,
          icon: r.icon,
          created: r.created,
          isActive: r.isActive,
          primaryColor: r.primaryColor,
          isTestClient: r.isTestClient,
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getClientUsers(): Promise<Array<WebUser> | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/users`);

      if (response?.status === 200) {
        return response?.data?.map((r: any) => ({
          id: r.id,
          firstName: r.firstName,
          lastName: r.lastname,
          email: r.userName,
          clientId: r.clientId,
          mobilePhone: r.mobilePhone,
          roles: r.roles,
          profileImage: r.profileImage,
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getUserDetails(userId: string): Promise<WebUser | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/users/details?userId=${userId}`);

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }

  async updateUserProfile(FirstName: string, LastName: string, Email: string, Files: File[]): Promise<any> {
    try {
      const formData = new FormData();

      formData.append('FirstName', FirstName);
      formData.append('LastName', LastName);
      formData.append('Email', Email);

      for (const file of Files) {
        formData.append('Files', file);
      }

      const response = await axiosPrivate.post(`/admin/api/users/update`, formData);

      if (response?.status === 200) {
        return response;
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getClientUserNotification(userId: string, clientId: string): Promise<ClientNotificationType[] | undefined> {
    try {
      const response = await axiosPrivate.get(`admin/api/users/notifications?userId=${userId}&clientId=${clientId}`, {
        cache: false,
      });

      if (response && response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }

  async getClientDetails(id: string, clientId: string): Promise<Client | undefined> {
    try {
      const response: any = await axiosPrivate.post(`/admin/api/clients/details`, { id: id, clientId: clientId });

      if (response.status === 200) {
        return {
          id: response?.data.id,
          clientId: response?.data?.clientId,
          clientName: response?.data?.clientName,
          created: response?.data?.registrationDate,
          stripeCustomerId: response?.data?.stripeCustomerId,
          billingEmail: response?.data?.billingEmail,
          logo: response?.data?.clientLogo,
          whiteLogo: response?.data?.clientWhiteLogo,
          icon: response?.data?.clientIcon,
          isActive: response?.data?.isActive,
          isTestClient: response?.data?.isTestClient,
          primaryColor: response?.data?.clientPrimaryColor,
          crmClientSecret: response?.data?.crmClientSecret,
          crmKeyId: response?.data?.crmKeyId,
          website: response?.data?.website,
          appStoreLink: response?.data?.clientAppStoreLink,
          playStoreLink: response?.data?.clientPlayStoreLink,
          address: response?.data?.clientAddress,
          phone: response?.data?.clientPhone,
          subdomain: response?.data?.subdomain,
          emailFrom: response?.data?.emailFrom,
          nameFrom: response?.data?.nameFrom,
          crmUserId: response?.data?.crmUserId,
          crmUrlRedirect: response?.data?.crmUrlRedirect,
          sendgridApiKey: response?.data?.sendgridApiKey,
          sendgridApiEnabled: response?.data?.sendgridApiEnabled,
          crmUrl: response?.data.crmUrl,
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getCurrentClient(): Promise<Client | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/clients/current`, { cache: false });

      if (response?.status === 200) {
        return {
          id: response?.data?.id,
          clientId: response?.data?.clientId,
          clientName: response?.data?.clientName,
          created: response?.data?.registrationDate,
          stripeCustomerId: response?.data?.stripeCustomerId,
          billingEmail: response?.data?.billingEmail,
          logo: response?.data?.clientLogo,
          icon: response?.data?.clientIcon,
          isActive: response?.data?.isActive,
          isTestClient: response?.data?.isTestClient,
          primaryColor: response?.data?.clientPrimaryColor,
          crmClientSecret: response?.data?.crmClientSecret,
          crmKeyId: response?.data?.crmKeyId,
          website: response?.data?.website,
          appStoreLink: response?.data?.clientAppStoreLink,
          playStoreLink: response?.data?.clientPlayStoreLink,
          address: response?.data?.clientAddress,
          phone: response?.data?.clientPhone,
          subdomain: response?.data?.subdomain,
          emailFrom: response?.data?.emailFrom,
          nameFrom: response?.data?.nameFrom,
          crmUserId: response?.data?.crmUserId,
          crmUrlRedirect: response?.data?.crmUrlRedirect,
          sendgridApiKey: response?.data?.sendgridApiKey,
          sendgridApiEnabled: response?.data?.sendgridApiEnabled,
          crmUrl: response?.data?.crmUrl,
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async createWebUser(webUser: CreateWebUserForm): Promise<any> {
    try {
      const response = await axiosPrivate.post(Endpoints.CreateWebUserUrl, webUser);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        if (err.response.data.errors && typeof err.response.data.errors === 'object') {
          const errors = err.response.data.errors;

          return Promise.reject(errors);
        }
      }

      return Promise.reject(err);
    }
  }

  async createClient(client: Client): Promise<any> {
    try {
      const response = await axiosPrivate.post(Endpoints.CreateClientUrl, client);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateClient(client: Client): Promise<any> {
    try {
      const response = await axiosPrivate.put(Endpoints.UpdateClientUrl, client);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateClientCurrent(client: Client): Promise<any> {
    try {
      const response = await axiosPrivate.put(Endpoints.UpdateClientCurrentUrl, client);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getClientMonthlyUsage(monthKey: string | null, clientId: string): Promise<MonthStatsResponse | undefined> {
    try {
      const response = await axiosPrivate.get(
        `/admin/api/clients/monthlyStats?${monthKey ? `monthKey=${monthKey}&` : ''}clientId=${clientId}`,
        { cache: false }
      );

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getClientDailyUsage(
    clientId: string,
    startDate: string,
    endDate: string
  ): Promise<DailyStatsResponse[] | undefined> {
    try {
      const response = await axiosPrivate.get(
        `/admin/api/clients/dailyStats?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}`,
        {
          cache: false,
        }
      );

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getLatestUsersStats(clientId: string): Promise<LatestUsersStats | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/clients/latestUsersStats?clientId=${clientId}`, {
        id: 'getLatestUsersStats',
      });

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getClientMonthlyUsageList(clientId: string): Promise<Array<MonthStatsResponse> | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/clients/monthlyStatsList?clientId=${clientId}`);

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getFeaturesData(clientId: string): Promise<FeaturesTypeResponse[] | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/features?clientId=${clientId}`, { cache: false });

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setFeaturesStatus(feature: FeaturesType): Promise<any> {
    try {
      const response = await axiosPrivate.post('/admin/api/features', feature);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getClientPushNotification(clientId: string): Promise<PushNotificationType | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/clients/push-notification-auth?clientId=${clientId}`);

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateClientPushNotification(pushInfo: PushNotificationType): Promise<any> {
    try {
      const response = await axiosPrivate.put('/admin/api/clients/push-notification-auth', pushInfo);

      if (response?.status === 200) {
        return response;
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getUserRecentActivity(userId: string, clinetId: string): Promise<RecentActivityType[] | undefined> {
    try {
      const response = await axiosPrivate.get(`/admin/api/users/activity?userid=${userId}&clientId=${clinetId}`);

      if (response?.status === 200) {
        return response?.data;
      }

      return undefined;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new AdminService();
