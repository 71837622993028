import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import adminService from '../../services/adminService/adminService';
import { AuthContext } from '../../services/authService/authProvider';
import { MonthlyStats } from '../../types';

const TotalTile = styled.h6`
  color: #4c5980;
  font-size: 14px;
  margin-bottom: 0;
`;

const TotalNumber = styled.h1`
  margin-bottom: 10px;
`;
const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function UsersStats() {
  const defaultStats = {
    newUsers: 0,
    deletedUsers: 0,
    totalUsers: 0,
    newPlaidConnections: 0,
    totalPlaidConnections: 0,
    removedPlaidConnections: 0,
    plaidAssetReport:0,
    notifications: 0,
    totalDisconnectedPlaidItems: 0,
    dateKey:"",
    clientId:""
  };
  const auth = React.useContext(AuthContext);
  const [userStats, setUserStats] = React.useState<MonthlyStats>(defaultStats);

  React.useEffect(() => {
    const user = auth.getUser();
    if (user && auth.clientId) {
      adminService.getClientMonthlyUsage(null, auth.clientId).then(stats => {
        if(stats){
          setUserStats(stats.month);
        }
        else{
          setUserStats(defaultStats);
        }
        
      });
    }
  }, [auth]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col style={{ borderBottom: '1px solid #dedede', borderRight: '1px solid #dedede' }}>
            <CenteredDiv>
              <TotalTile>Active Users</TotalTile>
              <TotalNumber>{userStats?.totalUsers}</TotalNumber>
            </CenteredDiv>
          </Col>
          <Col style={{ borderBottom: '1px solid #dedede' }}>
            <CenteredDiv>
              <TotalTile>Plaid Connections</TotalTile>
              <TotalNumber>{userStats?.totalPlaidConnections}</TotalNumber>
            </CenteredDiv>
          </Col>
        </Row>
        <Row>
          <Col style={{ borderRight: '1px solid #dedede' }}>
            <CenteredDiv>
              <TotalTile style={{ paddingTop: '15px' }}>Notifications</TotalTile>
              <TotalNumber>{userStats?.notifications}</TotalNumber>
            </CenteredDiv>
          </Col>
          <Col>
            <CenteredDiv>
              <TotalTile style={{ paddingTop: '15px' }}>Asset Report</TotalTile>
              <TotalNumber>{userStats?.plaidAssetReport}</TotalNumber>
            </CenteredDiv>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { UsersStats };
