import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Dropdown, Nav, NavDropdown, Offcanvas, Container, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

import { AuthContext } from '../../services/authService/authProvider';
import { OffCanvasNav } from '../LeftNav/offCanvasNav';

import type { Client, WebUser } from '../../types';
import adminService from '../../services/adminService/adminService';

import { FaBars, FaAngleDown, FaUserCircle, FaRegUser } from 'react-icons/fa';
import logo from '../../assets/img_logoCentrex.png';
import IconLogOut from '../../assets/image-icons/logout.svg';

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border: 1px solid #ced4da;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 5px 0;
  box-shadow: none !important;
  color: white;
  background-color: transparent;
  &:focus {
    background-color: transparent;
    color: white;
    border-bottom: 2px solid #ced4da;
  }
  &:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: none;
    background-color: transparent;
    color: white;
    border-bottom: 2px solid #ced4da;
  }
  &.is-invalid {
    color: red;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 0px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
  &.btn.show {
    background-color: white;
    color: black;
  }
  &.btn {
    text-align: left;
    width: 100%;
  }
  &.btn svg {
    float: right;
  }
`;
const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
const StyledNavbar = styled(Navbar)`
  background-color: transparent;
  color: white;
  .nav-link {
    color: white;
  }

  @media screen and (max-width: 575px) {
    background-color: #337ef1;
  }
`;

const ResponsiveContainer = styled(Container)`
  @media screen and (max-width: 575px) {
    --bs-gutter-x: 0rem;
  }
`;
const StyledContainer = (props: any) => {
  const { id, ...newprops } = props;
  return <ResponsiveContainer {...newprops} />;
};

function StyledNavLayout() {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [clients, setClients] = useState<Array<Client> | undefined>(undefined);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
  const [userDetail, setUserDetail] = useState<WebUser | null>(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    auth.signOut(() => {
      navigate('/auth/login', { replace: true });
    });
  };

  const handleSelect = (client: Client) => {
    if (client) {
      setSelectedClient(client);
      auth.setSelectedClientId(client.clientId);

      if (location.pathname.startsWith('/users')) {
        navigate('/users');
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (auth && auth?.getUser()?.id) {
        const userInfo = await adminService.getUserDetails(auth?.getUser()?.id as string);
        setUserDetail(userInfo as WebUser);
      }
    })();
  }, [auth, auth.is2FAEnabled, userDetail]);

  useEffect(() => {
    if (auth.isAdmin) {
      adminService.getClients().then(c => {
        setClients(c);

        if (auth.clientId) {
          setSelectedClient(c?.find(f => f.clientId === auth.clientId));
        }
      });
    }
  }, [auth.clientId, auth.isAdmin]);

  return (
    <>
      <style type="text/css">
        {`
          .dropdown-toggle::after {
            display: none !important; 
          }
        `}
      </style>

      <StyledContainer fluid style={{ marginBottom: '40px' }}>
        <StyledNavbar sticky="top" variant="dark">
          <Container fluid>
            <Nav.Link className="d-block d-sm-none" onClick={e => handleShow()}>
              <FaBars />
            </Nav.Link>
            <Navbar.Brand className="d-sm-none d-md-block" href="#home" onClick={e => navigate('/')}>
              <img alt="" src={logo} height="40" className="d-inline-block align-top" />{' '}
            </Navbar.Brand>

            {!location.pathname.includes('admin') && auth.isAdmin && auth.is2FAEnabled && (
              <>
                <Col xl={3} md={4} sm={6} xs={0}>
                  <StyledDropdown>
                    <StyledDropdownToggle splitvariant="success">
                      {selectedClient ? selectedClient.clientName : 'Select Client'}
                      <FaAngleDown className="mt-2"></FaAngleDown>
                    </StyledDropdownToggle>
                    <Dropdown.Menu>
                      {clients &&
                        clients.map((a, i) => (
                          <Dropdown.Item key={i} onClick={() => handleSelect(a)}>
                            {a.clientId} - {a.clientName}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </StyledDropdown>
                </Col>
              </>
            )}
            <Nav>
              <NavDropdown
                title={
                  <>
                    {userDetail?.profileImage && userDetail?.profileImage !== '' ? (
                      <img
                        src={`${process.env.REACT_APP_STORAGEURL}/web-profile-images/${userDetail?.profileImage}`}
                        alt=""
                        width={40}
                        height={40}
                        style={{ borderRadius: '100%' }}
                      />
                    ) : (
                      <FaUserCircle style={{ fontSize: '40px' }} />
                    )}
                  </>
                }
                id="basic-nav-dropdown"
                className="dropdown-menu-end"
                align="end"
              >
                <NavDropdown.Item
                  style={{
                    paddingLeft: '14px',
                    paddingRight: '14px',
                    paddingBottom: '10px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      {userDetail?.profileImage && userDetail?.profileImage !== '' ? (
                        <img
                          src={`${process.env.REACT_APP_STORAGEURL}/web-profile-images/${userDetail?.profileImage}`}
                          alt=""
                          width={28}
                          height={28}
                          style={{ borderRadius: '100%', border: '3px solid #50CEF8' }}
                        />
                      ) : (
                        <FaUserCircle style={{ fontSize: '28px', borderRadius: '100%', border: '3px solid #50CEF8' }} />
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: '16px',
                        color: '#2D3142',
                        fontWeight: 600,
                        marginBottom: 0,
                        marginLeft: '7px',
                      }}
                    >
                      {auth?.getUser()?.name} {auth?.getUser()?.lastName}
                    </p>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/myprofile" style={{ padding: '10px 14px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f7f7f7',
                      }}
                    >
                      <FaRegUser style={{ fontSize: '13px', color: '#2D3142', fontWeight: 600 }} />
                    </div>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#2D3142',
                        marginBottom: 0,
                        marginLeft: '7px',
                      }}
                    >
                      My profile
                    </p>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item style={{ padding: '10px 14px' }} onClick={handleLogout}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f7f7f7',
                      }}
                    >
                      <img src={IconLogOut} alt="" />
                    </div>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#D75053',
                        marginBottom: 0,
                        marginLeft: '7px',
                      }}
                    >
                      Logout
                    </p>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
        </StyledNavbar>
        <Offcanvas show={show} onHide={handleClose} backdrop="static">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <OffCanvasNav />
          </Offcanvas.Body>
        </Offcanvas>
      </StyledContainer>
    </>
  );
}

export { StyledNavLayout as Navbar };
