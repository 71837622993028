import { Contact } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

class crmService {
  async getContact(contactId: string, clientId: string): Promise<Contact | undefined> {
    try {
      const response = await axiosPrivate.get(`crm/api/v2/Contacts?contactId=${contactId}&clientId=${clientId}`);

      if (response?.status === 200) {
        return {
          id: response?.data?.id,
          email: response?.data?.email,
          address: response?.data?.address,
        };
      }

      return undefined;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new crmService();
