import {
  RefAttributes,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Tooltip,
  TooltipProps,
  OverlayTrigger,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../../services/authService/authProvider";
import RecentActivity from "../../components/Users/RecentActivity";
import usersService from "../../services/usersService/usersService";
import {
  Contact,
  UserData,
  capitalize,
  type RecentActivityType,
  type User,
} from "../../types";
import crmService from "../../services/crmService/crmService";
import { ProfileImage } from "../ProfileImage";
import { LoadingSpinnerComponent } from "../Loading";
import ModalComponent from "../Modal";
import adminService from "../../services/adminService/adminService";

import BankIcon from "../../assets/image-icons/bank.png";
import {
  FaRedo,
  FaStroopwafel,
  FaRegTrashAlt,
  FaWordpress,
  FaMobile,
} from "react-icons/fa";
import CentrexIcon from "../../assets/image-icons/centrex_mark.png";
import { MapLocation } from "../Location/MapLocation";

type TypePosition = {
  lat: number;
  lng: number;
};

type UserInfoProps = {
  userDetails: UserData | undefined;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const UserAddress = (address: any) => {
  let fullAddress = "";

  if (address?.address1 && address?.address1 !== "")
    fullAddress += address?.address1;
  if (address?.address2 && address?.address2 !== "")
    fullAddress += " " + address?.address2;
  if (address?.address3 && address?.address3 !== "")
    fullAddress += " " + address?.address3;
  if (address?.city && address?.city !== "")
    fullAddress += fullAddress !== "" ? ", " + address?.city : address?.city;
  if (address?.state && address?.state !== "")
    fullAddress += " " + address?.state;
  if (address?.zip && address?.zip !== "") fullAddress += " " + address?.zip;

  return fullAddress;
};
function UserInfo({ userDetails, loading, setLoading }: UserInfoProps) {
  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = useState<boolean>(false);
  const [statusActive, setStatusActive] = useState<string>("");
  const [contactDetails, setContactDetails] = useState<Contact | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<number>(404);
  const [center, setCenter] = useState<TypePosition>({
    lat: 38.0,
    lng: -97.0,
  });
  const [activity, setActivity] = useState<RecentActivityType[]>([]);
  const [loadingContact, setLoadingContact] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(2.5);

  useEffect(() => {
    (async () => {
      if (userDetails && userDetails?.id) {
        const res = await adminService.getUserRecentActivity(
          userDetails?.id,
          userDetails?.clientId
        );
        setActivity(res?.slice(0, 5) as RecentActivityType[]);
      }
    })();
  }, [userDetails]);

  useEffect(() => {
    (async () => {
      if (params.id) {
        if (userDetails?.contactId) {
          try {
            setLoadingContact(true);
            const contact = await crmService.getContact(
              userDetails?.contactId,
              userDetails?.clientId
            );
            setContactDetails(contact);
            setErrorAlert(false);
            setLoadingContact(false);
          } catch (error: any) {
            setErrorStatus(error?.response?.status as number);
            setErrorMessage(error?.response?.data as string);
            setErrorAlert(true);
          }
        }
      }
    })();
  }, [params.id, userDetails]);

  const renderCrmTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      Open in Centrex CRM
    </Tooltip>
  );

  const renderAccountTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      Manage accounts
    </Tooltip>
  );

  const renderDeleteAccountTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete accounts
    </Tooltip>
  );

  const renderActiveAccountTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      Active accounts
    </Tooltip>
  );

  const handleDeleteAccount = async () => {
    setLoading(true);
    await usersService.disableUser(userDetails?.id as string);

    setTimeout(() => {
      setLoading(false);
      setModal(false);
    }, 500);
  };

  const handleActiveAccount = async () => {
    setLoading(true);
    await usersService.enableUser(userDetails?.id as string);
    setLoading(false);
    setModal(false);
  };

  return (
    <>
      {!userDetails && <LoadingSpinnerComponent />}

      <ModalComponent
        modal={modal}
        size="lg"
        setModal={setModal}
        backdrop="static"
        centered={true}
        headerTitle={
          statusActive === "delete" ? "Delete Account" : "Active Account"
        }
        title={
          <>
            {statusActive === "delete" ? (
              <h4>Are you sure you want to delete this user?</h4>
            ) : (
              <h4>Are you sure you want to reactive this user?</h4>
            )}
          </>
        }
        content={
          <>
            {statusActive === "delete" && (
              <>
                <p>
                  If you accept, this user will no longer be able to log into
                  the mobile app, all plaid data, such as transactions, asset
                  reports and linked accounts will be removed.
                </p>
                <p>
                  You can later re create this user by using the CRM webhook.
                </p>
              </>
            )}
          </>
        }
        okVariant={statusActive === "delete" ? "danger" : "primary"}
        okTxt={
          <>
            {statusActive === "delete" ? (
              <span>Delete</span>
            ) : (
              <span>Active</span>
            )}
            &nbsp;
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </>
        }
        onClose={() => setModal(false)}
        {...(statusActive === "delete"
          ? { onClick: () => handleDeleteAccount() }
          : { onClick: () => handleActiveAccount() })}
        isFooter={true}
      />

      {userDetails && (
        <Container fluid>
          <Row style={{ marginBottom: "24px" }}>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <ProfileImage url={userDetails?.profileImageURL} sizePx={95} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <CenteredDiv>
                <span style={{ color: "#2D3142", fontSize: "18x" }}>
                  {capitalize(userDetails?.company)}
                </span>
                <h5 style={{ color: "#2D3142", fontSize: "20px" }}>
                  {userDetails?.firstName} {userDetails?.lastName}{" "}
                </h5>
                <div style={{ display: "inline" }}>
                  {userDetails?.wppUser ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Word press plugin user
                        </Tooltip>
                      }
                    >
                      <div style={{ display: "inline" }}>
                        <FaWordpress color="#21759b" />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                  {userDetails?.mobileApp ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">Mobile app</Tooltip>
                      }
                    >
                      <div style={{ display: "inline" }}>
                        <FaMobile color="#228B22" />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </div>
              </CenteredDiv>
            </Col>
          </Row>
          <Alert
            variant={errorStatus === 424 ? "warning" : "danger"}
            show={errorAlert}
          >
            <Alert.Heading>
              {errorStatus === 424 ? (
                <>Contact not found on CRM</>
              ) : (
                <>Unable to connect to CRM</>
              )}
            </Alert.Heading>

            {errorStatus === 424 && (
              <>
                <p>{errorMessage}</p>
              </>
            )}

            {errorStatus !== 424 && (
              <>
                <p>
                  Please update your CRM credentials in the &nbsp;
                  <>
                    {auth.isAdmin && (
                      <a
                        href={`/admin/clients/${userDetails?.clientId}`}
                        style={{ color: "#842029" }}
                      >
                        settings page.
                      </a>
                    )}

                    {!auth.isAdmin && (
                      <a href="/settings?view=crm" style={{ color: "#842029" }}>
                        settings page.
                      </a>
                    )}
                  </>
                </p>
              </>
            )}
          </Alert>

          {!errorAlert && (
            <>
              <Row>
                <Col>
                  <span style={{ color: "#4C5980", fontSize: "14px" }}>
                    Email:
                  </span>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <span style={{ color: "#2D3142", fontSize: "14px" }}>
                    {contactDetails?.email}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ color: "#4C5980", fontSize: "14px" }}>
                    Contact ID:
                  </span>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <span style={{ color: "#2D3142", fontSize: "14px" }}>
                    {contactDetails?.id}
                  </span>
                </Col>
              </Row>
              {contactDetails && (
                <Row>
                  <Col>
                    <span style={{ color: "#4C5980", fontSize: "14px" }}>
                      Address:
                    </span>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <span style={{ color: "#2D3142", fontSize: "14px" }}>
                      {UserAddress(contactDetails?.address)}
                    </span>
                  </Col>
                </Row>
              )}
              {!loadingContact && (
                <MapLocation address={UserAddress(contactDetails?.address)} />
              )}
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <div></div>
            <div>
              {userDetails?.userStatus === 4 && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderDeleteAccountTooltip}
                >
                  <Button
                    className="btnNavCustom gray"
                    style={{
                      marginRight: "5px",
                      paddingLeft: "9px",
                      paddingTop: "3px",
                    }}
                    onClick={() => {
                      setModal(true);
                      setStatusActive("delete");
                    }}
                  >
                    <FaRegTrashAlt style={{ color: "#2D3142" }} />
                  </Button>
                </OverlayTrigger>
              )}

              {userDetails?.userStatus !== 4 && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderActiveAccountTooltip}
                >
                  <Button
                    className="btnNavCustom gray"
                    style={{
                      marginRight: "5px",
                      paddingLeft: "9px",
                      paddingTop: "3px",
                    }}
                    onClick={() => {
                      setModal(true);
                      setStatusActive("active");
                    }}
                  >
                    <FaRedo style={{ color: "#2D3142" }} />
                  </Button>
                </OverlayTrigger>
              )}

              {userDetails?.userStatus === 4 && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderAccountTooltip}
                >
                  <Button
                    className="btnNavCustom gray"
                    style={{
                      marginRight: "5px",
                      paddingLeft: "9px",
                      paddingTop: "3px",
                    }}
                    onClick={() => {
                      navigate(`/users/${userDetails?.id}/bank-accounts`);
                    }}
                  >
                    <FaStroopwafel style={{ color: "#2D3142" }} />
                  </Button>
                </OverlayTrigger>
              )}

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderCrmTooltip}
              >
                <Button
                  className="btnNavCustom gray"
                  style={{ paddingLeft: "5px", paddingTop: "3px" }}
                  onClick={() => {
                    window.open(
                      `https://login.centrexsoftware.com/index.php?module=contacts&page=view2&cid=${contactDetails?.id}`
                    );
                  }}
                  disabled={errorAlert}
                >
                  <img src={CentrexIcon} alt="" />
                </Button>
              </OverlayTrigger>
            </div>
          </div>

          {userDetails?.userStatus === 4 && activity?.length !== 0 && (
            <div style={{ marginBottom: "24px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <h3 style={{ fontSize: "24px" }}>Recent Activity</h3>
              </div>

              <RecentActivity activity={activity} />
            </div>
          )}
        </Container>
      )}
    </>
  );
}

export { UserInfo };
