import { BaseClient } from '../../types';
import { axiosPublic } from '../axios/axiosPublic';

const debtpayproApi = 'https://api.debtpaypro.com/v1';

class debtpayProService {
  async testCredentials(clientId: string, clientSecret: string): Promise<string | undefined> {
    try {
      const response = await axiosPublic.post(debtpayproApi + `/auth/token`, {
        client_id: clientId,
        client_secret: clientSecret,
      });

      if (response?.status === 200) {
        return response?.data?.response?.api_key;
      }
    } catch (err) {
      return Promise?.reject(err);
    }

    return undefined;
  }

  async getCurrentUser(apiKey: string): Promise<BaseClient | undefined> {
    try {
      const requestOptions = {
        headers: {
          'api-key': apiKey,
          'cache-control': 'no-cache',
        },
      };
      const response = await axiosPublic.get(debtpayproApi + `/users/current`, requestOptions);

      if (response?.status === 200) {
        return {
          clientId: response?.data?.response?.acctID,
          id: response?.data?.response?.id,
        };
      }
    } catch (err) {
      return Promise?.reject(err);
    }

    return undefined;
  }

  async getContact(apiKey: string, contactId: string): Promise<BaseClient | undefined> {
    try {
      const requestOptions = {
        headers: {
          'api-key': apiKey,
        },
      };
      const response = await axiosPublic.get(debtpayproApi + `/contacts/${contactId}`, requestOptions);

      if (response?.status === 200) {
        return {
          clientId: response?.data?.response?.acctID,
          id: response?.data?.response?.id,
        };
      }

      return undefined;
    } catch (err) {
      return Promise?.reject(err);
    }
  }
}

export default new debtpayProService();
