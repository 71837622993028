import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentCard } from '../../components/Card';

function NotFound (){

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <ContentCard bodyPadding="0.5em">
                        <p>
                            The resource you where looking for was not found
                        </p> 
                    </ContentCard>
                </Col>
            </Row>
        </Container>
    )
}

export { NotFound };