import { useEffect, useState } from 'react';
import { Row as BRow, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
  HeaderCell,
  Row,
  Data,
  Table,
  Header,
  HeaderRow,
  Body,
  Cell,
  TableNode,
} from '@table-library/react-table-library';
import { useTheme } from '@table-library/react-table-library/theme';
import type { AssignmentType, ClientInfoType, FeaturesTypeResponse, TableItem } from '../../../types';
import adminService from '../../../services/adminService/adminService';
import AdvancesService from '../../../services/crmService/AdvancesService';
import { LoadingSpinnerComponent } from '../../../components/Loading';
import CheckBox from '../../../components/Forms/CheckBox';

const StyledHeaderCell = styled(HeaderCell)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledRow = styled(Row)`
  cursor: pointer;
  &:focus {
    background-color: white;
    color: black;
  }
  &:hover {
    background-color: #4c5980;
    color: black;
  }
`;

export default function Advances({ client }: ClientInfoType) {
  const [featureData, setFeatureData] = useState<FeaturesTypeResponse[]>([]);
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Data<TableNode>>({
    nodes: [],
  });

  const theme = useTheme({
    Row: `
    cursor: pointer;
    color: rgb(117, 117, 117);
    border: 1px solid #fff !important;
    &:hover {
      color: black;
    }
    &:hover .td {
      background-color:#f8f9fa;
    }
    `,
  });

  useEffect(() => {
    (async () => {
      if (client) {
        const features: FeaturesTypeResponse[] | undefined = await adminService.getFeaturesData(client?.clientId);

        if (features) {
          const filter = features?.filter(
            (rs: FeaturesTypeResponse) =>
              rs.description !== '' && (rs.key.search('advances/') !== -1 || rs.key.search('balance/') !== -1)
          );

          setFeatureData(filter);
        }

        try {
          const assignmentData: AssignmentType[] | undefined = await AdvancesService.getAssignmentType(
            client?.clientId
          );

          if (assignmentData) {
            const tableItems = assignmentData?.map(
              (r: AssignmentType, i: number) => ({ ...r, number: i + 1 } as TableItem<AssignmentType>)
            );
            const tableData = { nodes: tableItems } as Data<TableNode>;
            setTableData(tableData);
          }

          setTableLoaded(true);
        } catch (error) {
          setTableLoaded(true);
        }
      }
    })();
  }, [client]);

  return (
    <div className="client-settings">
      <h1 className="title" style={{ marginBottom: '55px' }}>
        Advances
      </h1>
      <div>
        <div style={{ marginBottom: '60px' }}>
          {featureData?.map((rs: FeaturesTypeResponse, key: number) => (
            <div key={key} style={{ borderBottom: '1px solid #ced4da' }}>
              <div className="result-row">
                <div className="item">
                  <p className="txt">{rs.description}</p>
                </div>
                <CheckBox
                  clientId={client?.clientId as string}
                  targetKey={rs.key}
                  type="switch"
                  id={`custom-switch-${key}`}
                  checked={rs.isEnabled}
                  index={key}
                  apiKind="Plaid"
                />
              </div>
            </div>
          ))}
        </div>
        <h1 className="title" style={{ marginBottom: '30px' }}>
          Assignments
        </h1>

        {!tableLoaded && (
          <div style={{ marginBottom: '55px' }}>
            <LoadingSpinnerComponent />
          </div>
        )}

        {tableLoaded && tableData.nodes.length === 0 && (
          <Container style={{ marginBottom: '55px' }}>
            <BRow className="justify-content-md-center">
              <Col md="auto">
                <span>Data not available</span>
              </Col>
            </BRow>
          </Container>
        )}

        {tableLoaded && tableData.nodes.length !== 0 && (
          <>
            <Table data={tableData} theme={theme}>
              {(tableList: TableNode[]) => (
                <>
                  <Header>
                    <HeaderRow>
                      <StyledHeaderCell>Description</StyledHeaderCell>
                      <StyledHeaderCell>Enabled</StyledHeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item: TableNode, key: number) => (
                      <StyledRow item={item} key={key}>
                        <Cell>
                          <p className="txt-16 mb-0">{item.key}</p>
                        </Cell>
                        <Cell>
                          <div className="row-item">
                            <CheckBox
                              clientId={client?.clientId as string}
                              targetKey={item.key.toString()}
                              type="switch"
                              id={`custom-switch-${key}`}
                              checked={item.isEnabled}
                              index={key}
                              apiKind="Assignments"
                            />
                          </div>
                        </Cell>
                      </StyledRow>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </>
        )}
      </div>
    </div>
  );
}
