import { ClientBilling, User } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

class ClientsService {
  async getUsersList(id: string): Promise<Array<User> | undefined> {
    try {
      const response = await axiosPrivate.post('transactions/api/v1/Dashboard/Users', { userId: id });

      if (response?.status === 200) {
        return response?.data?.users?.map((u: any) => ({
          id: u.userId,
          userName: u.userName,
          company: u.company,
          email: u.email,
          createdDate: u.createdDate,
          clientId: u.clientId,
          contactId: u.contactId,
          plaidConnections: u.plaidItems,
          plaidAuthRequired: u.plaidAuthRequired,
          userStatus: u.userStatus,
          mobileApp: u.mobileApp,
          wppUser: u.wppUser
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async createSetupIntent(clientId: string): Promise<string | undefined> {
    try {
      const response = await axiosPrivate.get(`/api/v1/Billing/GetSetupIntentToken?ClientId=${clientId}`);

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getClient(clientId: string): Promise<ClientBilling | undefined> {
    try {
      const response = await axiosPrivate.get(`/api/v1/admin/clients/details/${clientId}`);

      if (response?.status === 200) {
        return {
          id: response?.data?.CentrexClient?.id,
          clientId: response?.data?.CentrexClient?.clientId,
          stripeCustomerId: response?.data?.CentrexClient?.stripeCustomerId,
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new ClientsService();
