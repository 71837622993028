import { Account, BankAccounts, User, UserData } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import Endpoints from '../endPoints';

class UsersService {
  async getUsersList(clientId: string): Promise<Array<User> | undefined> {
    try {
      const response = await axiosPrivate.get(`/transactions/api/Users/get?clientId=${clientId}`, {
        id: `getUsersList-${clientId}`,
      });

      if (response?.status === 200 && response?.data) {
        return response?.data?.users
          ?.sort((a: any, b: any) => a.createdDate.localeCompare(b.createdDate))
          ?.map((u: any) => ({
            id: u.userId,
            userName: u.userName,
            company: u.company,
            email: u.email,
            createdDate: u.createdDate,
            clientId: u.clientId,
            contactId: u.contactId,
            plaidConnections: u.plaidItems,
            plaidAuthRequired: u.plaidAuthRequired,
            userStatus: u.userStatus,
            profileImageURL: u.profileImageURL,
            pushEnabled: u.pushEnabled,
            mobileApp: u.mobileApp,
            wppUser: u.wppUser
          }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
  async getUserProfile(id: string): Promise<UserData | undefined> {
    try {
      const response = await axiosPrivate.get(`/transactions/api/Profile/DetailsByUserId?userId=${id}`, {
        cache: false,
      });

      if (response.status === 200) {
        const u = response.data.appUserDto;
        const appUser: UserData = {
          id: u.userId,
          userName: u.userName,
          company: u.businessName,
          email: u.fixedEmail,
          createdDate: u.createdDate,
          clientId: u.clientId,
          contactId: u.contactId,
          accounts: u.plaidItems?.map(
            (u: any) =>
              ({
                id: u.id,
                clientId: u.clientId,
                institutionId: u.institutionId,
                institutionLogo: u.institutionLogo,
                institutionName: u.institutionName,
                itemId: u.itemId,
                requireAuth: u.requireAuth,
                removedOn: u.removedOn,
                createdOn: u.createdOn,
                userId: u.userId,
                accounts: u.accounts?.map(
                  (a: any) =>
                    ({
                      id: a.accountId,
                      name: a.name,
                      number: a.accountNumber,
                      type: a.accountType,
                      subType: a.accountSubtype,
                      institutionName: u.institutionName,
                      accessToken: u.accessToken,
                      bankId: u.id,
                      institutionId: u.institutionId,
                      latestBalance: a.lastBalance,
                      itemId: u.itemId,
                      requiresLogin: u.requireAuth,
                      createdOn: u.createdOn,
                      lastSyncDate: a.lastSyncDate
                    } as Account)
                ),
              } as BankAccounts)),
          userStatus: u.status,
          mobileApp: u.mobileApp,
          wppUser: u.wppUser,
          profileImageURL: u.profileImageURL,
          pushEnabled: u.pushEnabled,
          firstName: u.firstName,
          lastName: u.lastName,
        } as UserData;
        return appUser;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async disableUser(id: string): Promise<any> {
    try {
      const response = await axiosPrivate.put(Endpoints.DisableUserUrl, { userId: id });

      if (response?.status === 200) {
        return response;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async enableUser(id: string) {
    try {
      const response = await axiosPrivate.put(Endpoints.EnableUserUrl, { userId: id });

      if (response?.status === 200) {
        return response;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new UsersService();
