import { LoadingSpinnerComponent } from '../Loading';
import { Col, Row } from 'react-bootstrap';
import { Advance } from '../../types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ContactAdvance } from './ContactAdvance';

interface IContactAdvancesProps {
  isLoading: boolean | undefined;
  contactId?:string;
  clientId?:string;
  advances?: Advance[];
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const ContactAdvances = ({ isLoading, contactId, clientId, advances }: IContactAdvancesProps) => {
    
  return (
    <>
      {isLoading && <LoadingSpinnerComponent />}
      {advances && (
        <>
        <Row>
          <Col>
            <h4>Advances</h4>
          </Col>
        </Row>
        <Carousel responsive={responsive} containerClass="container-padding-bottom" >
          { advances.filter(a=>a.fundingDate).map((a: Advance)=>(
            <ContactAdvance 
              key={a.dealId}
              advance={a}
            />
          ))}
        </Carousel>
        </>
      )}     
   </>
  );
};

export { ContactAdvances };
