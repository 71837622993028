import { PlaidError, PlaidItemErrorCode, Transaction } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';

class PlaidService {
  async getAssetReportPdf(assetReportToken: string): Promise<Blob | undefined> {
    try {
      const response = await axiosPrivate.post(
        '/plaid/asset_report/pdf/get',
        { asset_report_token: assetReportToken },
        { responseType: 'blob' }
      );

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getInstitutions(): Promise<any | undefined> {
    try {
      const response = await axiosPrivate.post('/plaid/institutions/get');

      if (response?.status === 200) {
        return response?.data;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getInstitution(id: string): Promise<any | undefined> {
    try {
      const response = await axiosPrivate.post('/plaid/institutions/get_by_id', {
        institution_id: id,
        options: { include_optional_metadata: true },
      });

      if (response?.status === 200) {
        return response?.data?.institution;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getMonthTransactions(
    accessToken: string,
    itemId: string,
    accountId: string,
    month: Date
  ): Promise<Array<Transaction> | undefined> {
    try {
      const from = new Date(month.getUTCFullYear(), month.getMonth(), 1).toISOString().split('T')[0];
      const to = new Date(month.getUTCFullYear(), month.getMonth() + 1, 0).toISOString().split('T')[0];
      const response = await axiosPrivate.post('/plaid/transactions/get', {
        access_token: accessToken,
        start_date: from,
        end_date: to,
        options: {
          count: 500,
          account_ids: [accountId],
        },
      });

      if (response?.status === 200) {
        return response.data.transactions.map(
          (t: any) =>
            ({
              id: t.transaction_id,
              amount: t.amount,
              date: t.date,
              description: t.name,
            } as Transaction)
        );
      }
    } catch (err: any) {
      if (err) {
        const plaidError = err?.response?.data as PlaidError;
        this.reportItemError(itemId, PlaidItemErrorCode.LoginRequired);
        return Promise.reject(plaidError);
      }
    }

    return undefined;
  }

  async reportItemError(itemId: string, errorCode: PlaidItemErrorCode) {
    let itemError = {
      webhook_type: 'ITEM',
      webhook_code: 'ERROR',
      item_id: itemId,
      error: {
        error_code: errorCode,
        error_type: 'ITEM_ERROR',
      },
    };
    try {
      const response = await axiosPrivate.post('/transactions/api/PlaidWebhook', itemError);

      if (response?.status === 200) {
        return;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }
}

export default new PlaidService();
