/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { AuthContext } from '../../services/authService/authProvider';
import { DailyStatsResponse, getMonthByMonth, getWeekByWeek } from '../../types';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  ChartData,
  Legend,
  Tooltip,
  ScatterDataPoint,
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
} from 'chart.js';
import styled from 'styled-components';
import adminService from '../../services/adminService/adminService';
import { SelectFilterType } from '../../containers/Dashboard';
import { differenceInDays, differenceInMonths, format, add } from 'date-fns';
import { btnOption } from '../MenuButtons';

Chart.register(Legend, Tooltip, CategoryScale, PointElement, LinearScale, LineElement);

const Wrapper = styled.div`
  margin-bottom: 1em;
  padding: 0 10px;
`;

interface UsersOverviewProps {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  selectFilter: SelectFilterType[];
  period: string;
  setMenu: React.Dispatch<React.SetStateAction<btnOption[]>>;
}

type ChartDataType = {
  label: string;
  data: any;
  borderColor: string;
  backgroundColor: string;
  tension: number;
  fill: boolean;
};

type ChartDataSetType = {
  labels: string[];
  datasets: ChartDataType[];
};

function UsersOverview({ startDate, endDate, selectFilter, period, setMenu }: UsersOverviewProps) {
  const auth = React.useContext(AuthContext);
  const [chartData, setChartData] = React.useState<ChartData<'line', (number | ScatterDataPoint | null)[], unknown>>();

  useEffect(() => {
    (async () => {
      const user = auth.getUser();

      if (user && auth.clientId && endDate) {
        const totalDay = differenceInDays(endDate, startDate as Date);
        const totalMonth = differenceInMonths(endDate, startDate as Date);

        setMenu(prev => {
          const param = [...prev];
          param[1].disabled = totalDay < 7;
          param[2].disabled = totalMonth < 1;

          return param;
        });

        const responseData = await adminService.getClientDailyUsage(
          auth.clientId,
          `${format(startDate as Date, 'yyyy-MM-dd')}T00:00:00.000Z`,
          `${format(endDate as Date, 'yyyy-MM-dd')}T00:00:00.000Z`
        );
          setChartData(calcChartData(responseData) as ChartDataSetType);
      }
    })();
  }, [auth, endDate, selectFilter, period]);

  const calcChartData = (data: DailyStatsResponse[] | undefined) => {
    let chartDataSet: ChartDataSetType | null = null;
    const filterChart = selectFilter.filter((item: SelectFilterType) => item.checked);

    if (period === 'Day') {
      const totalPlaidConnections = data?.map((item: DailyStatsResponse) => item.totalPlaidConnections);
      const totalUsers = data?.map((item: DailyStatsResponse) => item.totalUsers);
      const newUsers = data?.map((item: DailyStatsResponse) => item.newUsers);

      const dataSet: ChartDataType[] = filterChart.map((item: SelectFilterType) => {
        let value: number[] = [];
        let color = '';

        if (item.label === 'Active Users') {
          value = totalUsers as number[];
          color = '#F87A52';
        }

        if (item.label === 'Plaid Connections') {
          value = totalPlaidConnections as number[];
          color = '#10BCD2';
        }

        if (item.label === 'New Users') {
          value = newUsers as number[];
          color = 'green';
        }

        return {
          label: item.label,
          data: value,
          borderColor: color,
          backgroundColor: color,
          tension: 0.4,
          fill: false,
        };
      });

      chartDataSet = {
        labels: data?.map((item: DailyStatsResponse) =>
          format(add(new Date(item.day), { days: 1 }), 'MM/dd/yy')
        ) as string[],
        datasets: dataSet,
      };
    }

    if (period === 'Week') {
      const weeks = getWeekByWeek(
        new Date(`${format(startDate as Date, 'yyyy-MM-dd')}T00:00:00.000Z`),
        new Date(`${format(endDate as Date, 'yyyy-MM-dd')}T00:00:00.000Z`)
      );

      const weekData = weeks.map((item: { startDate: string; endDate: string }) => {
        let totals = {
          totalPlaidConnections: 0,
          totalUsers: 0,
          newUsers: 0,
        };

        data?.forEach((res: DailyStatsResponse) => {
          if (new Date(item.startDate) <= new Date(res.day) && new Date(res.day) <= new Date(item.endDate)) {
            totals.totalPlaidConnections += res.totalPlaidConnections;
            totals.totalUsers += res.totalUsers;
            totals.newUsers += res.newUsers;
          }
        });

        return {
          label: `${format(add(new Date(item.startDate), { days: 1 }), 'MM/dd/yy')}-${format(
            add(new Date(item.endDate), { days: 1 }),
            'MM/dd/yy'
          )}`,
          totalPlaidConnections: totals.totalPlaidConnections,
          totalUsers: totals.totalUsers,
          newUsers: totals.newUsers,
        };
      });

      const dataSet: ChartDataType[] = filterChart.map((item: SelectFilterType) => {
        let value: number[] = [];
        let color = '';

        if (item.label === 'Active Users') {
          value = weekData.map(rs => rs.totalUsers);
          color = '#F87A52';
        }

        if (item.label === 'Plaid Connections') {
          value = weekData.map(rs => rs.totalPlaidConnections);
          color = '#10BCD2';
        }

        if (item.label === 'New Users') {
          value = weekData.map(rs => rs.newUsers);
          color = 'green';
        }

        return {
          label: item.label,
          data: value,
          borderColor: color,
          backgroundColor: color,
          tension: 0.4,
          fill: false,
        };
      });

      chartDataSet = {
        labels: weekData?.map(item => item.label),
        datasets: dataSet,
      };
    }

    if (period === 'Month') {
      const months = getMonthByMonth(startDate as Date, endDate as Date);

      const monthData = months.map((item: { startDate: string; endDate: string }) => {
        let totals = {
          totalPlaidConnections: 0,
          totalUsers: 0,
          newUsers: 0,
        };

        data?.forEach((res: DailyStatsResponse) => {
          if (new Date(item.startDate) <= new Date(res.day) && new Date(res.day) <= new Date(item.endDate)) {
            totals.totalPlaidConnections += res.totalPlaidConnections;
            totals.totalUsers += res.totalUsers;
            totals.newUsers += res.newUsers;
          }
        });

        return {
          label: format(add(new Date(item.startDate), { days: 1 }), 'MM/yy'),
          totalPlaidConnections: totals.totalPlaidConnections,
          totalUsers: totals.totalUsers,
          newUsers: totals.newUsers,
        };
      });

      const dataSet: ChartDataType[] = filterChart.map((item: SelectFilterType) => {
        let value: number[] = [];
        let color = '';

        if (item.label === 'Active Users') {
          value = monthData.map(rs => rs.totalUsers);
          color = '#F87A52';
        }

        if (item.label === 'Plaid Connections') {
          value = monthData.map(rs => rs.totalPlaidConnections);
          color = '#10BCD2';
        }

        if (item.label === 'New Users') {
          value = monthData.map(rs => rs.newUsers);
          color = 'green';
        }

        return {
          label: item.label,
          data: value,
          borderColor: color,
          backgroundColor: color,
          tension: 0.4,
          fill: false,
        };
      });

      chartDataSet = {
        labels: monthData?.map(item => item.label),
        datasets: dataSet,
      };
    }

    return chartDataSet;
  };

  return (
    <>
      <Wrapper>
        {chartData && (
          <div style={{ width: '100%', height: '300px' }}>
            <Line
              data={chartData}
              options={{
                scales: {
                  y:{
                    min : 0
                  }
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                      pointStyle: 'circle',
                      usePointStyle: true,
                      padding: 30,
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
}

export { UsersOverview };
