import { PropsWithChildren } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import SortIcon from '../../assets/image-icons/descending.png';
import '../custom.css';
import { FormInput } from '../Forms/FormInput';
import { FaSearch } from 'react-icons/fa';

type ContentProps = {
  title?: string;
  bodyPadding?: string;
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
};

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;
const PaddedDiv = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    padding: 0em;
  }
`;
const StyledCard = styled(Card)`
  border-radius: 1em;
  @media screen and (max-width: 575px) {
    border: none;
    padding: 0;
    .card-body {
      padding: 0;
    }
  }
`;

const StyledGroupIcon = styled(InputGroup.Text)`
  border: 1px solid #dedede;
  border-width: 0 0 1px;
  border-radius: 0;
  background-color: transparent;
`;

const RoundCard = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledCard {...newprops} />;
};

const ContentCard = ({ title, bodyPadding, search, setSearch, children }: PropsWithChildren<ContentProps>) => {
  const handleSearch = (e: any) => {
    if (setSearch) setSearch(e.target.value);
  };

  return (
    <>
      <Wrapper>
        <RoundCard>
          <PaddedDiv>
            {title && (
              <Card.Title>
                <h3 style={{ fontSize: '20px', color: '#2D3142' }}>{title}</h3>
              </Card.Title>
            )}
            {title === 'Recent Activities' && (
              <Button className="btnLeftNav recent">
                <img src={SortIcon} alt="" />
              </Button>
            )}
            {title === 'All Users' && (
              <InputGroup className="mb-3" style={{ width: '450px' }}>
                <Form.Control
                  as={FormInput}
                  type="search"
                  placeholder="Search User"
                  value={search}
                  onChange={handleSearch}
                />
                <StyledGroupIcon>
                  <FaSearch></FaSearch>
                </StyledGroupIcon>
              </InputGroup>
            )}
          </PaddedDiv>
          <Card.Body style={{ padding: bodyPadding }}>{children}</Card.Body>
        </RoundCard>
      </Wrapper>
    </>
  );
};

export { ContentCard };
